import React from "react";

const NewIcon=()=>(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50px" height="50px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet" style={{marginLeft:"5px"}}>

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#f792ab" stroke="none">
<path d="M413 3736 c-154 -38 -276 -151 -329 -306 -18 -52 -19 -97 -19 -870
l0 -815 24 -65 c58 -159 186 -269 346 -300 44 -8 544 -10 1820 -8 1649 3 1763
4 1811 21 106 36 156 82 534 491 400 434 426 469 450 604 11 59 11 85 0 144
-24 136 -49 170 -453 606 -379 410 -426 454 -531 490 -48 16 -166 17 -1826 19
-1398 1 -1786 -1 -1827 -11z m493 -741 c12 -8 106 -143 210 -300 l189 -286 5
273 c5 278 9 308 46 322 34 13 71 6 95 -20 l24 -26 3 -366 c2 -202 0 -382 -3
-401 -9 -48 -46 -81 -91 -81 -64 0 -90 25 -208 205 -61 93 -144 219 -183 280
l-73 110 0 -245 c0 -137 -5 -261 -10 -282 -19 -68 -89 -90 -135 -43 l-25 24 0
391 c0 248 4 398 10 410 24 44 106 64 146 35z m1606 -15 c11 -15 42 -132 82
-305 38 -168 65 -272 69 -260 4 11 36 129 72 263 36 134 72 256 80 272 21 41
53 60 100 60 46 0 79 -19 99 -59 8 -15 44 -137 80 -272 37 -134 70 -252 74
-262 5 -13 20 38 46 155 77 350 88 391 112 415 29 29 73 30 108 2 21 -17 26
-28 26 -67 0 -26 -41 -207 -90 -402 -83 -332 -91 -357 -120 -382 -42 -39 -101
-39 -139 0 -31 31 -32 32 -126 382 -36 134 -67 245 -68 247 -1 1 -36 -120 -76
-270 -41 -150 -81 -289 -89 -310 -37 -96 -151 -106 -197 -17 -23 44 -185 706
-185 755 0 35 5 48 27 65 36 28 89 24 115 -10z m-247 6 c45 -19 56 -57 28 -94
l-20 -27 -222 -3 -221 -2 0 -105 0 -105 191 0 c224 0 248 -7 249 -72 0 -16 -9
-37 -20 -48 -19 -19 -33 -20 -220 -20 l-200 0 0 -119 0 -120 224 -3 c204 -3
226 -5 245 -22 28 -25 28 -77 0 -102 -19 -17 -42 -19 -278 -22 -141 -2 -271 0
-290 3 -18 4 -44 17 -57 30 l-24 24 0 376 c0 250 4 383 11 396 22 43 59 48
322 49 193 0 255 -3 282 -14z m2292 -141 c61 -18 144 -89 175 -149 14 -26 23
-67 26 -114 4 -66 2 -78 -27 -137 -59 -119 -151 -171 -286 -163 -115 8 -190
61 -241 172 -23 51 -26 68 -22 130 6 89 30 138 99 200 79 71 172 92 276 61z"/>
</g>
</svg>

);

export default NewIcon;