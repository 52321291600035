import React, { Fragment, useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import InvoiceForm from "./CreateBill";
import Loader from "../frontpage/Loader";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const ListPrescription = () => {
    const [prescription, setPrescription] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10;

    const[formData1, setFormData1]= useState({
        userId:"",
        HospitalName:"",
      })
  
      const[HospitalName,setHospitalName]=useState("");
  
      useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
          if (currentUser) {
            const userId = currentUser.uid;
    
            setFormData1((prevData) => ({
              ...prevData,
              userId: userId,
              HospitalName: HospitalName,
            }));
    
            try {
              const response = await axios.get(
                `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
              );
              const addressData = response.data;
              setFormData1((prevData) => ({
                ...prevData,
                HospitalName: addressData.HospitalName || " ",
                HospitalId: addressData.HospitalId || " ",
              }));
            } catch (error) {
              console.error("Error fetching user data:", error);
             
            } finally {
              
            }
          } else {
           
          }
        });
    
        return () => unsubscribe();
      }, []);

      const hospitalId=formData1.HospitalId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/prescription/single",{
                    params: {hospitalId}
                });
                setPrescription(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(hospitalId){
        fetchData();
        }
    }, [hospitalId]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleEdit = (invoiceNumber) => {
        console.log("Edit button clicked for invoice number:", invoiceNumber);
        
    };

    const handleDelete = async (invoiceNumber, hospitalId) => {
        console.log("invoiceNumber is:", invoiceNumber);
        console.log("hospitalId:", hospitalId);
        try {
            await axios.delete(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/delete-prescription/${invoiceNumber}/${hospitalId}`);
            // After successful deletion, refresh the list
            setPrescription(prescription.filter(item => item.invoice !== invoiceNumber));
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const filteredExpenses = prescription.filter(prescription =>
        (prescription.patientId && prescription.patientId.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (prescription.patientName && prescription.patientName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (prescription.invoice && prescription.invoice.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    filteredExpenses.sort((a, b) => a.invoice - b.invoice);

    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Layout
            title="Profile"
            description="list treatment categories"
            className="container-fluid"
        >
        <Loader/>
            <br></br>
            <h1 className="mt-4 color-bright">Generated Prescription</h1><br></br><br></br>
            <div className="input-group mb-4" style={{ width: "40%" }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="form-control"
                />
                <div className="input-group-append">
                    <span className="input-group-text">
                        <i className="fas fa-search"></i>
                    </span>
                </div>
            </div>
            {filteredExpenses.length === 0 ? (
                <div className="tbl-header">
                    <table cellPadding="0" cellSpacing="0" border="0">
                        <thead>
                            <tr>
                                <th scope="col">Prescription Number</th>
                                <th scope="col">Patient Id</th>
                                <th scope="col">Patient Name</th>
                                <th scope="col">View Prescription</th>
                                <th scope="col">Edit</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center" colSpan="5">No Data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <>  
                <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={filteredExpenses.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
            />
                    <div className="tbl-header table-responsive">
                        <table id="listPatients" cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th scope="col">Prescription Number</th>
                                    <th scope="col">Patient Id</th>
                                    <th scope="col">Patient Name</th>
                                    <th scope="col">View Prescription</th>
                                    <th scope="col">Edit</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                       
                            <tbody>
                                {currentItems.map((prescription, i) => (
                                    <tr key={i}>
                                        <Fragment>
                                            <td>{prescription.invoice}</td>
                                            <td>{prescription.patientId}</td>
                                            <td>{prescription.patientName}</td>
                                            <td>
                                                <a href={prescription.url} target="_blank" rel="noopener noreferrer">
                                                    View Prescription
                                                </a>
                                            </td>
                                            <td>
                                                <Link to={`/update-prescription/${prescription.invoice}`}
                                                        className="btn btn-link"
                                            
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </Link>
                                            </td>
                                            <td>
                                                <button
                                                        className="btn btn-link"
                                                        onClick={() => handleDelete(prescription.invoice, hospitalId)}
                                                    >
                                                        <i className="fas fa-trash"></i> {/* Delete icon */}
                                                    </button>
                                            </td>
                                        </Fragment>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
           
        </Layout>
    );
}

export default ListPrescription;
