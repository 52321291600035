import React,{useState, useEffect} from "react";
import "./Frontpage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../img/img/screenshot-1.png";
import Image2 from "../img/img/screenshot-2.png";
import Image3 from "../img/img/screenshot-3.png";
import Image4 from "../img/img/screenshot-4.png";
import Image5 from "../img/img/screenshot-5.png";
import Image6 from "../img/img/Screenshot-1.jpg";
import Image7 from "../img/img/Screenshot-2.jpg";
import Image8 from "../img/img/Screenshot-3.jpg";
import Image9 from "../img/img/Screenshot-4.jpg";
import Image10 from "../img/img/Threeimage.png";
import Image11 from "../img/img/about.png";
import Lap1 from "../img/img/Laptop1.jpg";
import Lap2 from "../img/img/Laptop2.jpg";
import Lap3 from "../img/img/Laptop3.jpg";
import Lap4 from "../img/img/Laptop4.jpg";
import axios from "axios";
import Features from "./Features";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";

const Frontpage=()=>{
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/homefirstsection");
        const data = response.data;
        if (data) {
          setTitle(data.Title);
          setDescription(data.Description);
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchData();
  }, []);

  const [aboutTitle,setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/homeaboutsection");
        const data = response.data;
        if(data){
          setAboutTitle(data.Title);
          setAboutDescription(data.Description);
          }
          }catch(error){
            console.error("Error fetching data from backend:",error);
            }
            };
            fetchData();

  },[]);

  const [mobileView, setMobileView] = useState({});
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchMobileView = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/mobileviewsection");
        const data = response.data;
        if (data) {
          setMobileView(data);
          const pointsArray = Object.keys(data)
            .filter(key => !isNaN(key))
            .map(key => data[key]);
          setPoints(pointsArray);
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchMobileView();
  }, []);

  const [plans, setPlans] = useState([]);
  const [activeTab, setActiveTab] = useState('tab-1');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/payment-yearly");
        const data = response.data;
        if (data) {
          const plansArray = Object.keys(data)
            .filter(key => !isNaN(key) && data[key] !== null)
            .map(key => data[key]);
          setPlans(plansArray);
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchPlans();
  }, []);

  const [plans1, setPlans1] = useState([]);
  
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/payment-threeyear");
        const data = response.data;
        if (data) {
          const plansArray = Object.keys(data)
            .filter(key => !isNaN(key) && data[key] !== null)
            .map(key => data[key]);
          setPlans1(plansArray);
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchPlans();
  }, []);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/contactsubmit-form', formData);
      setSuccessMessage('Your message has been sent successfully!');
      setErrorMessage('');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending form data:', error);
      setErrorMessage('There was an error sending your message. Please try again.');
      setSuccessMessage('');
    }
  };

    return(
        <>
  <meta charSet="utf-8" />
  <title>FitApp - Mobile App HTML Template</title>
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <meta content="" name="keywords" />
  <meta content="" name="description" />
  {/* Favicon */}
  <link href="img/favicon.ico" rel="icon" />
  {/* Google Web Fonts */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
  <link
    href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500&family=Jost:wght@500;600;700&display=swap"
    rel="stylesheet"
  />
  {/* Icon Font Stylesheet */}
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css"
    rel="stylesheet"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
    rel="stylesheet"
  />
  {/* Libraries Stylesheet */}
  <link href="lib/animate/animate.min.css" rel="stylesheet" />
  <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
  {/* Customized Bootstrap Stylesheet */}
  <link href="css/bootstrap.min.css" rel="stylesheet" />
  {/* Template Stylesheet */}
  <link href="css/style.css" rel="stylesheet" />
  <div className="container-xxl bg-white p-0">
    {/* Spinner Start */}
    {/* <div
      id="spinner"
      className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-grow text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div> */}
    {/* Spinner End */}
    {/* Navbar & Hero Start */}
    <div className="container4 p-0" id="home">
      <Header/>
      <Loader/>
      <div className="container-xxl  hero-header">
        <div className="container4 px-lg-5">
          <div className="row g-5">
            <div className="col-lg-8 text-center text-lg-start">
              <h2 className="text-black mb-4 animated slideInDown homefirsttitle">
                {title}
              </h2>
              <p className="text-black pb-3 animated slideInDown homefirstdescription text-justify">
               {description}
              </p>
              {/* <a
                href=""
                className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft"
              >
                Read More
              </a>
              <a
                href=""
                className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight"
              >
                Contact Us
              </a> */}
            </div>
            <div
              className="col-lg-4 justify-content-center justify-content-lg-end wow fadeInUp windowslide"
              data-wow-delay="0.3s"
            >
              <Slider {...settings} className="screenshot-carousel1">
              <div>
                <img className="img-fluid" src={Lap1} alt="Screenshot 1" />
              </div>
              <div>
                <img className="img-fluid" src={Lap2} alt="Screenshot 2" />
              </div>
              <div>
                <img className="img-fluid" src={Lap3} alt="Screenshot 3" />
              </div>
              <div>
                <img className="img-fluid" src={Lap4} alt="Screenshot 4" />
              </div>
              {/* <div>
                <img className="img-fluid" src={Image5} alt="Screenshot 5" />
              </div> */}
            </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    {/* About Start */}
    <div className="container-xl " id="about">
      <div className="container4  px-lg-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium">About App</h5>
            <h1 className="mb-4 homefirsttitle abouttext">{aboutTitle}</h1>
            <p className="mb-4 homefirstdescription">
              {aboutDescription}
            </p>
         
            {/* <a
              href=""
              className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3"
            >
              Read More
            </a> */}
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid wow fadeInUp"
              data-wow-delay="0.5s"
              src={Image10}
            />
          </div>
        </div>
      </div>
    </div>
    {/* About End */}
    {/* Features Start */}
   <Features/>
    {/* Features End */}
    {/* Screenshot Start */}
    <div className="container-xxl py-5">
      <div className="container4 py-5 px-lg-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Screenshot</h5>
            <h1 className="mb-4 homefirsttitle">{mobileView.Title}</h1>
            <p className="mb-4 homefirstdescription text-justify">{mobileView.Description}</p>
            {points.map((point, index) => (
              <p key={index} className="text-justify">
                <i className="fa fa-check text-primary-gradient me-3" />
                {point.Value}
              </p>
            ))}
            {/* <a
              href=""
              className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3"
            >
              Read More
            </a> */}
          </div>
          <div
            className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
            data-wow-delay="0.3s"
          >
              <Slider {...settings} className="screenshot-carousel">
              <div>
                <img className="img-fluid" src={Image6} alt="Screenshot 1" />
              </div>
              <div>
                <img className="img-fluid" src={Image7} alt="Screenshot 2" />
              </div>
              <div>
                <img className="img-fluid" src={Image8} alt="Screenshot 3" />
              </div>
              <div>
                <img className="img-fluid" src={Image9} alt="Screenshot 4" />
              </div>
              {/* <div>
                <img className="img-fluid" src={Image5} alt="Screenshot 5" />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </div>
   
    {/* Pricing Start */}
    <div className="container-xxl py-5" id="pricing">
      <div className="container4 py-5 px-lg-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Pricing Plan</h5>
          <h1 className="mb-5">Choose Your Plan</h1>
        </div>
        <div
          className="tab-class text-center pricing wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <ul className="nav nav-pills d-inline-flex justify-content-center bg-primary-gradient rounded-pill mb-5">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'tab-1' ? 'active' : ''}`}
                data-bs-toggle="pill"
                onClick={() => setActiveTab('tab-1')}
              >
                Yearly
              </button>
            </li>
            <li className="nav-item">
              <button  className={`nav-link ${activeTab === 'tab-2' ? 'active' : ''}`} data-bs-toggle="pill"   onClick={() => setActiveTab('tab-2')}>
                3 Year Plan
              </button>
            </li>
          </ul>
          <div className="tab-content ">
          <div id="tab-1" className={`tab-pane fade show p-0 ${activeTab === 'tab-1' ? 'active' : ''}`}>
        <div className="row g-4" >
          {plans.map((plan, index) => (
            <div className="col-lg-4" key={index}>
              <div className={`bg-light rounded ${index === 1 ? 'border' : ''}`}  style={{height: "700px"}}>
                <div className="border-bottom p-4 mb-4">
                  <h4 className="text-primary-gradient mb-1">{plan.Title}</h4>
                  <span>{plan.Description}</span>
                </div>
                <div className="p-4 pt-0">
                  <h1 className="mb-4">
                    <small
                      className="align-top"
                      style={{ fontSize: 22 }}
                    >
                     Rs {""}
                    </small>
                    <span>{plan.Rate.split(' ')[1]}</span>
                    <small
                      className="align-bottom"
                      style={{ fontSize: 16 }}
                    >
                     + 18% GST
                    </small>
                  </h1>
                  {plan.Features && plan.Features.map((feature, idx) => (
                    feature && (
                      <div className="d-flex justify-content-between mb-3" key={idx}>
                        <span>{feature}</span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                    )
                  ))}
                  {plan.Unchecked && plan.Unchecked.map((unchecked, idxs) => (
                    unchecked && (
                      <div className="d-flex justify-content-between mb-3" key={idxs}>
                        <span>{unchecked}</span>
                        <i className="fa fa-close text-primary-gradient pt-1" />
                      </div>
                    )
                  ))}
                  <a
                    href=""
                    className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="tab-2" className={`tab-pane fade  show p-0 ${activeTab === 'tab-2' ? 'active' : ''}`}>
              <div className="row g-4">
              {plans1.map((plan, index) => (
                <div className="col-lg-4" key={index}>
                  <div className={`bg-light rounded ${index === 1 ? 'border' : ''}`}  style={{height: "700px"}}>
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                      {plan.Title}
                      </h4>
                      <span>{plan.Description}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ fontSize: 22 }}
                        >
                          Rs {""}
                        </small>
                        <span>{plan.Rate.split(' ')[1]}</span>
                        <small
                          className="align-bottom"
                          style={{ fontSize: 16 }}
                        >
                          + 18% GST
                        </small>
                      </h1>
                      {plan.Features && plan.Features.map((feature, idx) => (
                    feature && (
                      <div className="d-flex justify-content-between mb-3" key={idx}>
                        <span>{feature}</span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                    )
                  ))}
                  {plan.Unchecked && plan.Unchecked.map((unchecked, idxs) => (
                    unchecked && (
                      <div className="d-flex justify-content-between mb-3" key={idxs}>
                        <span>{unchecked}</span>
                        <i className="fa fa-close text-primary-gradient pt-1" />
                      </div>
                    )
                  ))}
                      <a
                        href=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>

              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Pricing End */}
   
    {/* Contact Start */}
    <div className="container-xxl py-5" id="contact">
      <div className="container4 py-5 px-lg-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
          <h1 className="mb-5">Get In Touch!</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="wow fadeInUp" data-wow-delay="0.3s">
              <p className=" mb-4 contactdescription">
              We're here to help with any questions or support you may need regarding our Hospital Management System (HMS). Whether you’re looking for technical assistance, have inquiries about features, or need guidance on how to best utilize the system, our team is ready to assist.
                {/* <a href="https://htmlcodex.com/contact-form">Download Now</a>. */}
              </p>
              <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a message here"
                      id="message"
                      style={{ height: 150 }}
                      value={formData.message}
                      onChange={handleChange}
                    />
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary-gradient rounded-pill py-3 px-5"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            {successMessage && (
        <div className="alert alert-success mt-3" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-3" role="alert">
          {errorMessage}
        </div>
      )}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Contact End */}
   
    {/* Back to Top */}
    <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
      <i className="bi bi-arrow-up text-white" />
    </a>
  </div>
  <Footer/>
  {/* JavaScript Libraries */}
  {/* Template Javascript */}
</>

    );
}

export default Frontpage;