import React,{useState, useEffect} from "react";
import Layout from "../core/Layout";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import Loader from "../frontpage/Loader";


const SocialAddress=()=>{
    const [formData, setFormData] = useState({
        userId: "",
        streetName1: "",
        streetName2: "",
        area: "",
        city: "",
        state: "",
        country: "",
      });
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState("");

      const [hospital,setHospital] =useState("");

      console.log("hospital:", hospital);

      useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
          if (currentUser) {
            const userId = currentUser.uid;
            fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`)
              .then(response => {
                if (!response.ok) {
                  throw new Error('User data not found');
                }
                return response.json();
              })
              .then(userData => {
                setHospital(userData.hospital.HospitalId);
              })
              .catch(error => {
                console.error('Error fetching user data:', error);
                setError('Error fetching user data');
              });
          } else {
            setError('User not authenticated');
          }
        });
        return () => unsubscribe();
      }, []);
    
    
      useEffect(() => {
        if (hospital) {
          axios
            .get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/hospital/address/${hospital}`)
            .then((response) => {
              setFormData(response.data);
            })
            .catch((error) => {
              console.error("Error fetching address data:", error);
              setError("Failed to load address data.");
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }, [hospital]);
    
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        try {
          await axios.post(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/hospital/address/${hospital}`, formData);
          alert("Address updated successfully");
        } catch (error) {
          console.error("Error updating address:", error);
          setError("Error updating address");
        } finally {
          setLoading(false);
        }
      };
    
     
    
    return(
      <div className="row">
      <Layout>
      <Loader/>
        <h1 style={{ color: "#007bff", marginTop: "5px" }}>Update Address</h1>
        <form onSubmit={handleSubmit} className="AddressForm">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Street Name 1:</label>
                <input
                  className="form-control"
                  type="text"
                  name="streetName1"
                  value={formData.streetName1}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Street Name 2:</label>
                <input
                  className="form-control"
                  type="text"
                  name="streetName2"
                  value={formData.streetName2}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Area:</label>
                <input
                  className="form-control"
                  type="text"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>City:</label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>State:</label>
                <input
                  className="form-control"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Country:</label>
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
            </div>
            
          </div>
          <button type="submit" className="btn btn-primary text-center addresssubmit" style={{ marginTop: "10px", marginLeft: "80px" }}>
            Update
          </button>
        </form>
      </Layout>
    </div>
    );
}

export default SocialAddress;