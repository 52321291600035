import React,{useState, useEffect} from "react";
import Layout from "../core/Layout";
import { Link , useParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { BarChart as RechartBarChart,Bar,Rectangle,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ResponsiveContainer,PieChart,Pie,Cell} from 'recharts';
import { format, parse } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Pagination from 'react-js-pagination';
import Loader from "../frontpage/Loader";

const MedicineStatus=()=>{
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate(); 

    
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);
      
    useEffect(() => {
        // Check if user is already logged in
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
            setTimeout(() => {
              navigate('/signin'); 
          }, 100);
          }
        });
      }, [navigate]);
    

    const initialStartDate = parse('01-05-2022', 'dd-MM-yyyy', new Date());
    const initialEndDate = parse('19-12-2024', 'dd-MM-yyyy', new Date());
  
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const handleSearch = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        // Implement search logic here
        if (startDate && endDate) {
          const formattedStartDate = format(startDate, 'dd-MM-yyyy');
          const formattedEndDate = format(endDate, 'dd-MM-yyyy');
          const hospitalId = formData1.HospitalId;
          fetchData1(formattedStartDate, formattedEndDate, hospitalId);
          fetchData2(formattedStartDate, formattedEndDate, hospitalId);
        }
      };
      
    const [data, setData] = useState({
      totalAmountSum: 0,
      paidSum: 0,
      balanceSum: 0,
    });
    
   
    useEffect(() => {
      const from = format(startDate, 'dd-MM-yyyy');
      const to = format(endDate, 'dd-MM-yyyy');
      const hospitalId = formData1.HospitalId;
      
      if (hospitalId) {
        fetchData1(from, to, hospitalId);
        fetchData2(from, to, hospitalId);
      }
    }, [startDate, endDate, formData1.HospitalId]);


      const fetchData1 = async (startDate,endDate, hospitalId) => {
        console.log("from", startDate);
        console.log("to", endDate);
        try {
          const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/medicinedash', {
            params: {
              startDate, endDate, hospitalId
             },
          });
          console.log("Response:",response.data);
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data', error);
        }
      };
  
      const[paid1,setPaidValues1]=useState("")
  
      const fetchData2 = async (startDate, endDate, hospitalId) => {
        console.log("from chart date:", startDate);
        console.log("to chart date:", endDate);
      
        try {
          const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/medicineCharts1', {
            params: {
              startDate,
              endDate,
              hospitalId
            },
          });
          const data = response.data;
          console.log("data value of charts:", data);
      
          setChartData(data);
        } catch (error) {
          console.error('Error fetching data', error);
        }
      }
      
      const [chartData, setChartData] = useState([]);
  
      const [paidValues, setPaidValues] = useState([]);
      const [totalAmountValues, setTotalAmountValues] = useState([]);
      const [dates, setDates] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 10;
  
      const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];
    
    useEffect(() => {
        const from = format(startDate, 'dd-MM-yyyy');
        const to = format(endDate, 'dd-MM-yyyy');
        fetchData1(from, to);
        fetchData2(from,to);
      }, []);

  
  
    const handleChangeStart = (event) => {
      const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
      setStartDate(date);
    };
  
    const handleChangeEnd = (event) => {
      const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
      setEndDate(date);
    };
  
    const handleChange=(date)=>{
      setStartDate(date);
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const validData = Array.isArray(chartData) ? chartData : [];

     const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = validData.slice(startIndex, startIndex + itemsPerPage);
  
    
  const data1 = paidValues.map((paidValue, index) => ({
    name: dates[index],
    Paid: paidValue,
    totalAmount: totalAmountValues[index],
    totalExpenses: (totalAmountValues[index] - paidValue) / 2,
    totalPending: totalAmountValues[index] - paidValue,
  }));

  const totalQuantitySoldSum = chartData.reduce((acc, cur) => acc + cur.TotalQuantitySold, 0);

  const totalValue = chartData.reduce((acc, cur) => acc + cur.TotalQuantitySold + cur.AmountSold + cur.NearingExpiry, 0);
  
  const pieData = [
    { name: 'Total Quantity Sold', value: (chartData.reduce((acc, cur) => acc + cur.TotalQuantitySold, 0) / totalValue) * 100 },
    { name: 'Amount Sold', value: (chartData.reduce((acc, cur) => acc + cur.AmountSold, 0) / totalValue) * 100 },
    { name: 'Nearing Expiry', value: (chartData.reduce((acc, cur) => acc + cur.NearingExpiry, 0) / totalValue) * 100 },
  ];
  
    const COLORS = ['#96e892', '#a98ee8', '#f7999c', '#fd9b63'];
  
  
  
  
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };
    return(
        <Layout title="Medicine Dashboard">
        <Loader/>
            <h1 className="mt-4 color-bright">Medicine Details</h1>
            <div className="row mb-5">
      
      </div>
      <div className="row justify-content-center">
      <div className="col-md-2">
        <input
          type="date"
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeStart}
          className="form-control"
          placeholder="Start Date"
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div className="col-md-2 endateAdmind">
        <input
          type="date"
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeEnd}
          className="form-control"
          placeholder="End Date"
          min={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        />
      </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <button className="btn btn-primary endabutton" onClick={(event) => handleSearch(event)}>
              Search
            </button>
          </div>
      </div><br></br>

    <div className="row">
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{ backgroundColor:"#a98ee8"}}>
          <div className="card-body">Total Quantity Sold</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              to={`/list/users`}
              style={{ textDecoration: 'none' }}
            >
              {totalQuantitySoldSum}
            </Link>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card text-white mb-4" style={{ backgroundColor:"#96e892"}}>
          <div className="card-body">Amount Sold</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              to={`/list/users`}
              style={{ textDecoration: 'none' }}
            >
              {data.paidSum}
            </Link>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{ backgroundColor:"#f7999c"}}>
          <div className="card-body">No of Medicines Nearing Expiry</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }} href="#">
              {data.nearingExpirySum}
            </a>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-chart-bar mr-1" />
             Medicine Details
          </div>
          <div className="card-body">
          
          <ResponsiveContainer width="100%" height={400}>
  <RechartBarChart
    data={chartData}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 40,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" tick={{ angle: -45, textAnchor: 'end', style: { fontSize: '10px' } }} interval={0} />
    <YAxis />
    <Tooltip formatter={(value, name) => `${name}: ${value}`} />
    <Bar dataKey="TotalQuantitySold" fill="#a98ee8" label="Total Quantity Sold" />
    <Bar dataKey="AmountSold" fill="#96e892" label="Amount Sold" />
    <Bar dataKey="NearingExpiry" fill="#f7999c" label="Nearing Expiry" />
  </RechartBarChart>
</ResponsiveContainer>
    
          </div>
          
          <div className="card-footer small text-muted">
            Updated yesterday at 11:59 PM
          </div>
        </div>
      </div>
      <div className="rowtablechart">
      <table className="tablechartmbile">
        <thead>
          <tr style={{ backgroundColor: '#007bff', color: 'white' }}>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>S.No</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Date</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Total Quantity Sold</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Amount Sold</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Nearing Expiry</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={index} style={{ backgroundColor: 'white', color: 'black' }}>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{startIndex + index + 1}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.name}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.TotalQuantitySold}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.AmountSold}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.NearingExpiry}</td>
            </tr>
          ))}
        </tbody>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={data.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
      </table>
      <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label={({ name, value }) => `${name}: ${value.toFixed(2)}%`}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
      </PieChart>
    </ResponsiveContainer>
    </div>
    </div>
        </Layout>
    );
}

export default MedicineStatus;