import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import SplitInvoiceItem3 from './USplitInvoiceItem';
import SplitInvoiceModal3 from './USplitInvoiceModal';
import InputGroup from 'react-bootstrap/InputGroup';
import Layout from "../core/Layout";
import Select from 'react-select';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import withRouter from './WithRouter';
import firebase from 'firebase/compat/app';
import Loader from '../frontpage/Loader';

class SplitInvoiceForm1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currency: '₹',
      currentDate: new Date().toLocaleDateString(),
      invoiceNumber: 1,
      dateOfIssue: '',
      billTo: '',
      billToEmail: '',
      billToAddress: '',
      billFrom: '',
      billFromEmail: '',
      billFromAddress: '',
      notes: '',
      total: '0.00',
      subTotal: '0.00',
      taxRate: '',
      hospital: "",  
      taxAmount: '0.00',
      discountRate: '',
      discountAmount: '0.00',
      items: [{
        id: 0,
        name: '',
        description: '',
        price: '',
        quantity: 1,
        medicineId: null
      }],
      patientOptions: [],
      hospitalOptions: [],
      medicineOptions: [],
      selectedMedicineNames: [],
      additionalNotes: [],
      showInvoiceCapture: false,
      isPrintEnabled: false,
      amountDue: '0.00',
      totalPaid: 0,
      remainingAmount: '0.00',
      paymentTypes: [],
      rows:[],
    };

    this.editField = this.editField.bind(this);
    this.handleAddEvent = this.handleAddEvent.bind(this);
    this.handleRowDel = this.handleRowDel.bind(this);
    this.onItemizedItemEdit = this.onItemizedItemEdit.bind(this);
    this.handleCalculateTotal = this.handleCalculateTotal.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.handlePatientSelect = this.handlePatientSelect.bind(this);
    this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
    this.fetchMedicines = this.fetchMedicines.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
    this.handleTotalPaidChange = this.handleTotalPaidChange.bind(this);
    this.handlePaymentModeChange = this.handlePaymentModeChange.bind(this);
    this.handleAddSplitEvent = this.handleAddSplitEvent.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
  }

  componentDidMount() {
    this.handleCalculateTotal();
    this.fetchPatients();
    this.fetchHospitals();
    this.fetchMedicines();
    // this.fetchLatestInvoiceNumber();
    this.fetchInvoiceData();
    this.fetchUserHospital();
  }

  
  fetchUserHospital = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const userId = user.uid;
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`);
        const hospitalId = response.data.hospital.HospitalId;
        this.setState({ hospital: hospitalId }, () => {
          this.fetchInvoiceData();
          this.fetchMedicines();
          this.fetchPaymentTypes();
        });
      }
    } catch (error) {
      console.error("Error fetching user hospital:", error);
      this.setState({ error: "Failed to fetch user hospital" });
    }
  };


  async fetchPatients() {
    try {
      const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/createbill/get-patients');
      const patientOptions = response.data.map(patient => ({
        value: patient.patientId,
        label: `${patient.patientId} - ${patient.Name}`,
        email: patient.Email,
        address: patient.Address,
        phone: patient.PhoneNo,
        data: patient
      }));
      this.setState({ patientOptions });
    } catch (error) {
      console.error("There was an error fetching the patients!", error);
    }
  }

  async fetchHospitals() {
    try {
      const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/createbill/get-hospitals');
      const hospitalOptions = response.data.map(hospital => ({
        value: hospital.hospitalId,
        label: `${hospital.hospitalName}`,
        email: hospital.emailAddress,
        address: hospital.billingAddress,
        phone: hospital.phoneNumber,
        data: hospital
        
      }));
      this.setState({ hospitalOptions });
    } catch (error) {
      console.error("There was an error fetching the hospitals!", error);
    }
  }

  async fetchMedicines() {
    try {
      const hospitalId = this.state.hospital;
      const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/createbill/get-medicines?hospitalId=${hospitalId}`);
      const medicineOptions = response.data.map(medicine => ({
        value: medicine.medicineId,
        label: medicine.MedicineName,
        price: medicine.Price,
        availableQuantity: medicine.AvailableQuantity || medicine.Quantity,
        data: medicine
      }));
      const billCategoryResponse = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getAdminExtraFields/${hospitalId}`);
      const billCategories = billCategoryResponse.data.BillCategory || [];
      const billCategoryOptions = billCategories.map(category => ({
        value: category.name,
        label: category.name,
        price: category.price,
        availableQuantity: category.quantity || 1, // If not specified, set default quantity to 1
        data: category
      }));
      console.log("availableQuantity:", medicineOptions.availableQuantity);
      this.setState({ medicineOptions, billCategoryOptions });
    } catch (error) {
      console.error("There was an error fetching the medicines!", error);
    }
  }

  fetchInvoiceData() {
    const hospitalId= this.state.hospital;
    const { invoice } = this.props.params;  
    console.log("hospitalId:", hospitalId);
      console.log("invoicenumber:", invoice);
      fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/get-splitdata?invoiceNumber=${invoice}&hospitalId=${hospitalId}`)
        .then(response => response.json())
        .then(data => {
          const invoice = data; // Data should be the invoice object
          console.log("invoice:", invoice);
          if (invoice) {
            const updatedItems = invoice.items.map(item => ({
              ...item
             
            }));

            const medicineOptions = invoice.items.map(item => ({
              value: item.medicineId,
              label: item.name,
              price: item.price,
              availableQuantity: item.quantity, // Adjust based on your data structure
            }));

            const fetchedRows = invoice.paymentAmounts.map((amount, index) => ({
              id: index,
              paymentMode: invoice.paymentMode[index],
              paymentId: invoice.paymentIds[index],
              date: invoice.paymentDates[index], // Keep this non-editable
              amount: amount,
            }));
    

            const totalPaid = invoice.paymentAmounts.reduce((acc, amount) => acc + (parseFloat(amount) || 0), 0);
        const amountDue = invoice.amountDue || '0.00';
        const remainingAmount = (parseFloat(amountDue) - parseFloat(totalPaid)).toFixed(2);

            this.setState({
              billDate: invoice.BillDate || " ",
          billFrom: invoice.billFrom || " ",
          billFromAddress: invoice.billFromAddress || " ",
          billFromEmail: invoice.billFromEmail || " ",
          billFromPhone: invoice.billFromPhone || " ",
          billTo: invoice.billTo || " ",
          billToAddress: invoice.billToAddress || " ",
          billToEmail: invoice.billToEmail || " ",
          billToPhone: invoice.billToPhone || " ",
          dateOfIssue: invoice.dateOfIssue || " ",
          notes: invoice.notes || " ",
          patientId: invoice.patientId || " ",
          patientName: invoice.patientName || " ",
          url: invoice.url || " ",
          invoice: invoice.invoice || " ",
          items: updatedItems || " ",
          total: invoice.total || " ",
          selectedMedicineNames: invoice.selectedMedicineNames || " ",
          subTotal: invoice.subTotal || " ",
          taxAmount: invoice.taxAmount || " ",
          discountAmount: invoice.discountAmount || "",
          additionalNotes: invoice.additionalNotes || " ",
          color1: invoice.color1 || " ",
          color2: invoice.color2 || " ",
          color3: invoice.color3 || " ",
          color4: invoice.color4 || " ",
          socialMedia: invoice.socialMedia || {},
          address: invoice.address || {},
          amountDue: amountDue,
          totalPaid1: totalPaid,
          paymentMode1: invoice.paymentMode || [],
          remainingAmount: remainingAmount,
          paymentCategories: invoice.paymentCategories || [],
          paymentMode: invoice.paymentMode || [],
          paymentDates: invoice.paymentDates || [],
          paymentAmounts: invoice.paymentAmounts || [],
          paymentId: invoice.paymentIds || [],
          rows: fetchedRows,
          totalPaid: totalPaid,
          medicineOptions: medicineOptions, 
        });
          }
        })
        .catch(error => {
          console.error('Error fetching invoice data:', error);
        });
  }

  
  handleAddSplitEvent() {
    const newId = this.state.items.length ? this.state.items[this.state.items.length - 1].id + 1 : 0;

    
    const lastItem = this.state.items[this.state.items.length - 1];
    const lastSelectedCategory = lastItem.selectedCategory || 'Medicine'; 

   
    const newSplitItem = {
      id: newId,
      name: '',
      description: '',
      price: '',
      quantity: 1,
      medicineId: null,
      type: 'split', 
      selectedCategory: lastSelectedCategory,
    };

   
    this.setState((prevState) => ({
      items: [...prevState.items, newSplitItem],
    }));
  }

  handlePaymentIdChange(id, e) {
    const updatedRows = this.state.rows.map(row => 
      row.id === id ? { ...row, paymentId: e.target.value } : row
    );
    this.setState({ rows: updatedRows });
  }

  handleAmountChange = (id, event) => {
    const value = parseFloat(event.target.value) || 0;  // Parse the input value as a number
    this.setState((prevState) => {
      // Update the specific row's amount
      const updatedRows = prevState.rows.map((row) =>
        row.id === id ? { ...row, amount: value } : row
      );
  
      // Calculate the new total paid by summing all row amounts
      const totalPaid = updatedRows.reduce((acc, row) => acc + (parseFloat(row.amount) || 0), 0);
  
      console.log("Updated Rows:", updatedRows);  // Debugging rows
    console.log("New Total Paid:", totalPaid);  // Debugging total paid

      return {
        rows: updatedRows,
        totalPaid: totalPaid,
      };
    });
  };

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  addRow() {
    this.setState((prevState) => {
      const newRow = {
        id: prevState.rows.length + 1,  // Assign a new ID
        paymentMode: "",  // Default payment mode
        date: new Date().toLocaleDateString('en-GB'),  // Current date
        paymentId: "",
        amount: 0,  // Default amount
      };
  
      const updatedRows = [...prevState.rows, newRow];
  
      const totalPaid = updatedRows.reduce((acc, row) => acc + (parseFloat(row.amount) || 0), 0);
  
      return {
        rows: updatedRows,
        totalPaid: totalPaid,
      };
    });
  };
  

  removeRow = (id) => {
    this.setState((prevState) => {
      const updatedRows = prevState.rows.filter((row) => row.id !== id);
  
      const totalPaid = updatedRows.reduce((acc, row) => acc + (parseFloat(row.amount) || 0), 0);
  
      return {
        rows: updatedRows,
        totalPaid: totalPaid,
      };
    });
  };
  

  handleAmountDueChange(event) {
    const amountDue = event.target.value;
    this.setState(prevState => ({
      amountDue: amountDue,
      // Remaining amount remains unchanged based on the initial totalPaid and amountDue
      remainingAmount: (parseFloat(prevState.amountDue) - parseFloat(prevState.totalPaid)).toFixed(2)
    }));
  }
  
  handlePaymentModeChange(rowId, e) {
    const updatedRows = this.state.rows.map(row => {
      if (row.id === rowId) {
        return { ...row, paymentMode: e.target.value };
      }
      return row;
    });
    this.setState({ rows: updatedRows });
  }
  

  handlePatientSelect(selectedOption) {
    const { value , label, email, address, data, phone} = selectedOption;

    const billingAmount = parseFloat(data?.BillingAmount || 0);
    this.setState({
      billTo: label,
      billToEmail: email,
      billToAddress: address,
      billToPhone: phone,
      billToData: data,
      // subTotal: billingAmount.toFixed(2), // Update subtotal with billing amount
      patientId: value,
      patientName: data.Name,
    }, this.handleCalculateTotal); // Recalculate total
  }

  handleHospitalSelect(selectedOption) {
    const { label, email, address, phone} = selectedOption;

    this.setState({
      billFrom: label,
      billFromEmail: email,
      billFromAddress: address,
      billFromPhone: phone,
    });
  }


  handleTotalPaidChange(event) {
    const RemainingAmount= this.state.total - this.state.totalPaid1;
    const totalPaid = parseFloat(event.target.value) || 0;
    const amountDue = parseFloat(RemainingAmount) - totalPaid;
    this.setState({
      totalPaid: event.target.value,
      amountDue: amountDue.toFixed(2),
    });
  }

  fetchPaymentTypes = () => {
    const { hospital } = this.state;
    if (hospital) {
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getPaymentTypes/${hospital}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.paymentTypes) {
                    this.setState({ paymentTypes: data.paymentTypes });
                } else {
                    this.setState({ error: "Payment types not found" });
                }
            })
            .catch((error) => {
                console.error("Error fetching payment types:", error);
                this.setState({ error: "Error fetching payment types" });
            });
    }
  };


  handleRowDel(items) {
    const updatedItems = this.state.items.filter(item => item.id !== items.id);
    this.setState({ items: updatedItems }, this.handleCalculateTotal);
  }

  handleAddEvent() {
    const id = (this.state.items.length === 0) ? 0 : this.state.items[this.state.items.length - 1].id + 1;
    const item = {
      id: id,
      name: '',
      description: '',
      price: '',
      quantity: 1,
      medicineId: null
    };
    this.setState((prevState) => ({
      items: [...prevState.items, item]
    }));
  }

  handleCalculateTotal() {
    const items = this.state.items;

    let subTotal = 0;
    items.forEach(item => {
      subTotal += parseFloat(item.price) * parseFloat(item.quantity);
    });

    const discountAmount = (this.state.discountRate / 100) * subTotal;
    const taxAmount = (this.state.taxRate / 100) * (subTotal - discountAmount);
    const total = subTotal - discountAmount + taxAmount;

    this.setState({
      subTotal: subTotal.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      taxAmount: taxAmount.toFixed(2),
      total: total.toFixed(2)
    });
}

  onItemizedItemEdit(evt) {
    const { id, name, value } = evt.target;
    const items = [...this.state.items];
    const index = items.findIndex(item => item.id === parseInt(id));
    items[index][name] = value;

    this.setState({ items }, this.handleCalculateTotal);
  }

  editField(event) {
    this.setState({ [event.target.name]: event.target.value }, this.handleCalculateTotal);
  }


  onCurrencyChange(event) {
    this.setState({ currency: event.target.value });
  }

  openModal(event) {
    event.preventDefault();
    this.handleCalculateTotal();
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  // fetchLatestInvoiceNumber = async () => {
  //   try {
  //     const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/splitlatestInvoiceNumber");
  //     if (response.data.success) {
  //       this.setState({
  //         invoiceNumber: response.data.latestInvoiceNumber + 1,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching latest invoice number:", error);
  //   }
  // };

  printInvoice = async (event) => {
    event.preventDefault();
    const input = document.getElementById('invoiceCaptureprint');
   
    try {
      // Generate PDF
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792]
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
      // Convert the PDF data to a blob
      const blob = pdf.output('blob');
      const fileName = `${this.state.patientId}_${this.state.billFrom}.pdf`;
      pdf.save(fileName);
      const storageRef = firebase.storage().ref();
      const pdfRef = storageRef.child(`invoices/${fileName}`);
  
      // Upload the PDF to Firebase Storage and get the public URL
      await pdfRef.put(blob);
      const downloadURL = await pdfRef.getDownloadURL();
  
      // Prepare the form data with only the PDF URL
      const urlData = {
        invoice: this.state.invoice,
        url: downloadURL
      };
  
      // Send only the PDF URL to the backend API
      const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/splitupdate-pdf-url1', urlData);
      console.log(response.data);
  
      this.setState({ successMessage: 'PDF URL updated successfully!' });
  
    } catch (error) {
      console.error('Error:', error);
      this.setState({ successMessage: 'Failed to update PDF URL.' });
    }
  }
  

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Hospital ID:', this.state.hospital);
  
    const hospitalId = this.state.hospital;
    const { invoice } = this.props.params;
  
    // Fetch existing bill data
    const existingBillResponse = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/get-splitdata?invoiceNumber=${invoice}&hospitalId=${hospitalId}`);
    const existingBill = existingBillResponse.data;
    const existingAllPaids = existingBill.allPaids || [];
    const existingAllPaidsDates = existingBill.allPaidsDate || [];
    const existingAllPaidsAmountDue = existingBill.allPaidsAmountDue || [];
  
    const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

    let updatedAllPaids = [...existingAllPaids];
    let updatedAllPaidsDates = [...existingAllPaidsDates];
    let updatedAllPaidsAmountDue = [...existingAllPaidsAmountDue];

    const amountDue = this.state.total - this.state.totalPaid;

    if (this.state.editingPaymentId) {
      const paymentIndex = updatedAllPaids.findIndex(payment => payment.paymentId === this.state.editingPaymentId);
      
      if (paymentIndex !== -1) {
        // Update existing payment details
        updatedAllPaids[paymentIndex] = this.state.totalPaid;
        updatedAllPaidsDates[paymentIndex] = currentDate;
        updatedAllPaidsAmountDue[paymentIndex] = amountDue;
      } else {
        // Add new payment details if it doesn't exist
        updatedAllPaids.push(this.state.totalPaid);
        updatedAllPaidsDates.push(currentDate);
        updatedAllPaidsAmountDue.push(amountDue);
      }
    } else {
      // Add new payment details for a new payment
      updatedAllPaids.push(this.state.totalPaid);
      updatedAllPaidsDates.push(currentDate);
      updatedAllPaidsAmountDue.push(amountDue);
    }
  

    const paymentMode = this.state.rows.map(row => row.paymentMode);
    const paymentDates = this.state.rows.map(row => row.date);
    const paymentAmounts = this.state.rows.map(row => row.amount);
    const paymentIds = this.state.rows.map(row => row.paymentId);
    const paymentCategories = this.state.items.map(item => item.selectedCategory);
  
    const formData = {
      billDate: this.state.BillDate,
      hospital: this.state.hospital,
      patientId: this.state.patientId,
      patientName: this.state.patientName,
      billTo: this.state.billTo,
      billToEmail: this.state.billToEmail,
      billToPhone: this.state.billToPhone,
      billToAddress: this.state.billToAddress,
      billFrom: this.state.billFrom,
      billFromEmail: this.state.billFromEmail,
      billFromAddress: this.state.billFromAddress,
      billFromPhone: this.state.billFromPhone,
      invoice: this.state.invoice,
      total: this.state.total,
      totalPaid: [this.state.totalPaid],
      amountDue: [amountDue],
      dateOfIssue: this.state.dateOfIssue,
      selectedMedicineNames: this.state.selectedMedicineNames,
      subTotal: this.state.subTotal,
      discountAmount: this.state.discountAmount,
      taxAmount: this.state.taxAmount,
      notes: this.state.notes,
      additionalNotes: JSON.stringify(this.state.additionalNotes),
      items: JSON.stringify(this.state.items),
      color1: this.state.color1,
      color2: this.state.color2,
      color3: this.state.color3,
      color4: this.state.color4,
      address: this.state.address,
      socialMedia: this.state.socialMedia,
      paymentMode,
      paymentDates,
      paymentAmounts,
      paymentCategories,
      paymentIds,
      allPaids: updatedAllPaids,
      allPaidsDate: updatedAllPaidsDates,
      allPaidsAmountDue: updatedAllPaidsAmountDue,
    };
  
    try {
      // Submit the form data
      const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/splitsubmit-bill', formData);
      console.log(response.data);
      this.setState({
        successMessage: 'Bill saved successfully!',
        isPrintEnabled: true,
      });
  
      // Check if any items have the selectedCategory as "Medicine"
      const medicineUpdates = this.state.items
        .filter(item => item.selectedCategory === 'Medicine') // Only process items where category is "Medicine"
        .map(item => ({
          medicineId: item.medicineId,
          quantitySold: item.quantity,
          invoice: this.state.invoiceNumber,
        }));
  
      if (medicineUpdates.length > 0) {
        // If there are medicine updates, proceed with the medicine API call
        const medicineResponse = await fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/update-billmedicinequantity1', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ hospitalId: this.state.hospital, updates: medicineUpdates }),
        });
  
        const medicineData = await medicineResponse.json();
        console.log('Medicine update response:', medicineData);
  
        if (!medicineResponse.ok) {
          throw new Error(medicineResponse.statusText);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ successMessage: 'Not saved value' });
    }
  };
  

  render() {
    const { items, successMessage} = this.state;
    return (
        <Layout title="List Prescriptions" className="container-fluid">
        <Loader/>
         <h1 className="mt-4 color-bright">Update Split Bill</h1>
    <Form >
      <Row>
        <Col md={8} lg={9}>
        <div id="invoiceCapture12">
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div class="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div class="mb-2">
                    <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                    <span className="current-date">{new Date().toLocaleDateString()}</span>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                  <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                      maxWidth: '150px'
                    }} required="required"/>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <span className="fw-bold me-2">Invoice&nbsp;Number:&nbsp;</span>
                <Form.Control type="number" value={this.state.invoice} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                    maxWidth: '70px'
                  }} disabled/>
              </div>
            </div>
            <hr className="my-4"/>
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold">Bill to:</Form.Label>
                {/* <Select
                      options={this.state.patientOptions}
                      onChange={this.handlePatientSelect}
                      placeholder="Search for a patient..."
                      className="my-2"
                    /> */}
                <Form.Control placeholder={"Who is this invoice to?"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Billing Phone number"} value={this.state.billToPhone} type="text" name="billToPhone" className="my-2" autoComplete="phone" onChange={(event) => this.editField(event)} required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billToEmail} type="email" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
               
              </Col>
              <Col>
                <Form.Label className="fw-bold">Bill from:</Form.Label>
                {/* <Select
                      options={this.state.hospitalOptions}
                      onChange={this.handleHospitalSelect}
                      placeholder="Search for a hospital..."
                      className="my-2"
                    /> */}
                <Form.Control placeholder={"Who is this invoice from?"} rows={3} value={this.state.billFrom} type="text" name="billFrom" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Phone Number"} value={this.state.billFromPhone} type="text" name="billFromPhone" className="my-2" onChange={(event) => this.editField(event)} autoComplete="phone" required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billFromEmail} type="email" name="billFromEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billFromAddress} type="text" name="billFromAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
              </Col>
              
            </Row>
           
            <SplitInvoiceItem3 onItemizedItemEdit={this.onItemizedItemEdit} onRowAdd={this.handleAddEvent} handleAddSplitEvent={this.handleAddSplitEvent} onRowDel={this.handleRowDel} currency={this.state.currency} items={this.state.items} medicineOptions={this.state.medicineOptions} billCategoryOptions={this.state.billCategoryOptions} handleCalculateTotal={this.handleCalculateTotal} />
                        <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                      <div className="d-flex flex-row align-items-start justify-content-between">
                          <span className="fw-bold">Subtotal:</span>
                          <span>{this.state.currency}{this.state.subTotal}</span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Discount:</span>
                          <span>
                              <span className="small">({this.state.discountRate || 0}%)</span>
                              {this.state.currency}{this.state.discountAmount || '0.00'}
                          </span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Tax:</span>
                          <span>
                              <span className="small">({this.state.taxRate || 0}%)</span>
                              {this.state.currency}{this.state.taxAmount || '0.00'}
                          </span>
                      </div>
                      <hr/>
                      <div className="d-flex flex-row align-items-start justify-content-between" style={{ fontSize: '1.125rem' }}>
                          <span className="fw-bold">Total:</span>
                          <span className="fw-bold">{this.state.currency}{this.state.total || '0.00'}</span>
                      </div>
                      <hr/>
                      {/* <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                        <span className="fw-bold">Due Paid:
                        <div> {this.state.paymentMode1 && this.state.paymentMode1}({this.state.billDate})</div></span>
                        
                       
                        <span className="fw-bold"><div>
                          {this.state.currency}{(parseFloat(this.state.totalPaid1) || 0).toFixed(2)}
                          </div>
                        </span>
                      </div> */}

                      <hr/>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                        <span className="fw-bold">Remaining Amount:</span>
                        <span className="fw-bold">{this.state.currency}{(parseFloat(this.state.total) - parseFloat(this.state.totalPaid1) || 0).toFixed(2)}</span>
                      </div>
                    <hr/>
                    </Col>
                    <Col lg={12}>
                    <Table>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Payment Mode</th>
                          <th>Payment Id</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rows.map((row, index) => (
                          <tr key={row.id}>
                            <td>{index + 1}</td>
                            <td>
                              {/* Editable Payment Mode */}
                              <Form.Select value={row.paymentMode} onChange={(e) => this.handlePaymentModeChange(row.id, e)}>
                                <option value="">Select Payment Mode</option>
                                {this.state.paymentTypes.map((type, idx) => (
                                  <option key={idx} value={type}>{type}</option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                            <Form.Control 
                                type="text" 
                                value={row.paymentId || ''} 
                                onChange={(e) => this.handlePaymentIdChange(row.id, e)} 
                                placeholder="Enter Payment Id" 
                              />
                            </td>
                            <td>
                              {/* Non-editable Date */}
                              {row.date}
                            </td>
                            <td>
                              {/* Editable Amount */}
                              <Form.Control 
                                type="number" 
                                value={row.amount || ''} 
                                onChange={(e) => this.handleAmountChange(row.id, e)} 
                                placeholder="Enter Amount" 
                              />
                            </td>
                            <td>
                              <Button variant="danger" onClick={() => this.removeRow(row.id)}>Delete</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </Col>
                    <Col lg={6}>
                    <Button className='mb-4' onClick={this.addRow}>Add Payment Type</Button>
                    <div className="d-flex flex-row align-items-start justify-content-between mb-4" style={{ fontSize: '1.125rem' }}>
                      <span className="fw-bold">Total Paid:</span>
                      <span className="fw-bold">{this.state.currency}{this.state.totalPaid.toFixed(2)}</span>
                    </div>
                      <hr/>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                        <span className="fw-bold">Amount Due:</span>
                        <span className="fw-bold">{this.state.currency}{(parseFloat(this.state.total)-parseFloat(this.state.totalPaid) || 0).toFixed(2)}</span>
                      </div>
                  </Col>
              </Row>
            <hr className="my-4"/>
            <Form.Label className="fw-bold">Notes:</Form.Label>
            <Form.Control placeholder="Happy Medicial" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} as="textarea" className="my-2" rows={1}/>
          </Card>
          </div>
        </Col>
        
        <Col md={4} lg={3}>
          <div className="sticky-top pt-md-3 pt-xl-4">
          <Button variant="primary" type="submit" onClick={this.openModal} className="d-block w-100" disabled={!this.state.isPrintEnabled}>Review Invoice</Button>
            
           <SplitInvoiceModal3 showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmmount={this.state.taxAmmount} discountAmmount={this.state.discountAmmount} total={this.state.total} patientId={this.state.patientId} patientName={this.state.patientName}
            color1={this.state.color1}
            color2={this.state.color2} 
            color3={this.state.color3} 
            color4={this.state.color4}
            address={this.state.address}
            socialMedia={this.state.socialMedia}
            hospital={this.state.hospital}
            invoice={this.state.invoice}
            notes={this.state.notes}
            rows={this.state.rows}
            totalPaid={this.state.totalPaid}
            selectedCategory={this.state.selectedCategory}
           />
           <Button variant="success" type="button" onClick={this.handleSubmit} className="d-block w-100 mt-3 fw-bold"> Save</Button><br></br>
           {successMessage && <div className="mt-3 alert alert-success">{successMessage}</div>} {/* Success message */}
           {/* <Button variant="secondary" type="print" className="d-block w-100 mb-4" onClick={this.printInvoice}  >Print</Button> */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select onChange={event => this.onCurrencyChange({currency: event.target.value})} className="btn btn-light my-1" aria-label="Change Currency">
                <option value="₹">INR (Indian Rupee)</option>
                <option value="$">USD (United States Dollar)</option>
                <option value="£">GBP (British Pound Sterling)</option>
                <option value="¥">JPY (Japanese Yen)</option>
                <option value="$">CAD (Canadian Dollar)</option>
                <option value="$">AUD (Australian Dollar)</option>
                <option value="$">SGD (Signapore Dollar)</option>
                <option value="¥">CNY (Chinese Renminbi)</option>
                <option value="₿">BTC (Bitcoin)</option>
              </Form.Select>
            </Form.Group>
                        <Form.Group className="my-3">
                <Form.Label className="fw-bold">Tax rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="taxRate" type="number" value={this.state.taxRate } onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
                <Form.Label className="fw-bold">Discount rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="discountRate" type="number" value={this.state.discountRate} onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            {/*  */}
          </div>
        </Col>
      </Row>
    </Form>
  
    <div id="invoiceCaptureprint" className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start w-100 p-4" style={{backgroundColor: this.state.color1}}>
          <div>
              <h6>Address</h6>
              {this.state.address?.streetName1 && <div>{this.state.address.streetName1},{this.state.address.streetName2}</div>}
              {this.state.address?.area && <div>{this.state.address.area}</div>}
              {this.state.address?.city && <div>{this.state.address.city}</div>}
            </div>
            <div className="w-100" style={{marginLeft:"10px",textAlign:"center"}}>
              <h4 className="fw-bold my-2">{this.state.billFrom || 'John Uberbacher'}</h4>
              <h6 className="fw-bold text-secondary mb-1">
                Invoice #: {this.state.invoice || ''}
              </h6>
            </div>
            <div className="text-end ms-4">
              <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
              <h5 className="fw-bold text-secondary">{this.state.currency} {this.state.total}</h5>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <Col md={4}>
                <div className="fw-bold">Billed to:</div>
                <div>{this.state.billTo || ''}</div>
                <div>{this.state.billToAddress || ''}</div>
                <div>{this.state.billToEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold">Billed From:</div>
                <div>{this.state.billFrom || ''}</div>
                <div>{this.state.billFromAddress || ''}</div>
                <div>{this.state.billFromEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold mt-2">Date Of Issue:</div>
                <div>{this.state.dateOfIssue || ''}</div>
              </Col>
            </Row>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>CATEGORY</th>
                  <th>Item</th>
                  <th>QTY</th>
                  <th className="text-end">PRICE</th>
                  <th className="text-end">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, i) => {
                  return (
                    <tr id={i} key={i}>
                      <td>
                      {item.selectedCategory || this.state.selectedCategory}
                      </td>
                      <td>
                        { item.name}
                      </td>
                      <td style={{ width: '70px' }}>
                        {item.quantity}
                      </td>
                      <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {item.price}</td>
                      <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {item.price * item.quantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.subTotal}</td>
                </tr>
                {parseFloat(this.state.taxAmount) !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TAX</td>
                    <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.taxAmount}</td>
                  </tr>
                }
                {parseFloat(this.state.discountAmount) !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>DISCOUNT</td>
                    <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.discountAmount}</td>
                  </tr>
                }
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>TOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.total}</td>
                </tr>
                <table style={{width: "300%"}}>
                <thead>
                  <tr>
                    <th style={{textAlign:"center"}}>S.NO</th>
                    <th style={{textAlign:"center"}}>Payment Type</th>
                    <th style={{textAlign:"center"}}>Payment Id</th>
                    <th style={{textAlign:"center"}}>Date</th>
                    <th style={{textAlign:"center"}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render payment type rows in the bill */}
                  {this.state.rows.map((row, index) => (
                    <tr className="text-end" key={index}>
                      <td style={{textAlign:"center"}}>{index + 1}</td>
                      <td className="fw-bold" style={{ width: '100px', textAlign:"center" }}>
    
                        <div style={{ fontWeight: "normal",  textAlign:"center" }}>
                          {`${row.paymentMode}`}
                        </div>
                      </td>
                      <td>
                      <div style={{ fontWeight: "normal", textAlign: "center" }}>
                        {`${row.paymentId || 'N/A'}`}
                      </div>
                      </td>
                      <td>
                      <div style={{ fontWeight: "normal",  textAlign:"center" }}>
                          {`${row.date}`}
                      </div>
                      </td>
                      <td className="text-center" style={{ width: '100px',  textAlign:"center" }}>
                        {this.state.currency} {(parseFloat(row.amount) || 0).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
                  <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>TOTAL PAID</td>
                      {this.state.currency}{this.state.totalPaid.toFixed(2)}
                  </tr>
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>AMOUNT DUE</td>
                      <td className="text-end" style={{ width: '100px' }}>{this.state.currency}{(parseFloat(this.state.total)-parseFloat(this.state.totalPaid) || 0).toFixed(2)}</td>
                    </tr>
              </tbody>
            </Table>
            {this.state.notes &&
              <div className="bg-light py-3 px-4 rounded">
                {this.state.notes}
              </div>}
          </div>
          <div className="mt-4 text-center footerbill" style={{backgroundColor: this.state.color2 || "lightgrey"}}>
              {/* <p className="fw-bold">Thank you!</p>
              <p>Contact us: {this.state.billFromEmail || 'email@example.com'}</p>
              <p>{this.state.billFromAddress || '1234 Street, City, Country'}</p> */}
              <div className="social-icons mt-3 d-flex justify-content-center">
                {this.state.socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.facebook}</span>
                  </div>
                )}
                {this.state.socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.instagram}</span>
                  </div>
                )}
                {this.state.socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.linkedin}</span>
                  </div>
                )}
                {this.state.socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.youtube}</span>
                  </div>
                )}
              </div>
          </div>
        </div>
        
    </Layout>)
  }
}

export default withRouter(SplitInvoiceForm1);
