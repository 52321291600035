import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loader from "../frontpage/Loader";

const AddHospital=()=>{
    const [formData, setFormData] = useState({
        HospitalName: "",
        Email: "",
        PhoneNumber: "",
        CEOName: "",
        GSTNumber: "",
        RegisterNumber: "",
        WebsiteLinks: "",
      });

      const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/addHospital', formData);
          console.log('Hospital data saved:', response.data);

          setFormData({
            HospitalName: "",
            Email: "",
            PhoneNumber: "",
            CEOName: "",
            GSTNumber: "",
            RegisterNumber: "",
            WebsiteLinks: "",
          });

          setSuccessMessage("Hospital data saved successfully!");

        } catch (error) {
          console.error('Error saving hospital data:', error);
        }
      };
    
    return(
        <Layout>
        <Loader/>
             <form onSubmit={handleSubmit}>
             <h1 style={{color:"#007bff"}} className="mb-4">Add Hospital</h1>
      <div className="form-group col-md-6">
        <label className="font-weight-bold" htmlFor="inputHospitalName">Hospital Name</label>
        <input
          type="text"
          className="form-control input-shadow input-p"
          id="inputHospitalName"
          name="HospitalName"
          placeholder="Hospital Name"
          value={formData.HospitalName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group col-md-6">
        <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
        <input
          type="email"
          className="form-control input-shadow input-p"
          id="inputEmail"
          name="Email"
          placeholder="Email"
          value={formData.Email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group col-md-6">
        <label className="font-weight-bold" htmlFor="inputPhoneNumber">Phone Number</label>
        <input
          type="text"
          className="form-control input-shadow input-p"
          id="inputPhoneNumber"
          name="PhoneNumber"
          placeholder="Phone Number"
          value={formData.PhoneNumber}
          onChange={handleChange}
        />
      </div>
      <div className="form-group col-md-6">
        <label className="font-weight-bold" htmlFor="inputCEOName">CEO Name</label>
        <input
          type="text"
          className="form-control input-shadow input-p"
          id="inputCEOName"
          name="CEOName"
          placeholder="CEO Name"
          value={formData.CEOName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputGSTNumber">GST Number</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputGSTNumber"
            name="GSTNumber"
            placeholder="GST Number"
            value={formData.GSTNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputRegisterNumber">Register Number</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputRegisterNumber"
            name="RegisterNumber"
            placeholder="Register Number"
            value={formData.RegisterNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputWebsiteLinks">Website Links</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputWebsiteLinks"
            name="WebsiteLinks"
            placeholder="Website Links"
            value={formData.WebsiteLinks}
            onChange={handleChange}
          />
        </div>

      <button type="submit" className="btn btn-primary buttoncenter mb-4">Submit</button>
    </form>
    {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        </Layout>
    );
}

export default AddHospital;