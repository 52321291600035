import React, { Fragment, useState, useEffect } from "react";
import "../styles.css";
import Menu from "./Menu";
import { Link, useLocation } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import NewIcon from "../NewIcon";
import firebase from 'firebase/compat/app';
import "./Layout.css";

const Layout = ({ children }) => {

  const [status, setStatus] = useState('');
  const [roleId, setRoleId] = useState(null);
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    console.log('Toggle sidebar');
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('User data not found');
            }
            return response.json();
          })
          .then(userData => {
            setStatus(userData.status || '');
            setRoleId(userData.roleId || null);
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
            setError('Error fetching user data');
          });
      } else {
        setError('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);


  const location = useLocation();

  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);


  const isActive = (path) => {
    return location.pathname === path
      ? { color: "#ff9900" }
      : { color: "white" };
  };
  const mockUserInfo = { _id: 1, name: "Admin User" };

  const [isFinancialsOpen, setIsFinancialsOpen] = useState(false);

  const toggleFinancialsDropdown = () => {
    setIsFinancialsOpen(!isFinancialsOpen);
  };

  const [isSchedulesOpen, setIsSchedulesOpen] = useState(false);

  const toggleSchedulesDropdown = () => {
    setIsSchedulesOpen(!isSchedulesOpen);
  };

  const [isManagementOpen, setIsManagementOpen] = useState(false);

  const toggleManagementDropdown = () => {
    setIsManagementOpen(!isManagementOpen);
  };
  
  const [isMarketingOpen, setIsMarketingOpen] = useState(false);

  const toggleMarketingDropdown = () => {
    setIsMarketingOpen(!isMarketingOpen);
  };

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const [isBrandingOpen, setIsBrandingOpen] = useState(false);

  const toggleBrandingDropdown = () => {
    setIsBrandingOpen(!isBrandingOpen);
  };

  const [isBussinessOpen, setIsBussinessOpen] = useState(false);

  const toggleBussinessDropdown = () => {
    setIsBussinessOpen(!isBussinessOpen);
  };

  const [isAccountingOpen, setIsAccountingOpen] = useState(false);

  const toggleAccountingDropdown = () => {
    setIsAccountingOpen(!isAccountingOpen);
  };

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  
  const [isWhatsappOpen, setIsWhatsappOpen] = useState(false);

  const toggleWhatsappDropdown = () => {
    setIsWhatsappOpen(!isWhatsappOpen);
  };

  const [isFormacyOpen, setIsFormacyOpen] = useState(false);

  const toggleFormacyDropdown =()=>{
    setIsFormacyOpen(!isFormacyOpen);
  }
  // const isActive = (path) => {
  //   return location.pathname === path ? 'active' : '';
  // };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('User data not found');
            }
            return response.json();
          })
          .then(userData => {
            setFirstName(userData.firstName || '');
            setLastName(userData.lastName || '');
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
          });
      } else {
        console.log('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  const displayName = firstName && lastName ? `${firstName} ${lastName}` : 'New User';

  const adminLinks = () => {
    if( roleId === 1 && status==='Passed'){
      return(
        <Fragment>
         <div className="sidebar" style={{marginTop:"15px"}}>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isFinancialsOpen}
        
        onClick={toggleFinancialsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-tachometer-alt" />
        </div>
        Financials
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFinancialsOpen? 'show' : ''}`}
        id="collapseFinancials"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className={`nav-link ${isActive('/sales&Revenue')}`} to="/sales&Revenue" style={{color:"white"}}>
            Sales & Revenue
            <NewIcon/>
          </Link>
          <Link className={`nav-link ${isActive('/patientadmin')}`} to="/patientadmin" style={{color:"white"}}>
            Patient Statistics
          </Link>
          <Link className="nav-link" to="/medicinedash" style={{color:"white"}}>
            Medicine Statistics
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Statistics
          </Link>
        </nav>
      </div>
    </div>

        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSchedulesOpen}
         
        onClick={toggleSchedulesDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        Schedules
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSchedulesOpen ? 'show' : ''}`}
        id="collapseSchedules"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}} to="/appointment">
            Appointment
            
          </Link>
          <Link className="nav-link" style={{color:"white"}} to="/reports">
            Reports
          </Link>
        </nav>
      </div>

     
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isManagementOpen}
         
        onClick={toggleManagementDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-folder-open" />
        </div>
        EMR Management
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isManagementOpen ? 'show' : ''}`}
        id="collapseManagement"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive("/list-patients")} to="/list-patients">
            Patients <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list-doctors")} to="/list-doctors">
            Doctors <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Patient Mapping Journey 
          </Link>
          <Link to="/prescription" className="nav-link" style={{color:"white"}}>
            Prescriptions
          </Link>
          <Link to="/list-prescription" className="nav-link" style={{color:"white"}}>
            List Prescriptions
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Lab Reports
          </Link>
        </nav>
      </div>


      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isMarketingOpen}
        
        onClick={toggleMarketingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bullhorn" />
        </div>
        Marketing
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isMarketingOpen ? 'show' : ''}`}
        id="collapseMarketing"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}}>
            Whatsapp Campaigns
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Offer Codes
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Campaign Analytics
          </Link>
        </nav>
      </div>


      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive(`/AdminExtraFields`)} to={`/AdminExtraFields`}>
            Admin Extra Fields
          </Link>
          <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
          {/* <Link className="nav-link" style={{color:"white"}}>
            Add Users
          </Link> */}
          <Link className="nav-link" style={isActive("/list/users")} to="/list/users">
            List Users <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/settingsAdmin")} to="/settingsAdmin">
            Admin Page <NewIcon/>
          </Link> 
          <Link className="nav-link" style={isActive("/add-Hospital")} to="/add-Hospital">
            Add Hospital <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list-Hospital")} to="/list-Hospital">
            List Hospital <NewIcon/>
          </Link>
        </nav>
      </div>


      
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBrandingOpen}
         
        onClick={toggleBrandingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bullseye" />
        </div>
        Branding
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBrandingOpen ? 'show' : ''}`}
        id="collapseBranding"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/logo" style={{color:"white"}}>
            Logo <NewIcon/>
          </Link>
          <Link className="nav-link" to="/address-branding" style={{color:"white"}}>
            Address <NewIcon/>
          </Link>
          <Link to="/social-media" className="nav-link" style={{color:"white"}}>
            Social Media <NewIcon/>
          </Link>
          <Link to="/AdminSettings" className="nav-link" style={{color:"white"}}>
            Admin Settings <NewIcon/>
          </Link>
        </nav>
      </div>


  
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>

      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
       
        aria-expanded={isFormacyOpen}
         
        onClick={toggleFormacyDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-pills" />
        </div>
         Pharmacy
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFormacyOpen ? 'show' : ''}`}
        id="collapseFormacy"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive("/list-vendors")} to="/list-vendors">
            Vendors <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list/medicine")} to="/list/medicine">
            Medicines <NewIcon/>
          </Link>
        </nav>
      </div>

       
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isAccountingOpen}
         
        onClick={toggleAccountingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-file-invoice-dollar" />
        </div>
         Accounting
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isAccountingOpen ? 'show' : ''}`}
        id="collapseAccounting"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive("/list-expenses")} to="/list-expenses">
            Patient Expenses <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/ListClinicExpenses")} to="/ListClinicExpenses">
            Operational Expenses <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/create-bill")} to="/create-bill">
            Create Bill <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/List-bill")} to="/List-bill">
            List Bill  <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/split-bill")} to="/split-bill">
            Split Bill <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/List-split")} to="/List-split">
            List Split Bill  <NewIcon/>
          </Link>
        </nav>
      </div>

    
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isNotificationOpen}
        
        onClick={toggleNotificationDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bell" />
        </div>
         Notifications
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isNotificationOpen ? 'show' : ''}`}
        id="collapseNotifications"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}}>
            Patient Email
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Notifications
          </Link>
                <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              
              aria-expanded={isWhatsappOpen}
             
              onClick={toggleWhatsappDropdown}
              style={{color:"white"}}
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-calendar-alt" />
              </div>
              Whatsapp
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </a>
                <div
            className={`collapse ${isWhatsappOpen ? 'show' : ''}`}
            id="collapseWhatsapp"
            aria-labelledby="headingTwo"
            data-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link" style={{color:"white"}}>
                Patient
              </Link>
              <Link className="nav-link" style={{color:"white"}}>
                Doctor
              </Link>
            </nav>
          </div>
        </nav>
      </div>
        
      </Fragment>
      );
    }
    else if(roleId === 2 && status === 'Passed'){
      return(
        <Fragment>
         <div className="sidebar" style={{marginTop:"15px"}}>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isFinancialsOpen}
        
        onClick={toggleFinancialsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-tachometer-alt" />
        </div>
        Financials
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFinancialsOpen ? 'show' : ''}`}
        id="collapseFinancials"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className={`nav-link ${isActive('/sales&Revenue')}`} to="/sales&Revenue" style={{color:"white"}}>
            Sales & Revenue
            <NewIcon/>
          </Link>
          <Link className={`nav-link ${isActive('/patientadmin')}`} to="/patientadmin" style={{color:"white"}}>
            Patient Statistics
          </Link>
          <Link className="nav-link" to="/medicinedash" style={{color:"white"}}>
            Medicine Statistics
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Statistics
          </Link>
        </nav>
      </div>
    </div>

        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSchedulesOpen}
         
        onClick={toggleSchedulesDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        Schedules
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSchedulesOpen ? 'show' : ''}`}
        id="collapseSchedules"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}} to="/appointment">
            Appointment
            
          </Link>
          <Link className="nav-link" style={{color:"white"}} to="/reports">
            Reports
          </Link>
        </nav>
      </div>

     
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isManagementOpen}
         
        onClick={toggleManagementDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-folder-open" />
        </div>
        EMR Management
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isManagementOpen ? 'show' : ''}`}
        id="collapseManagement"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive("/list-patients")} to="/list-patients">
            Patients <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list-doctors")} to="/list-doctors">
            Doctors <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Patient Mapping Journey 
          </Link>
          <Link to="/prescription" className="nav-link" style={{color:"white"}}>
            Prescriptions
          </Link>
          <Link to="/list-prescription" className="nav-link" style={{color:"white"}}>
            List Prescriptions
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Lab Reports
          </Link>
        </nav>
      </div>


      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isMarketingOpen}
        
        onClick={toggleMarketingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bullhorn" />
        </div>
        Marketing
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isMarketingOpen ? 'show' : ''}`}
        id="collapseMarketing"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}}>
            Whatsapp Campaigns
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Offer Codes
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Campaign Analytics
          </Link>
        </nav>
      </div>


      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive(`/AdminExtraFields`)} to={`/AdminExtraFields`}>
            Admin Extra Fields
          </Link>
          <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
          {/* <Link className="nav-link" style={{color:"white"}}>
            Add Users
          </Link> */}
          <Link className="nav-link" style={isActive("/list/users")} to="/list/users">
            List Users <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/settingsAdmin")} to="/settingsAdmin">
            Admin Page <NewIcon/>
          </Link> 
        </nav>
      </div>


      
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBrandingOpen}
         
        onClick={toggleBrandingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bullseye" />
        </div>
        Branding
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBrandingOpen ? 'show' : ''}`}
        id="collapseBranding"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/logo" style={{color:"white"}}>
            Logo <NewIcon/>
          </Link>
          <Link className="nav-link" to="/address-branding" style={{color:"white"}}>
            Address <NewIcon/>
          </Link>
          <Link to="/social-media" className="nav-link" style={{color:"white"}}>
            Social Media <NewIcon/>
          </Link>
          <Link to="/AdminSettings" className="nav-link" style={{color:"white"}}>
            Admin Settings <NewIcon/>
          </Link>
        </nav>
      </div>


  
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>

      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
       
        aria-expanded={isFormacyOpen}
         
        onClick={toggleFormacyDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-pills" />
        </div>
         Pharmacy
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFormacyOpen ? 'show' : ''}`}
        id="collapseFormacy"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive("/list-vendors")} to="/list-vendors">
            Vendors <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list/medicine")} to="/list/medicine">
            Medicines <NewIcon/>
          </Link>
        </nav>
      </div>

       
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isAccountingOpen}
         
        onClick={toggleAccountingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-file-invoice-dollar" />
        </div>
         Accounting
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isAccountingOpen ? 'show' : ''}`}
        id="collapseAccounting"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive("/list-expenses")} to="/list-expenses">
            Patinet Expenses <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/ListClinicExpenses")} to="/ListClinicExpenses">
            Operational Expenses <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/create-bill")} to="/create-bill">
            Create Bill <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/List-bill")} to="/List-bill">
            List Bill  <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/split-bill")} to="/split-bill">
            Split Bill <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/List-split")} to="/List-split">
            List Split Bill  <NewIcon/>
          </Link>
        </nav>
      </div>

    
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isNotificationOpen}
        
        onClick={toggleNotificationDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bell" />
        </div>
         Notifications
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isNotificationOpen ? 'show' : ''}`}
        id="collapseNotifications"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}}>
            Patient Email
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Notifications
          </Link>
                <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              
              aria-expanded={isWhatsappOpen}
             
              onClick={toggleWhatsappDropdown}
              style={{color:"white"}}
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-calendar-alt" />
              </div>
              Whatsapp
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </a>
                <div
            className={`collapse ${isWhatsappOpen ? 'show' : ''}`}
            id="collapseWhatsapp"
            aria-labelledby="headingTwo"
            data-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link" style={{color:"white"}}>
                Patient
              </Link>
              <Link className="nav-link" style={{color:"white"}}>
                Doctor
              </Link>
            </nav>
          </div>
        </nav>
      </div>
        
      </Fragment>
      );
    }
    else if( roleId === 3 && status === 'Passed'){
      return(
        <Fragment>
          <div className="sidebar" style={{marginTop:"15px"}}>
          <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isFinancialsOpen}
        
        onClick={toggleFinancialsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-tachometer-alt" />
        </div>
        Financials
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFinancialsOpen ? 'show' : ''}`}
        id="collapseFinancials"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={{color:"white"}}>
            Doctor Statistics
          </Link>
        </nav>
          </div>
          <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSchedulesOpen}
         
        onClick={toggleSchedulesDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        Schedules
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSchedulesOpen ? 'show' : ''}`}
        id="collapseSchedules"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}} to="/appointment">
            Appointment
            
          </Link>
          <Link className="nav-link" style={{color:"white"}} to="/reports">
            Reports
          </Link>
        </nav>
      </div>
      
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isManagementOpen}
         
        onClick={toggleManagementDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-folder-open" />
        </div>
        EMR Management
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isManagementOpen ? 'show' : ''}`}
        id="collapseManagement"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link to="/prescription" className="nav-link" style={{color:"white"}}>
            Prescriptions
          </Link>
          <Link to="/list-prescription" className="nav-link" style={{color:"white"}}>
            List Prescriptions
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Lab Reports
          </Link>
        </nav>
        </div>
        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
        </nav>
        </div>
          </div>
        </Fragment>
      )
    }
    else if(roleId === 4 && status === 'Passed'){
      return(
        <Fragment>
           <div className="sidebar" style={{marginTop:"15px"}}>
           <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isFinancialsOpen}
        
        onClick={toggleFinancialsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-tachometer-alt" />
        </div>
        Financials
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFinancialsOpen ? 'show' : ''}`}
        id="collapseFinancials"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className={`nav-link ${isActive('/sales&Revenue')}`} to="/sales&Revenue" style={{color:"white"}}>
            Sales & Revenue
            <NewIcon/>
          </Link>
          <Link className={`nav-link ${isActive('/patientadmin')}`} to="/patientadmin" style={{color:"white"}}>
            Patient Statistics
          </Link>
          <Link className="nav-link" to="/medicinedash" style={{color:"white"}}>
            Medicine Statistics
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Statistics
          </Link>
        </nav>
      </div>
        
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
        </nav>
        </div>
        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
       
        aria-expanded={isFormacyOpen}
         
        onClick={toggleFormacyDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-pills" />
        </div>
         Pharmacy
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFormacyOpen ? 'show' : ''}`}
        id="collapseFormacy"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive("/list-vendors")} to="/list-vendors">
            Vendors <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/list/medicine")} to="/list/medicine">
            Medicines <NewIcon/>
          </Link>
        </nav>
      </div>

       
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isAccountingOpen}
         
        onClick={toggleAccountingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-file-invoice-dollar" />
        </div>
         Accounting
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isAccountingOpen ? 'show' : ''}`}
        id="collapseAccounting"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive("/list-expenses")} to="/list-expenses">
            Expenses <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/create-bill")} to="/create-bill">
            Create Bill <NewIcon/>
          </Link>
          <Link className="nav-link" style={isActive("/List-bill")} to="/List-bill">
            List Bill  <NewIcon/>
          </Link>
        </nav>
      </div>

           </div>
        </Fragment>
      );
    }
    else if (roleId === 5 && status === 'Passed'){
      return(
        <Fragment>
           <div className="sidebar" style={{marginTop:"15px"}}>
           <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSchedulesOpen}
         
        onClick={toggleSchedulesDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        Schedules
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSchedulesOpen ? 'show' : ''}`}
        id="collapseSchedules"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}} to="/appointment">
            Appointment
            
          </Link>
          <Link className="nav-link" style={{color:"white"}} to="/reports">
            Reports
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
        </nav>
        </div>
        
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>

           </div>
        </Fragment>
      )
    }
    else if (roleId === 6 && status === 'Passed'){
      return(
        <Fragment>
          <div className="sidebar" style={{marginTop:"15px"}}>
          <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isFinancialsOpen}
        
        onClick={toggleFinancialsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-tachometer-alt" />
        </div>
        Financials
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isFinancialsOpen ? 'show' : ''}`}
        id="collapseFinancials"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className={`nav-link ${isActive('/sales&Revenue')}`} to="/sales&Revenue" style={{color:"white"}}>
            Sales & Revenue
            <NewIcon/>
          </Link>
          <Link className={`nav-link ${isActive('/patientadmin')}`} to="/patientadmin" style={{color:"white"}}>
            Patient Statistics
          </Link>
          <Link className="nav-link" to="/medicinedash" style={{color:"white"}}>
            Medicine Statistics
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Doctor Statistics
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isManagementOpen}
         
        onClick={toggleManagementDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-folder-open" />
        </div>
        EMR Management
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isManagementOpen ? 'show' : ''}`}
        id="collapseManagement"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={{color:"white"}}>
            Patient Mapping Journey 
          </Link>
          <Link to="/prescription" className="nav-link" style={{color:"white"}}>
            Prescriptions
          </Link>
          <Link to="/list-prescription" className="nav-link" style={{color:"white"}}>
            List Prescriptions
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Lab Reports
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isMarketingOpen}
        
        onClick={toggleMarketingDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-bullhorn" />
        </div>
        Marketing
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isMarketingOpen ? 'show' : ''}`}
        id="collapseMarketing"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}}>
            Whatsapp Campaigns
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Offer Codes
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Campaign Analytics
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
        </nav>
      </div>
      
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>
          </div>
        </Fragment>
      );
    }
    else if (roleId === 7 && status === 'Passed') {
      return(
        <Fragment>
         <div className="sidebar" style={{marginTop:"15px"}}>
          
        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSchedulesOpen}
         
        onClick={toggleSchedulesDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        Schedules
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSchedulesOpen ? 'show' : ''}`}
        id="collapseSchedules"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" style={{color:"white"}} to="/appointment">
            Appointment
            
          </Link>
          <Link className="nav-link" style={{color:"white"}} to="/reports">
            Reports
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
         
        aria-expanded={isManagementOpen}
         
        onClick={toggleManagementDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-folder-open" />
        </div>
        EMR Management
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isManagementOpen ? 'show' : ''}`}
        id="collapseManagement"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={{color:"white"}}>
            Patient Mapping Journey 
          </Link>
          <Link to="/prescription" className="nav-link" style={{color:"white"}}>
            Prescriptions
          </Link>
          <Link to="/list-prescription" className="nav-link" style={{color:"white"}}>
            List Prescriptions
          </Link>
          <Link className="nav-link" style={{color:"white"}}>
            Lab Reports
          </Link>
        </nav>
      </div>
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isSettingsOpen}
        
        onClick={toggleSettingsDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fas fa-cogs" />
        </div>
        Settings
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isSettingsOpen ? 'show' : ''}`}
        id="collapseSettings"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
        <Link className="nav-link" style={isActive(`/profile`)} to={`/profile`}>
            profile <NewIcon/>
          </Link>
        <Link className="nav-link" style={{color:"white"}}>
            Themes
          </Link>
        </nav>
        </div>
        <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        
        aria-expanded={isBussinessOpen}
         
        onClick={toggleBussinessDropdown}
        style={{color:"white", fontWeight:"bold",textTransform:"uppercase",fontSize:"15px"}}
      >
        <div className="sb-nav-link-icon">
        <i className="fab fa-google" />
        </div>
        Google Business
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className={`collapse ${isBussinessOpen ? 'show' : ''}`}
        id="collapseBusiness"
        aria-labelledby="headingTwo"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          <Link className="nav-link" to="/reviews" style={{color:"white"}}>
            Patient Reviews
          </Link>
         
        </nav>
      </div>
        </div>
        </Fragment>
      );
      }
      else if ( status === 'failed') {
        return(
          <Fragment>
            <div className="sidebar" style={{marginTop:"15px"}}>
              
            </div>
          </Fragment>
        );
      }
      else{
    
    return (
      <Fragment>
         <div className="sidebar" style={{marginTop:"15px"}}>
      
      </div>
        
      </Fragment>
    );
  };
  }
  const loggedIn = () => <div className="small">Logged in as:</div>;

  return (
      <nav className="sb-nav-fixed">
        <Menu />
        <div id="layoutSidenav">
        <div id="layoutSidenav_nav" className={isSidebarOpen ? 'show-sidebar' : ''}>
            <nav
              className="sb-sidenav accordion sb-sidenav-dark"
              id="sidenavAccordion"
            >
               <div className="close-sidebar-icon" onClick={toggleSidebar}>
                <i className="fas fa-times"></i>
              </div>
              <div className="sb-sidenav-menu">
                <div className="nav">{adminLinks()}</div>
              </div>
              <div className="sb-sidenav-footer">
                {loggedIn()}
                {displayName}
              </div>
            </nav>
          </div>
          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid">
              {/* <h1 className="mt-4 color-bright">Dashboard</h1> */}
                {/* <ol className="breadcrumb mb-4" style={{backgroundColor:"lightgray"}}>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>  */}
                {children}
              </div>
            </main>
          </div>
        </div>
        <div className="mobile-footer">
          <a href="#" onClick={toggleSidebar}>
            <i className="fas fa-bars"></i>
          </a>
          <a href="/profile">
            <i className="fas fa-user"></i>
          </a>
        </div>
      </nav>
  );
  
};

export default Layout;
