import React,{useState, useEffect} from "react";
import Layout from "../core/Layout";
import { Link , useParams, useNavigate} from "react-router-dom";
// import { Bar } from "react-chartjs-2";
import axios from 'axios';
import BarChart from './BarChart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { BarChart as RechartBarChart,Bar,Rectangle,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ResponsiveContainer} from 'recharts';
import { format, parse } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Pagination from 'react-js-pagination';
import Loader from "../frontpage/Loader";
import {
  ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

const PatientStatus = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Check if user is already logged in
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setTimeout(() => {
          navigate('/signin'); 
      }, 100);
      }
    });
  }, [navigate]);

  
  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  

  const expenseChartData = {
    labels: ["2021-03-31", "2021-01-31","2021-02-28"],
    datasets: [
      {
        backgroundColor: ["#007bff", "#dc3545"],
        data: [2500, 5000, 2400],
      },
    ],
  };


  const [data, setData] = useState({
    totalAmountSum: 0,
    paidSum: 0,
    balanceSum: 0,
  });
  
  
    const fetchData1 = async (from,to, hospitalId) => {
      console.log("from", from);
      console.log("to", to);
      try {
        const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/filterData', {
          params: {
            from, to, hospitalId
           },
        });
        console.log("Response:",response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    const[paid1,setPaidValues1]=useState("")

    const fetchData2 = async (from,to, hospitalId) => {
      console.log("from chart date:", from);
      console.log("to chart date:", to);

      try{
        const response= await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/filterchart',{
          params: {
            from, to, hospitalId
           },
          });
           const data= response.data;
            console.log("data value of charts:", data);

           setPaidValues(data.paidValues);
           setTotalAmountValues(data.totalAmountValues);
           setDates(data.dates);
      } catch (error) {
        console.error('Error fetching data', error);
    }
  }
    const [chartData, setChartData] = useState([]);

    const [paidValues, setPaidValues] = useState([]);
    const [totalAmountValues, setTotalAmountValues] = useState([]);
    const [dates, setDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const hospitalId = formData1.HospitalId;

      useEffect(() => {
        const fetchData1 = async () => {
          try {
            const response = await fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/chartfetch',{
              params: {hospitalId}
            }); // Replace with your backend URL
            const data = await response.json();
            console.log("data:",data);
    
          setPaidValues(data.paidValues || []);
            setTotalAmountValues(data.totalAmountValues || []);
            setDates(data.dates || []);
          } catch (error) {
            console.error('Error fetching data', error);
          }
        };
        if(hospitalId){
        fetchData1();
        }
      }, [hospitalId]);

    const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/filterDataByDate');
          console.log("Response:", response.data);
  
          if (!response.data || !response.data.totalAmountSum || !response.data.paidSum || !response.data.balanceSum) {
            throw new Error('Invalid data format received');
          }
  
          setData({
            totalAmountSum: response.data.totalAmountSum,
            paidSum: response.data.paidSum,
            balanceSum: response.data.balanceSum,
          });
        } catch (error) {
          console.error('Error fetching data', error);
        }
      };
  
      fetchData();
    }, []);


  const rechartData = [
    {
      name: '01/03/2021',
      totalbalance: 3000,
      expense: 1398,
      amt: 2210,
    },
    {
      name: '02/03/2021',
      totalbalance: 11000,
      expense: 5800,
      amt: 2290,
    },
    {
      name: '03/03/2021',
      totalbalance: 2780,
      expense: 3908,
      amt: 2000,
    },
    {
      name: '04/03/2021',
      totalbalance: 1890,
      expense: 4800,
      amt: 2181,
    },
    {
      name: '05/03/2021',
      totalbalance: 2390,
      expense: 3800,
      amt: 2500,
    },
    {
      name: '06/03/2021',
      totalbalance: 3490,
      expense: 4300,
      amt: 2100,
    },
  ];

  const initialStartDate = parse('01-01-2024', 'dd-MM-yyyy', new Date());
  const initialEndDate = parse('31-12-2024', 'dd-MM-yyyy', new Date());

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleSearch = async (event) => {
    event.preventDefault();
    if (startDate && endDate) {
      const from = format(startDate, 'dd-MM-yyyy');
      const to = format(endDate, 'dd-MM-yyyy');
      const hospitalId = formData1.HospitalId;
      await fetchData1(from, to, hospitalId);
      await fetchData2(from, to, hospitalId);
      const patientStatus = await fetchPatientStatusData(from, to, hospitalId);
      if (patientStatus) {
        setPatientStatusData(patientStatus);
      }
    }
  };

  useEffect(() => {
    const from = format(startDate, 'dd-MM-yyyy');
    const to = format(endDate, 'dd-MM-yyyy');
    const hospitalId = formData1.HospitalId;
    
    if (hospitalId) {
      fetchData1(from, to, hospitalId);
      fetchData2(from, to, hospitalId);
      fetchPatientStatusData(from, to, hospitalId);
    }
  }, [startDate, endDate, formData1.HospitalId]);

  const handleChangeStart = (event) => {
    const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setStartDate(date);
  };

  const handleChangeEnd = (event) => {
    const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setEndDate(date);
  };

  const handleChange=(date)=>{
    setStartDate(date);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, dates.length);
  const currentData = dates.slice(startIndex, endIndex);
 
  const [patientStatusData, setPatientStatusData] = useState({
    noOfInpatients: 0,
    noOfOutpatients: 0,
    noOfAdmittedPatients: 0,
    noOfDischargedPatients: 0,
    noOfPatientsUnderSurgery: 0,
    noOfPatientsUnderTreatment: 0
  });

  
  const [noOfInpatients, setNoOfInpatients] = useState([]);
  const [noOfOutpatients, setNoOfOutpatients] = useState([]);
  const [noOfAdmittedPatients, setNoOfAdmittedPatients] = useState([]);
  const [noOfDischargedPatients, setNoOfDischargedPatients] = useState([]);
  const [noOfPatientsUnderSurgery, setNoOfPatientsUnderSurgery] = useState([]);
  const [noOfPatientsUnderTreatment, setNoOfPatientsUnderTreatment] = useState([]);
  
  const patientStatusChartData = dates.map((date, index) => ({
    name: date,
    Inpatients: noOfInpatients[index],
    Outpatients: noOfOutpatients[index],
    Admitted: noOfAdmittedPatients[index],
    Discharged: noOfDischargedPatients[index],
    UnderSurgery: noOfPatientsUnderSurgery[index],
    UnderTreatment: noOfPatientsUnderTreatment[index],
    TotalBilled: totalAmountValues[index],  
    TotalPaid: paidValues[index],  // Add your total paid data
    TotalPending: totalAmountValues[index] - paidValues[index],  // Calculate total pending
  }))
  console.log("patientStatusData:", patientStatusChartData);

  
  const fetchPatientStatusData = async (from, to, hospitalId) => {
    try {
      const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/filterDataPatient', {
        params: { from, to, hospitalId},
      });
      const data = response.data;
      console.log("fetch patient status data:",data);
      const totalInpatients = data.noOfInpatients.reduce((acc, val) => acc + val, 0);
      const totalOutpatients = data.noOfOutpatients.reduce((acc, val) => acc + val, 0);
      const totalAdmittedPatients = data.noOfAdmittedPatients.reduce((acc, val) => acc + val, 0);
      const totalDischargedPatients = data.noOfDischargedPatients.reduce((acc, val) => acc + val, 0);
      const totalPatientsUnderSurgery = data.noOfPatientsUnderSurgery.reduce((acc, val) => acc + val, 0);
      const totalPatientsUnderTreatment = data.noOfPatientsUnderTreatment.reduce((acc, val) => acc + val, 0);

      setPatientStatusData({
        noOfInpatients: totalInpatients,
        noOfOutpatients: totalOutpatients,
        noOfAdmittedPatients: totalAdmittedPatients,
        noOfDischargedPatients: totalDischargedPatients,
        noOfPatientsUnderSurgery: totalPatientsUnderSurgery,
        noOfPatientsUnderTreatment: totalPatientsUnderTreatment,
      });

      setDates(data.dates);
      setNoOfInpatients(data.noOfInpatients);
      setNoOfOutpatients(data.noOfOutpatients);
      setNoOfAdmittedPatients(data.noOfAdmittedPatients);
      setNoOfDischargedPatients(data.noOfDischargedPatients);
      setNoOfPatientsUnderSurgery(data.noOfPatientsUnderSurgery);
      setNoOfPatientsUnderTreatment(data.noOfPatientsUnderTreatment);
    } catch (error) {
      console.error('Error fetching patient status data:', error);
    }
  };

 


  return (
    <Layout title="Dashboard">
    <Loader/>
    <h1 className="mt-4 color-bright">Patient Statistics</h1>
    <div className="row mb-5">
      
      </div>
      <div className="row justify-content-center">
      <div className="col-md-2">
        <input
          type="date"
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeStart}
          className="form-control"
          placeholder="Start Date"
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div className="col-md-2 endateAdmind">
        <input
          type="date"
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeEnd}
          className="form-control"
          placeholder="End Date"
          min={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        />
      </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <button className="btn btn-primary endabutton" onClick={(event) => handleSearch(event)}>
              Search
            </button>
          </div>
      </div><br></br>

    <div className="row">
      <div className="col-xl-3 col-md-6">
        <div className="card basic-card-aqua text-white mb-4">
          <div className="card-body">Total Billed</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              
              style={{ textDecoration: 'none' }}
            >
              {data.totalAmountSum}
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card basic-card-lips text-white mb-4">
          <div className="card-body">Total Paid</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              
              style={{ textDecoration: 'none' }}
            >
              {data.paidSum}
            </Link>
          </div>
        </div>
      </div>
    
      <div className="col-xl-3 col-md-6">
        <div className="card basic-card-dark text-white mb-4">
          <div className="card-body">Total Pending</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
              {data.totalAmountSum - data.paidSum}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card bg-success text-white mb-4">
          <div className="card-body">No of Inbound Patients</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfInpatients}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{backgroundColor:"#918ced"}}>
          <div className="card-body">No of OutBound Patients</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfOutpatients}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{backgroundColor:"#e8d77b"}}>
          <div className="card-body">No of Patients Admitted</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfAdmittedPatients}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card text-white mb-4" style={{backgroundColor:"#edb287"}}>
          <div className="card-body">No of Patients Discharged</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfDischargedPatients}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{backgroundColor:"#c5def0"}}>
          <div className="card-body">No of Patients Under Surgery</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfPatientsUnderSurgery}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card text-white mb-4" style={{backgroundColor:"#9580a6"}}>
          <div className="card-body">No of Patients Under Treatment</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }}>
            {patientStatusData.noOfPatientsUnderTreatment}
            </a>
          </div>
        </div>
      </div>
      
      <div className="col-lg-12">
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-chart-bar mr-1" />
             Revenue and Cost Analysis
          </div>
          <div className="card-body">
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              data={patientStatusChartData}
              margin={{
                top: 20, right: 20, bottom: 5, left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              
              {/* X-Axis */}
              <XAxis dataKey="name" />
              
              {/* Y-Axis for Patient Data */}
              <YAxis yAxisId="left" orientation="left" />
              
              {/* Y-Axis for Financial Data */}
              <YAxis yAxisId="right" orientation="right" />
              
              <Tooltip />
              <Legend />
              
              {/* Bars for Patient Data */}
              <Bar yAxisId="left" dataKey="Inpatients" fill="#413ea0" />
              <Bar yAxisId="left" dataKey="Outpatients" fill="#ff7300" />
              <Bar yAxisId="left" dataKey="Admitted" fill="#82ca9d" />
              <Bar yAxisId="left" dataKey="Discharged" fill="#8884d8" />
              <Bar yAxisId="left" dataKey="UnderSurgery" fill="#ffc658" />
              <Bar yAxisId="left" dataKey="UnderTreatment" fill="#d0ed57" />
              
              {/* Lines for Financial Data */}
              <Line yAxisId="right" type="monotone" dataKey="TotalBilled" stroke="#ff7300" />
              <Line yAxisId="right" type="monotone" dataKey="TotalPaid" stroke="#8884d8" />
              <Line yAxisId="right" type="monotone" dataKey="TotalPending" stroke="#82ca9d" />
            </ComposedChart>
          </ResponsiveContainer>

          </div>
          <div className="card-footer small text-muted">
            Updated yesterday at 11:59 PM
          </div>
        </div>
      </div>

     
      <table style={{ width: '80%', borderCollapse: 'collapse', marginTop: '20px', marginLeft: '40px' }}>
        <thead>
          <tr style={{ backgroundColor: '#007bff', color: 'white' }}>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>S.No</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Date</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Paid</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Total Amount</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Balance</th>
          </tr>
        </thead>
        <tbody>
        {currentData.map((date, index) => {
        const paid = paidValues[startIndex + index];
        const totalAmount = totalAmountValues[startIndex + index];
        const balance = totalAmount - paid;

          return (
            <tr key={index} style={{ textAlign: 'center' }}>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{startIndex + index + 1}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{date}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{paid}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{totalAmount}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{balance}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={dates.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  </Layout>
  );
};

export default PatientStatus;
