import React, {useState, useEffect} from 'react';
import Layout from "../core/Layout";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../frontpage/Loader';

const Reviews=()=>{

    
    const [testimonialData, setTestimonialData] = useState([]);

    useEffect(() => {
        const fetchTestimonialData = async () => {
          try {
            const response = await fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/testimonials');
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const testimonials = await response.json();
            console.log("testimonials:",testimonials);
            setTestimonialData(testimonials);
           
          } catch (error) {
            console.error('Error fetching testimonial data:', error);
          }
        };
    
        fetchTestimonialData();
      }, []);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

 
  const handleButtonClick = async () => {
    const dateRange = document.getElementById('date-range').value;
    const star4 = document.getElementById('4star').checked;
    const star5 = document.getElementById('5star').checked;
    const minWords = document.getElementById('min-words').value;

    const filters = {
      dateRange,
      star4,
      star5,
      minWords
    };

    try {
      const response = await fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/fetch-reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(filters)
      });
      const data = await response.json();
      console.log(data); // This will log the reviews fetched from Google API

      const successMessage = document.getElementById('success-message');
      successMessage.style.display = 'block';
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

    return(
        <Layout title="Reviews">
        <Loader/>
            <>
            <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
        <h2 className="mb-2" style={{color:"blue"}}>Trigger Review</h2>
        <select id="date-range" className="mb-3">
          <option value="1">Last one month</option>
          <option value="3">Last three months</option>
          <option value="6">Last six months</option>
          <option value="12">One year</option>
          <option value="24">Two years</option>
        </select><br></br>
        <label><input type="checkbox" id="4star" value="4"/> 4 Star</label>
        <label><input type="checkbox" id="5star" value="5"/> 5 Star</label><br></br>
        <select id="min-words" className="mb-3">
          <option value="20">Minimum 20 words</option>
          <option value="30">Minimum 30 words</option>
          <option value="40">Minimum 40 words</option>
          <option value="50">Minimum 50 words</option>
        </select><br></br>
        
        <button className='styled-button' onClick={handleButtonClick}>Fetch and Store Reviews</button>
        <div id="success-message" className="success-message" style={{display: "none", color: "green"}}>Reviews fetched and stored successfully!</div>
        </div>
      </div>
      <h1 style={{color:"#007bff"}}>Testimonial</h1>
      <div className="col-md-7 text-center heading-section ftco-animate">
            
             
            </div>
            <div className="ftco-section testimony-section">
        <div className="container" style={{height:"700px"}}>
          <div className="row justify-content-center mb-5 pb-3">
           
          </div>
          
            <div className="col-md-12">
              <Slider {...settings}>
              {testimonialData.map(testimonial => (
                  <div key={testimonial.time} className="item" style={{marginRight:"10px"}}>
                    <div className="card" style={{ border: "none", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                      <div className="card-body text-center" style={{ height: "600px" }}>
                        <h5 className="card-title" style={{ fontWeight: 'bold', color: "#853f91"}}>{testimonial.author_name}</h5>
                        <div className="card-text-container" style={{ maxHeight: "520px", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <p className="card-text mt-1 text-justify">{testimonial.text}</p>
                          </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            </div>
          </div>
      
     
            </>
        </Layout>
    );
}

export default Reviews;