import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useParams, useNavigate  } from "react-router-dom";
import { expenses } from "./ListExpenses";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
// import { useHistory } from 'react-router-dom';

const UpdateExpenses = () => {

  const { expenseId } = useParams();
  
  const [expense, setExpense] = useState({
    PatientName: "",
    totalAmount: "",
    description: "",
    date: moment().format('DD/MM/YYYY'),
    Paid: "",
    Balance: "",
    patientId: ""
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const [patientOptions, setPatientOptions] = useState([]);

  useEffect(() => {
    console.log("Extracted patientId:", expenseId);
    
    const fetchExpense = async () => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/expense/${expenseId}`,{
          params: {
            HospitalId: formData1.HospitalId 
          }
        });
        const expenseData = response.data;
        let parsedDate;
          if (expenseData.Date) {
            parsedDate = moment(expenseData.Date, "DD/MM/YYYY").toDate();
          } else {
            parsedDate = new Date();  // Fallback to current date if not provided
          }
        setExpense({ ...expenseData, Date: parsedDate });
      } catch (error) {
        console.error('Error fetching expense: ', error);
      }
    };

    fetchExpense();
  },[expenseId,formData1.HospitalId]);

  const hospitalId=formData1.HospitalId;

  useEffect(() => {
    const fetchPatientDetails = async (hospitalId) => {
      try {
        const response = await axios.get(
          'https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/fetchPatientNames',
          { params: { hospitalId } }
        );
        const formattedPatientOptions = response.data.map(patient => ({
          value: patient.patientId,
          label: patient.Name
        }));
        setPatientOptions(formattedPatientOptions);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };

    if (hospitalId) fetchPatientDetails(hospitalId);
  }, [hospitalId]);

  const handleChange = (e) => {
   
      const { name, value } = e.target;

      setExpense({ ...expense, [name]: value });
    
  };

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setExpense((prevExpense) => ({ ...prevExpense, Date: formattedDate }));
  };

  const handlePatientChange = (e) => {
    const selectedPatient = patientOptions.find(patient => patient.value === e.target.value);
    if (selectedPatient) {
      setExpense({ ...expense, patientId: selectedPatient.value, PatientName: selectedPatient.label });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedExpense = {
        ...expense,
        HospitalId: formData1.HospitalId,
        date: moment(expense.date, "DD/MM/YYYY").format("DD/MM/YYYY")  // Ensure date is correctly formatted
      };
      const response = await axios.put(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updateexpenses/${expenseId}`, formattedExpense);

      setSuccessMessage('Expenses updated successfully');
      setErrorMessage('');
    } catch (error) {
      console.error('Error updating expense:', error);
      setSuccessMessage('');
      setErrorMessage('Error updating expense');
    }
  };

  const formattedDate = moment(expense.Date, "DD/MM/YYYY").isValid()
  ? moment(expense.Date, "DD/MM/YYYY").toDate()
  : new Date();

  const UpdateExpenseForm = () => (
    <div className="form-group col-md-12">
      <form onSubmit={handleSubmit}>
        <div className="form-row" style={{marginLeft:"20%"}}>
          {/* First Column */}
          <div className="form-group col-md-4">
            <label className="font-weight-bold" htmlFor="patientId">
              Patient ID
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="patientId"
              name="patientId"
              placeholder="Patient ID"
              value={expense.patientId}
              onChange={handleChange}
              disabled
            /><br></br>
  
            <label className="font-weight-bold" htmlFor="inputAddress2">
              Name
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="inputAddress2"
              name="PatientName"
              placeholder="Name"
              value={expense.PatientName}
              onChange={handleChange}
            /><br></br>
  
            <label
              className="font-weight-bold"
              htmlFor="exampleFormControlTextarea1"
            >
               Description
            </label>
            <textarea
              className="form-control input-bg-light-grey input-shadow3 input-p"
              id="description"
              name="description"
              value={expense.description}
              onChange={handleChange}
              placeholder="Write a description"
              rows="3"
            />
          </div>
  
          {/* Second Column */}
          <div className="form-group col-md-4" style={{marginLeft:"10px"}}>
            <label className="font-weight-bold" htmlFor="inputAddress">
              Date
            </label>
            <div className="input-container">
              <DatePicker
                selected={formattedDate}
                onChange={handleDateChange}
                className="form-control input-shadow1 input-p"
              />
              <div className="icon-container">
                <i className="fa fa-calendar" style={{ color: "blue" }}></i>
              </div>
            </div><br></br>
  
            <label className="font-weight-bold" htmlFor="inputAddress">
              Amount
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Amount"
              name="text"
              value={expense.totalAmount}
              onChange={handleChange}
            /><br></br>
  
            <label
              className="font-weight-bold"
              htmlFor="exampleFormControlSelect1 input-p"
            >
              Total Paid
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="paid"
              name="paid"
              placeholder="Amount"
              value={expense.Paid}
              onChange={handleChange}
            /><br></br>
  
            <label className="font-weight-bold" htmlFor="balance">
              Balance
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="balance"
              name="balance"
              placeholder="Balance"
              value={expense.Balance}
              onChange={handleChange}
            />
          </div>
           
          
        </div><br></br>
        <button type="submit" className="btn btn-primary" style={{marginLeft:"42%",width:"150px"}}>
               Save
            </button>
      </form>
      {successMessage && (
        <div className="alert alert-success mt-3" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-3" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );

  return (
    <Layout title="Category Treatment Form">
      <>
      <h1 className="mt-4 color-bright">Update Expenses</h1><br></br>
        {UpdateExpenseForm()}
      </>
    </Layout>
  );
};
// const { patient_id } = useParams();
// const history = useHistory();
// const [expense, setExpense] = useState({});
// const [loading, setLoading] = useState(true);

// useEffect(() => {
//   const fetchExpense = async () => {
//     try {
//       const response = await axios.get(`/get-expense/${patient_id}`);
//       setExpense(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching expense: ', error);
//       setLoading(false);
//     }
//   };

//   fetchExpense();
// }, [patient_id]);

// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setExpense((prevExpense) => ({ ...prevExpense, [name]: value }));
// };

// const handleDateChange = (date) => {
//   setExpense((prevExpense) => ({ ...prevExpense, date }));
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   try {
//     await axios.put(`/update-expense/${expense._id}`, expense);
//     history.push('/');
//   } catch (error) {
//     console.error('Error updating expense: ', error);
//   }
// };

// if (loading) {
//   return <div>Loading...</div>;
// }

// return (
//   <div className="form-group col-md-12">
//     <form onSubmit={handleSubmit}>
//       <div className="form-row">
//         <div className="form-group col-md-6">
//           <label className="font-weight-bold" htmlFor="name">Name</label>
//           <input
//             type="text"
//             className="form-control"
//             id="name"
//             name="name"
//             placeholder="Name"
//             value={expense.name || ''}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="form-group col-md-6">
//           <label className="font-weight-bold" htmlFor="total_amount">Total Amount</label>
//           <input
//             type="text"
//             className="form-control"
//             id="total_amount"
//             name="total_amount"
//             placeholder="Amount"
//             value={expense.total_amount || ''}
//             onChange={handleChange}
//           />
//         </div>
//       </div>
//       <div className="form-row">
//         <div className="form-group col-md-6">
//           <label className="font-weight-bold" htmlFor="date">Date</label>
//           <DatePicker
//             selected={new Date(expense.date)}
//             onChange={handleDateChange}
//             className="form-control"
//           />
//         </div>
//       </div>
//       <div className="form-row">
//         <div className="form-group col-md-6">
//           <label className="font-weight-bold" htmlFor="description">Description</label>
//           <textarea
//             className="form-control"
//             id="description"
//             name="description"
//             value={expense.description || ''}
//             onChange={handleChange}
//             placeholder="Write a description"
//             rows="3"
//           />
//         </div>
//       </div>
//       <div className="form-row">
//         <div className="form-group col-md-3">
//           <div className="form-group">
//             <label className="font-weight-bold" htmlFor="paid">Total Paid</label>
//             <input
//               type="text"
//               className="form-control"
//               id="paid"
//               name="paid"
//               placeholder="Amount"
//               value={expense.paid || ''}
//               onChange={handleChange}
//             />
//           </div>
//         </div>
//       </div>
//       <button type="submit" className="btn btn-primary">Save</button>
//     </form>
//   </div>
// );
// };

export default UpdateExpenses;
