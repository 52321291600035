import React, { useState, useEffect} from "react";
import Layout from "../core/Layout";


const NormalUser=()=>{
    return(
        <div className="cannotselect">
        <Layout>
        

            <div className="loading">
            <p>Please wait..Your access request has been sent to the admin.</p>
            <span>
                <i />
                <i />
            </span>
            </div>
        </Layout>
        </div>
        
    );
}

export default NormalUser;