import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import firebase from 'firebase/compat/app';
import Loader from "../frontpage/Loader";
// Popup component
const Popup = ({ title, description, onClose, onConfirm, onCancel }) => (
  <div className="popup-overlay">
    <div className="popup-content">
      <button className="close-btn" onClick={onClose}>x</button>
      <h2>{title}</h2>
      <p>{description}</p>
      <button className="confirm-btn" onClick={onConfirm}>OK</button>
      <button className="cancel-btn" onClick={onCancel}>Cancel</button>
    </div>
  </div>
);

const ListUsers = () => {
  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [toggleIndex, setToggleIndex] = useState(null);
  const [toggleState, setToggleState] = useState(false);
  const [role, setRole] = useState(""); 
  const [hospitalId, setHospitalId] = useState("");
  const [message, setMessage] = useState(""); 


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const deleteHandler = async (userId, hospital) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/ListdeleteUser`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            hospital,
            userId,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete user');
        }
  
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const searchUsers = (target) => {
    // Implement search functionality if needed
  };

  const countUsers = () => {
    return data.length;
  };

  const indexOfLastUser = activePage * itemPerPage;
  const indexOfFirstUser = indexOfLastUser - itemPerPage;
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    // Set up Firebase auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('User data not found');
          }
          return response.json();
        })
        .then(userData => {
          setRole(userData.role || '');
          setHospitalId(userData.hospital.HospitalId || '');
          
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          setMessage('Error fetching user data');
        });
      } else {
        setMessage('User not authenticated');
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    fetchUsers();
  }, [activePage, role]);

  const fetchUsers = async () => {
    try {
      let url = `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUsersByHospital/${hospitalId}`;
      if (role === "Super Admin") {
        url = `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getAllUsers`;
      }
      const response = await fetch(url);
      const users = await response.json();
      const usersArray = users ? Object.values(users) : [];
      setData(usersArray);
      setTotalItemsCount(usersArray.length);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleToggleChange = (user, index) => {
    console.log("Toggling:", user.status, "Index:", index);
    setCurrentUser(user);
    setToggleIndex(index);
    setToggleState(user.status === "Passed");
    setPopupVisible(true);
  };
  

  const updateUserPermission = async (hospital,userId, status) => {
    try {
      const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updateUserPermission`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hospital,
          userId,
          status,
        }),
      });

      if (response.ok) {
        setData((prevData) =>
          prevData.map((user) =>
            user.userId === userId ? { ...user, status } : user
          )
        );
        setPopupVisible(false);
        setCurrentUser(null);
        setToggleIndex(null);
      } else {
        console.error("Failed to update permission");
      }
    } catch (error) {
      console.error("Error updating permission:", error);
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    setCurrentUser(null);
    setToggleIndex(null);
  };

  const handlePopupConfirm = () => {
    const newStatus = toggleState ? "Failed" : "Passed";
    console.log("Updating status to:", newStatus);
    updateUserPermission(currentUser.hospital,currentUser.userId, newStatus);
  };

  const handlePopupCancel = () => {
    setPopupVisible(false);
    setCurrentUser(null);
    setToggleIndex(null);
  };

  return (
    <Layout
      title="Profile"
      description="Update your profile"
      className="container-fluid"
    >
    <Loader/>
      <br />
      <h1 className="mt-4 color-bright">List Users</h1>
      <br />
      <br />

      <div className="row">
        <div className="col-lg-8">
          <form>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  return searchUsers(e.target.value);
                }}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="button">
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="tbl-header table-responsive">
          <table  id="listPatients" cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th scope="col">HospitalId</th>
                <th scope="col">Hospital Name</th>
                <th scope="col">First</th>
                <th scope="col">Email</th>
                <th scope="col" style={{ paddingLeft: "100px" }}>Role</th>
                <th scope="col" style={{ paddingLeft: "100px" }}>Permission</th>
                <th scope="col"  style={{ paddingLeft: "130px" }}>Edit</th>
                <th scope="col"  style={{ paddingLeft: "150px" }}>Delete</th>
              </tr>
            </thead>
         
            <tbody>
              {currentUsers.length !== 0 ? (
                currentUsers.map((user, i) => (
                  <tr key={i}>
                    <td>{user.hospital || 0}</td>
                    <td>{user.hospitalName || 'Admin'}</td>
                    <td>{user.firstName}</td>
                    <td>{user.email}</td>
                    <td style={{ paddingLeft: "100px" }}>
                      {user.role === "Super Admin" ? (
                        <button type="button" className="btn btn-primary btn-sm">
                          Super Admin
                        </button>
                      ) : user.role === "Admin" ? (
                        <button type="button" className="btn btn-secondary btn-sm">
                          Admin
                        </button>
                      ) : user.role === "Accountant" ? (
                        <button type="button" className="btn btn-info btn-sm">
                          Accountant
                        </button>
                      ) : user.role === "Patient" ? (
                        <button type="button" className="btn btn-info btn-sm">
                          Patient
                        </button>
                      ) : user.role === "Doctor" ? (
                        <button type="button" className="btn btn-dark btn-sm">
                          Doctor
                        </button>
                      ) : user.role === "Receptionist" ? (
                        <button type="button" className="btn btn-dark btn-sm">
                          Receptionist
                        </button>
                      ) : user.role === "Analyst" ? (
                        <button type="button" className="btn btn-dark btn-sm">
                          Analyst
                        </button>
                      ) : (
                        <button type="button" className="btn btn-warning btn-sm">
                          Normal
                        </button>
                      )}
                    </td>
                    <td style={{ paddingLeft: "80px" }}>
                      <input
                        type="checkbox"
                        className="toggle"
                        id={`toggle-${i}`}
                        checked={user.status === "Passed"}
                        onChange={() => handleToggleChange(user, i)}
                      />
                      <label htmlFor={`toggle-${i}`}>
                        <span className="on">Yes</span>
                        <span className="off">No</span>
                      </label>
                    </td>
                    <td style={{ paddingLeft: "140px" }}>
                      <Link to={`/update-user/${user.hospital}/${user.userId}`}>
                        <i className="bi bi-pencil-square" />
                      </Link>
                    </td>
                    <td style={{ paddingLeft: "180px" }}>
                      <i className="bi bi-trash" onClick={() => deleteHandler(user.userId, user.hospital)} />
                    </td>
                    {/* <td>
                      {user.role === "Patient" ? (
                        <Link to={`/pat-details/$${user.userId}`}>
                          <button type="button" className="btn btn-success btn-sm">
                            Details
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td> */}
                    <td></td>
                  </tr>
                ))
              ) : (
                <td>
                  <b>No Users found</b>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemPerPage}
        totalItemsCount={parseInt(countUsers())}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />

      {popupVisible && (
        <Popup
          title="Confirm"
          description="Permission to selected Role"
          onClose={handlePopupClose}
          onConfirm={handlePopupConfirm}
          onCancel={handlePopupCancel}
        />
      )}
    </Layout>
  );
};

export default ListUsers;
