import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Loader from "../frontpage/Loader";

const SocialMedia = () => {
  const [formData, setFormData] = useState({
    userId: "",
    instagram: "",
    facebook: "",
    youtube: "",
    linkedin: ""
  });

  const [hospital,setHospital] =useState("");
  const [error, setError] = useState("");

  console.log("hospital:", hospital);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('User data not found');
            }
            return response.json();
          })
          .then(userData => {
            setHospital(userData.hospital.HospitalId);
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
            setError('Error fetching user data');
          });
      } else {
        setError('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (hospital) {
      axios
        .get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/hospital/socialmedia/${hospital}`)
        .then((response) => {
          setFormData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching address data:", error);
          setError("Failed to load address data.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [hospital]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios.post(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/hospital/socialmedia/${hospital}`, formData);
      alert("Address updated successfully");
    } catch (error) {
      console.error("Error updating address:", error);
      setError("Error updating address");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <Layout>
      <Loader/>
        <h1 style={{ color: "#007bff", marginTop: "5px" }}>Social Media Link</h1>
        <form onSubmit={handleSubmit} className="socialMediaform">
          <div className="col-md-4">
            <label>Instagram:</label>
            <input
              className="form-control"
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleChange}
            />
          </div>
          <div  className="col-md-4">
            <label>Facebook:</label>
            <input
              className="form-control"
              type="text"
              name="facebook"
              value={formData.facebook}
              onChange={handleChange}
            />
          </div>
          <div  className="col-md-4">
            <label>YouTube:</label>
            <input
              className="form-control"
              type="text"
              name="youtube"
              value={formData.youtube}
              onChange={handleChange}
            />
          </div>
          <div  className="col-md-4">
            <label>LinkedIn:</label>
            <input
              className="form-control"
              type="text"
              name="linkedin"
              value={formData.linkedin}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary text-center socialmediabutton" style={{marginTop:"10px",marginLeft:"80px"}}>Update</button>
        </form>
      </Layout>
    </div>
  );
}

export default SocialMedia;
