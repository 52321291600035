import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from "axios";
import Select from 'react-select';
import { format, parse } from 'date-fns';
import { FaTrash } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import "./AdminAppointment.css";
import Loader from "../frontpage/Loader";

const AdminAppointmentForm = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [doctorOptions, setDoctorOptions] = useState([]);
    const today = new Date();
    const todayString = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}-${today.getFullYear()}`;

    const [doctorName, setDoctorName] = useState(null);
    const [fromHour, setFromHour] = useState(0);
    const [fromMinute, setFromMinute] = useState(0);
    const [toHour, setToHour] = useState(0);
    const [toMinute, setToMinute] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [data, setData] = useState([]);
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [doctorOptions1, setDoctorOptions1] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fromTime = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`;
        const toTime = `${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;
        const timeRange = `From ${fromTime} to ${toTime}`;

        const formattedDate = `${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}/${startDate.getFullYear()}`;
        const adminData = {
            doctorName: doctorName ? doctorName.value : '',
            date: formattedDate,
            timeRange: timeRange,
            hospitalId: formData1.HospitalId ? formData1.HospitalId.toString() : ''
        };

        try {
            const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/saveAppointmentTime', adminData);
            if (response.status === 200) {
                setSuccessMessage('Submitted successfully!');
                setData(prevData => [...prevData, adminData]);
                setDoctorName(null);
                setStartDate(new Date());
                setFromHour(0);
                setFromMinute(0);
                setToHour(0);
                setToMinute(0);
            }
        } catch (error) {
            console.error('Error saving appointment:', error);
        }
    };

    const[formData1, setFormData1]= useState({
        userId:"",
        HospitalName:"",
      })
  
      const[HospitalName,setHospitalName]=useState("");
  
      useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
          if (currentUser) {
            const userId = currentUser.uid;
    
            setFormData1((prevData) => ({
              ...prevData,
              userId: userId,
              HospitalName: HospitalName,
            }));
    
            try {
              const response = await axios.get(
                `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
              );
              const addressData = response.data;
              setFormData1((prevData) => ({
                ...prevData,
                HospitalName: addressData.HospitalName || " ",
                HospitalId: addressData.HospitalId || " ",
              }));
            } catch (error) {
              console.error("Error fetching user data:", error);
             
            } finally {
              
            }
          } else {
           
          }
        });
    
        return () => unsubscribe();
      }, []);

      const hospitalId = formData1.HospitalId;
  
    useEffect(() => {
        const fetchDoctorDetails = async (hospitalId) => {
            try {
                const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/fetchDoctorNames', {
                    params: {hospitalId}
                });
                const formattedDoctorOptions = response.data.map(doctor => ({
                    value: doctor.Name,
                    label: doctor.Name,
                }));
                setDoctorOptions(formattedDoctorOptions);
            } catch (error) {
                console.error('Error fetching doctor details:', error);
            }
        };
        if(hospitalId){
        fetchDoctorDetails(hospitalId);
        }
    }, [hospitalId]);

    const handleDelete = async (doctorName, date, hospitalId) => {
        try {
            await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/deleteDoctorData', { doctorName, date , hospitalId });
            setData((prevData) => prevData.filter(item => item.doctorName !== doctorName || item.date !== date));
            console.log('Data deleted from backend');
        } catch (error) {
            console.error('Error deleting data from backend:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getDoctorData?hospitalId=${hospitalId}`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data from backend:', error);
            }
        };
        if(hospitalId){
        fetchData();
        }
    }, [hospitalId]);

    const normalizeDate = (dateString) => {
        const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
        if (isNaN(parsedDate)) {
            return parse(dateString, 'dd/MM/yyyy', new Date());
        }
        return parsedDate;
    };

    return (
        <Layout title="Appointment Form">
        <Loader/>
            <>
                <h1 className="mt-4 color-bright">Appointment</h1>
                <section className="ftco-section">
                    <div className="col">
                        <form onSubmit={handleSubmit} className="admin_form">
                            <h4>
                                Book <span>Appointment</span>
                            </h4>
                            <div className="form-row">
                                {doctorOptions.length > 0 && (
                                    <div className="form-group col-lg-4">
                                        <label htmlFor="inputDoctorName">Doctor Name</label>
                                        <Select
                                            id="inputDoctorName"
                                            options={doctorOptions}
                                            value={doctorName}
                                            onChange={(selectedOption) => setDoctorName(selectedOption)}
                                            isSearchable
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='form-row'>
                                <div className="form-group col-lg-4">
                                    <label htmlFor="inputDate">Choose Date </label>
                                    <div className="input-group date">
                                        <DatePicker
                                            id="inputDate"
                                            className="form-control"
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row ">
                                <div className="form-group col-lg-2">
                                    <label htmlFor="fromTime">From</label>
                                    <div className="input-group date">
                                        <select className="form-control" id="fromHour" value={fromHour}
                                            onChange={(e) => setFromHour(e.target.value)}>
                                            {[...Array(24)].map((_, hour) => (
                                                <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text">:</span>
                                        <select className="form-control" id="fromMinute" value={fromMinute}
                                            onChange={(e) => setFromMinute(e.target.value)}>
                                            {[...Array(60)].map((_, minute) => (
                                                <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-lg-2">
                                    <label htmlFor="toTime">To</label>
                                    <div className="input-group date">
                                        <select className="form-control" id="toHour" value={toHour}
                                            onChange={(e) => setToHour(e.target.value)}>
                                            {[...Array(24)].map((_, hour) => (
                                                <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text">:</span>
                                        <select className="form-control" id="toMinute" value={toMinute}
                                            onChange={(e) => setToMinute(e.target.value)}>
                                            {[...Array(60)].map((_, minute) => (
                                                <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-box">
                                <button type="submit" className="btn " style={{ color: "white", backgroundColor: "blue", borderColor: "blue" }}>
                                    Submit
                                </button>
                            </div>
                            {successMessage && <p>{successMessage}</p>}
                        </form>
                    </div>
                </section>
                <h2 className="mb-4 text-center">Appointments</h2>
                <section className="ftco-section">
                    <div className="col table-responsive">
                        
                        <table id="listPatients3" className="table">
                            <thead>
                                <tr>
                                    <th>Doctor Name</th>
                                    <th>Date</th>
                                    <th>Time Range</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((appointment, index) => (
                                    <tr key={index}>
                                        <td>{appointment.doctorName}</td>
                                        <td>{format(normalizeDate(appointment.date), 'MM/dd/yyyy')}</td>
                                        <td>{appointment.timeRange}</td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => handleDelete(appointment.doctorName, appointment.date, hospitalId)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </>
        </Layout>
    );
};

export default AdminAppointmentForm;
