import React,{ useState } from "react";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import InvoiceItem2 from "./UPresInvoiceItem";
import InvoiceModal2 from "./UPresInvoiceModal";
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaTrash } from 'react-icons/fa';
import "./UPrescription.css";
import { useParams } from 'react-router-dom';
import withRouter from './WithRouter';

class InvoiceForm3 extends React.Component {
   

    constructor(props) {
      super(props);
  
      this.state = {
        isOpen: false,
        currency: '₹',
        currentDate: new Date().toLocaleDateString(),
        invoiceNumber: null,
        dateOfIssue: '',
        billTo: '',
        billToEmail: '',
        billToAddress: '',
        billToGender: '',
        billFrom: '',
        billFromEmail: '',
        hospital: "",
        billFromAddress: '',
        notes: '',
        total: '0.00',
        subTotal: '0.00',
        taxRate: '',  
        taxAmount: '0.00',
        discountRate: '',
        discountAmount: '0.00',
        successMessage: '',
        items: [{
          id: 0,
          name: '',
          description: '',
          price: '',
          quantity: 1,
          medicineId: null,
          quantities: {
            morningBefore: 0,
            morningAfter: 0,
            noonBefore: 0,
            noonAfter: 0,
            eveningBefore: 0,
            eveningAfter: 0,
            nightBefore: 0,
            nightAfter: 0
          }
    
          
        }],
       
        patientOptions: [],
        hospitalOptions: [],
        medicineOptions: [],
        selectedMedicineNames: [],
        showInvoiceCapture: false,
        additionalNotes: [],
        isPrintEnabled: false,
      };
  
      this.editField = this.editField.bind(this);
      this.handleAddEvent = this.handleAddEvent.bind(this);
      this.handleRowDel = this.handleRowDel.bind(this);
      this.onItemizedItemEdit = this.onItemizedItemEdit.bind(this);
      this.handleCalculateTotal = this.handleCalculateTotal.bind(this);
      this.onCurrencyChange = this.onCurrencyChange.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.fetchPatients = this.fetchPatients.bind(this);
      this.handlePatientSelect = this.handlePatientSelect.bind(this);
      this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
      this.fetchMedicines = this.fetchMedicines.bind(this);
      this.printInvoice = this.printInvoice.bind(this);
      this.onRowAddNotes = this.onRowAddNotes.bind(this);
      this.handleDeleteNote = this.handleDeleteNote.bind(this);
      this.handleSubmit=this.handleSubmit.bind(this);
    }
  
    componentDidMount() {
      this.handleCalculateTotal();
      this.fetchPatients();
      this.fetchDoctor();
      this.fetchMedicines();
      // this.fetchLatestInvoiceNumber();
      this.fetchInvoiceData();
      this.fetchUserHospital();
    }
  
    
  
  fetchUserHospital = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const userId = user.uid;
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserData/${userId}`);
        const hospitalId = response.data.hospital.HospitalId;
        this.setState({ hospital: hospitalId }, () => {
          this.fetchInvoiceData();
          this.fetchMedicines();
      
        });
      }
    } catch (error) {
      console.error("Error fetching user hospital:", error);
      this.setState({ error: "Failed to fetch user hospital" });
    }
  };

    async fetchPatients() {
      try {
        const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/createbill/get-patients');
        const patientOptions = response.data.map(patient => ({
          value: patient.patientId,
          label: `${patient.patientId} - ${patient.Name}`,
          email: patient.Email,
          address: patient.Address,
          phone: patient.PhoneNo,
          gender: patient.Gender,
          data: patient
        }));
        this.setState({ patientOptions });
      } catch (error) {
        console.error("There was an error fetching the patients!", error);
      }
    }


    fetchInvoiceData() {
      const hospitalId= this.state.hospital;
      const { invoiceNumber } = this.props.params;  
        console.log("invoicenumber:", invoiceNumber);
        fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/get-prescriptiondata?invoiceNumber=${invoiceNumber}&hospitalId=${hospitalId}`) // Update this URL to fetch data
          .then(response => response.json())
          .then(data => {
            const invoice = Array.isArray(data) ? data.find(item => item !== null) : data;
            console.log("invoice:", invoice);
            if (invoice) {
              const updatedItems = invoice.items.map(item => ({
                ...item,
                quantities: item.quantities || {
                  morningBefore: 0,
                  morningAfter: 0,
                  noonBefore: 0,
                  noonAfter: 0,
                  eveningBefore: 0,
                  eveningAfter: 0,
                  nightBefore: 0,
                  nightAfter: 0
                }
               
              }));

              this.setState({
            billFrom: invoice.billFrom,
            billFromAddress: invoice.billFromAddress,
            billFromEmail: invoice.billFromEmail,
            billFromPhone: invoice.billFromPhone,
            billFromHospitalName: invoice.billFromHospitalName,
            billTo: invoice.billTo,
            billToAddress: invoice.billToAddress,
            billToEmail: invoice.billToEmail,
            billToGender: invoice.billToGender,
            billToPhone: invoice.billToPhone,
            dateOfIssue: invoice.dateOfIssue,
            items: updatedItems,
            notes: invoice.notes,
            patientId: invoice.patientId,
            patientName: invoice.patientName,
            url: invoice.url,
            invoiceNumber: invoice.invoice,
            color1: invoice.color1 || " ",
            color2: invoice.color2 || " ",
            color3: invoice.color3 || " ",
            color4: invoice.color4 || " ",
            socialMedia: invoice.socialMedia || {},
            address: invoice.address || {},
          });
            }
          })
          .catch(error => {
            console.error('Error fetching invoice data:', error);
          });
    }
  
    async fetchDoctor() {
      try {
        const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/prescription/get-doctor');
        console.log("response:", response);
        const doctorOptions = response.data.map(doctor => ({
          value: doctor.doctorId,
          label: `${doctor.DoctorName}`,
          email: doctor.Email,
          phone: doctor.PhoneNo,
          data: doctor
          
        }));
        this.setState({ doctorOptions });
      } catch (error) {
        console.error("There was an error fetching the hospitals!", error);
      }
    }
  
   
    async fetchMedicines() {
      try {
        const hospitalId = this.state.hospital;
        console.log("Fetching medicines for hospitalId:", hospitalId);
        if (!hospitalId) {
          throw new Error("hospitalId is missing");
        }
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/createbill/get-medicines?hospitalId=${hospitalId}`);
        const medicineOptions = response.data.map(medicine => ({
          value: medicine.medicineId,
          label: medicine.MedicineName,
          price: medicine.Price,
          data: medicine
        }));
        this.setState({ medicineOptions });
      } catch (error) {
        console.error("There was an error fetching the medicines!", error);
        // Optionally set state to display an error message to the user
        this.setState({ error: "Failed to load medicines" });
      }
    }
    
    handlePatientSelect(selectedOption) {
      const { value , label, email, address, data, phone, gender} = selectedOption;
  
      const billingAmount = parseFloat(data?.BillingAmount || 0);
      this.setState({
        billTo: label,
        billToEmail: email,
        billToAddress: address,
        billToPhone: phone,
        billToData: data,
        billToGender: gender,
        // subTotal: billingAmount.toFixed(2), // Update subtotal with billing amount
        patientId: value,
        patientName: data.Name,
      }, this.handleCalculateTotal); // Recalculate total
    }
  
    handleHospitalSelect(selectedOption) {
      const { label, email, address, hospitalName, phone, Address} = selectedOption;
  
      this.setState({
        billFrom: label,
        billFromEmail: email,
        billFromAddress: address,
        billFromPhone: phone,
        billFromHospitalName: hospitalName,
        billFromAddress: Address
      });
    }
  
    handleRowDel(items) {
      const updatedItems = this.state.items.filter(item => item.id !== items.id);
      this.setState({ items: updatedItems }, this.handleCalculateTotal);
    }
  
    handleAddEvent() {
      
      const id = (this.state.items.length === 0) ? 0 : this.state.items[this.state.items.length - 1].id + 1;
      const item = {
        id: id,
        name: '',
        description: '',
        price: '',
        quantity: 1,
        medicineId: null,
         quantities: {
          morningBefore: 0,
          morningAfter: 0,
          noonBefore: 0,
          noonAfter: 0,
          eveningBefore: 0,
          eveningAfter: 0,
          nightBefore: 0,
          nightAfter: 0
        }
      };
      this.setState((prevState) => ({
        items: [...prevState.items, item]
      }));
    }

    onRowAddNotes(){
      this.setState(prevState => ({
        additionalNotes: [...prevState.additionalNotes, '']
      }));
    }

    handleAdditionalNoteChange(index, event) {
      const newNotes = [...this.state.additionalNotes];
      newNotes[index] = event.target.value;
      this.setState({ additionalNotes: newNotes });
    }

    handleDeleteNote(index) {
      this.setState(prevState => {
        const newNotes = prevState.additionalNotes.filter((note, i) => i !== index);
        return { additionalNotes: newNotes };
      });
    }
  
  
    handleCalculateTotal() {
     
      const items = this.state.items;
  
      let subTotal = 0;
      items.forEach(item => {
        subTotal += parseFloat(item.price) * parseFloat(item.quantity);
      });
  
      const discountAmount = (this.state.discountRate / 100) * subTotal;
      const taxAmount = (this.state.taxRate / 100) * (subTotal - discountAmount);
      const total = subTotal - discountAmount + taxAmount;
  
      this.setState({
        subTotal: subTotal.toFixed(2),
        discountAmount: discountAmount.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        total: total.toFixed(2)
      });
  }
  
  onItemizedItemEdit(event) {
    if (!event || !event.target) {
      console.error('Invalid event object:', event);
      return;
    }
    
    const { id, name, value } = event.target;
    
    this.setState(prevState => {
      const updatedItems = prevState.items.map(item => {
        if (item.id === parseInt(id)) {
          return { ...item, [name]: value };
        }
        return item;
      });
      console.log('Editing item:', updatedItems);
      return { items: updatedItems };
    });
  }
  
    editField(event) {
      this.setState({ [event.target.name]: event.target.value }, this.handleCalculateTotal);
    }
  
  
    onCurrencyChange(event) {
      this.setState({ currency: event.target.value });
    }
  
    openModal(event) {
      event.preventDefault();
      this.handleCalculateTotal();
      this.setState({ isOpen: true });
    }
  
    closeModal() {
      this.setState({ isOpen: false });
    }
  
  
    printInvoice = async (event) => {
      event.preventDefault();
      const input = document.getElementById('invoiceCaptureprint');
    
      try {
        // Generate PDF
        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [612, 792]
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        
        // Convert the PDF data to a blob
        const blob = pdf.output('blob');
        const fileName = `${this.state.patientId}_${this.state.billFrom}.pdf`;
        pdf.save(fileName);
        const storageRef = firebase.storage().ref();
        const pdfRef = storageRef.child(`invoices/${fileName}`);
    
        // Upload the PDF to Firebase Storage and get the public URL
        await pdfRef.put(blob);
        const downloadURL = await pdfRef.getDownloadURL();
    
        // Prepare the form data with only the PDF URL
        const urlData = {
          invoiceNumber: this.state.invoiceNumber,
          url: downloadURL
        };
    
        // Send only the PDF URL to the backend API
        const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/update-pdf-url', urlData);
        console.log(response.data);
    
        this.setState({ successMessage: 'PDF URL updated successfully!' });
    
      } catch (error) {
        console.error('Error:', error);
        this.setState({ successMessage: 'Failed to update PDF URL.' });
      }
    }
    
    
    handleSubmit = async (event) => {
      console.log('Hospital ID:', this.state.hospital);
      event.preventDefault();
      const formData = {
        hospital: this.state.hospital,
        patientId: this.state.patientId,
        patientName: this.state.patientName,
        billTo: this.state.billTo,
        billToEmail: this.state.billToEmail,
        billToAddress: this.state.billToAddress,
        billToGender: this.state.billToGender,
        billToPhone: this.state.billToPhone,
        billFrom: this.state.billFrom,
        billFromEmail: this.state.billFromEmail,
        billFromAddress: this.state.billFromAddress,
        billFromPhone: this.state.billFromPhone,
        billFromHospitalName: this.state.billFromHospitalName,
        notes: this.state.notes,
        additionalNotes: JSON.stringify(this.state.additionalNotes),
        items: JSON.stringify(this.state.items),
        invoiceNumber: this.state.invoiceNumber,
        dateOfIssue: this.state.dateOfIssue,
        color1: this.state.color1,
        color2: this.state.color2,
        color3: this.state.color3,
        color4: this.state.color4,
        address: this.state.address,
        socialMedia: this.state.socialMedia,
      };
    
      try {
        const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/submit-prescription', formData);
        console.log(response.data);
        this.setState({ successMessage: 'Prescription saved successfully!',isPrintEnabled: true, });
      } catch (error) {
        console.error('Error:', error);
        this.setState({ successMessage: 'Not saved value' });
      }
    }
    
  
    render() {
    
      const { items, successMessage} = this.state;
      
      return (
          <Layout title="List Prescriptions" className="container-fluid">
           <h1 className="mt-4 color-bright">Update Prescription</h1>
      <Form >
        <Row>
          <Col md={8} lg={9}>
          <div id="invoiceCapture12">
            <Card className="p-4 p-xl-5 my-3 my-xl-4">
              <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                <div class="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div class="mb-2">
                      <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                      <span className="current-date">{new Date().toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                    <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                        maxWidth: '150px'
                      }} required="required"/>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Prescription&nbsp;Number:&nbsp;</span>
                  <Form.Control type="number" value={this.state.invoiceNumber} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                      maxWidth: '70px'
                    }} disabled/>
                </div>
              </div>
              <hr className="my-4"/>
              <Row className="mb-5">
                <Col>
                  <Form.Label className="fw-bold">Patient:</Form.Label>
                
                  <Form.Control placeholder={"Who is this invoice to?"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                  <Form.Control placeholder={"Billing Phone number"} value={this.state.billToPhone} type="text" name="billToPhone" className="my-2" autoComplete="phone" onChange={(event) => this.editField(event)} required="required"/>
                  <Form.Control placeholder={"Email address"} value={this.state.billToEmail} type="email" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                  <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
                  <Form.Control placeholder={"Gender"} value={this.state.billToGender} type="text" name="billToGender" className="my-2" autoComplete="gender" onChange={(event) => this.editField(event)} required="required"/>
                </Col>
                <Col>
                  <Form.Label className="fw-bold">Doctor:</Form.Label>
                  <Select
                        options={this.state.doctorOptions}
                        onChange={this.handleHospitalSelect}
                        placeholder="Search for a Doctor..."
                        className="my-2"
                      />
                  <Form.Control placeholder={"Who is this invoice from?"} rows={3} value={this.state.billFrom} type="text" name="billFrom" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                  <Form.Control placeholder={"Phone Number"} value={this.state.billFromPhone} type="text" name="billFromPhone" className="my-2" onChange={(event) => this.editField(event)} autoComplete="phone" required="required"/>
                  <Form.Control placeholder={"Email address"} value={this.state.billFromEmail} type="email" name="billFromEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                  {/* <Form.Control placeholder={"Billing address"} value={this.state.billFromAddress} type="text" name="billFromAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/> */}
                </Col>
                
              </Row>
             
              <InvoiceItem2 onItemizedItemEdit={this.onItemizedItemEdit} onRowAdd={this.handleAddEvent} onRowDel={this.handleRowDel} currency={this.state.currency} items={this.state.items} medicineOptions={this.state.medicineOptions}  handleCalculateTotal={this.handleCalculateTotal} />
                        
              <hr className="my-4"/>
              <Form.Label className="fw-bold">Notes:</Form.Label>
              <Form.Control placeholder="Happy Medicial" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} as="textarea" className="my-2" rows={1}/>
              {this.state.additionalNotes.map((note, index) => (
                    <Form.Group key={index} className="d-flex align-items-center my-2">
                      <Form.Control
                        as="textarea"
                        rows="4"
                        value={note}
                        className="flex-grow-1"
                        style={{ marginRight: '10px' }}
                        onChange={e => this.handleAdditionalNoteChange(index, e)}
                      />
                         <FaTrash
                        onClick={() => this.handleDeleteNote(index)}
                        style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                      />
                    </Form.Group>
                  ))}
              <Button variant="primary" type="add" className="fw-bold" onClick={this.onRowAddNotes} style={{width:"125px",height:"50px"}}>Add Notes</Button>
            </Card>
            </div>
          </Col>
          
          <Col md={4} lg={3}>
            <div className="sticky-top pt-md-3 pt-xl-4">
              <Button variant="primary" type="submit" onClick={this.openModal} className="d-block w-100" disabled={!this.state.isPrintEnabled}>Review Prescription</Button>
              
             <InvoiceModal2 showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmmount={this.state.taxAmmount} discountAmmount={this.state.discountAmmount} total={this.state.total} patientId={this.state.patientId} patientName={this.state.patientName}
               color1={this.state.color1}
              color2={this.state.color2} 
              color3={this.state.color3} 
              color4={this.state.color4}
              address={this.state.address}
              socialMedia={this.state.socialMedia}
              hospital={this.state.hospital}
              invoice={this.state.invoice}
             />
             <Button variant="success" type="button" onClick={this.handleSubmit} className="d-block w-100 mt-3 fw-bold">
                    Save
                  </Button><br></br>
                  {successMessage && <div className="mt-3 alert alert-success">{successMessage}</div>} {/* Success message */}
             {/* <Button variant="secondary" type="print" className="d-block w-100 mb-4" onClick={this.printInvoice}>Update</Button> */}
             
            </div>
          </Col>
        </Row>
      </Form>
    
      <div id="invoiceCaptureprint" className="p-4">
      <div className="d-flex flex-row justify-content-between align-items-start w-100 p-4"  style={{backgroundColor: this.state.color1}}>
      <div>
              <h6>Address</h6>
              {this.state.address?.streetName1 && <div>{this.state.address.streetName1},{this.state.address.streetName2}</div>}
              {this.state.address?.area && <div>{this.state.address.area}</div>}
              {this.state.address?.city && <div>{this.state.address.city}</div>}
            </div>
        <div className="w-100" style={{marginLeft:"10px",textAlign:"center"}}>
          <h4 className="fw-bold my-2">{this.state.billFrom || 'John Uberbacher'}</h4>
          <h6 className="fw-bold text-secondary mb-1">
            Prescription #: {this.state.invoiceNumber || ''}
          </h6>
          <h2 className="fw-bold my-2 text-center">{this.state.billFromHospitalName || ''}</h2>
        </div>
      </div>
      <div className="p-4">
        <Row className="mb-4">
          <Col md={4}>
            <div className="fw-bold">Patient:</div>
            <div>Name:{this.state.billTo || ''}</div>
            <div>Phone:{this.state.billToPhone || ''}</div>
            <div>Email:{this.state.billToEmail || ''}</div>
            <div>Gender:{this.state.billToGender || ''}</div>
            <div>Address:{this.state.billToAddress || ''}</div>
          </Col>
          <Col md={4}>
            <div className="fw-bold">Doctor:</div>
            <div>Name:{this.state.billFrom || ''}</div>
            <div>Address:{this.state.billFromAddress || ''}</div>
            <div>Email:{this.state.billFromEmail || ''}</div>
          </Col>
          <Col md={4}>
            <div className="fw-bold mt-2">Date Of Issue:</div>
            <div>{this.state.dateOfIssue || ''}</div>
          </Col>
        </Row>
        <Table className="mb-0">
          <thead>
            <tr>
              <th className="text-center">S.No</th>
              <th className="text-center">Medicine Name</th>
              <th className="text-center">Session</th>
              <th className="text-center">Days</th>
            </tr>
          </thead>
          <tbody>
          {this.state.items.length > 0 ? (
              this.state.items.map((item, i) => (
                <tr id={i} key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{item.name}</td>
                  <td className="session-quantity-cell text-center">
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th></th>
                            {['Mor', 'Noon', 'Eve', 'Nig'].map(time => (
                              <th key={time}>{time}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {['Before', 'After'].map(period => (
                            <tr key={period}>
                              <td>{period}</td>
                              {['morning', 'noon', 'evening', 'night'].map(time => (
                                <td key={`${time}${period}`}>
                                  {item.quantities[`${time}${period}`] || 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                    <td style={{textAlign:"center"}}>
                      {item.days || 0}
                    </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No items available</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="mt-4">
              <div className="fw-bold">Notes:</div>
              <div>{this.state.notes}</div>
              {this.state.additionalNotes.map((note, index) => (
                <div key={index}>{note}</div>
              ))}
            </div>
            <div className="mt-4 text-center footerbill" style={{backgroundColor: this.state.color2 || "lightgrey"}}>
              {/* <p className="fw-bold">Thank you!</p>
              <p>Contact us: {this.state.billFromEmail || 'email@example.com'}</p>
              <p>{this.state.billFromAddress || '1234 Street, City, Country'}</p> */}
              <div className="social-icons mt-3 d-flex justify-content-center">
                {this.state.socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.facebook}</span>
                  </div>
                )}
                {this.state.socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.instagram}</span>
                  </div>
                )}
                {this.state.socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.linkedin}</span>
                  </div>
                )}
                {this.state.socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.youtube}</span>
                  </div>
                )}
              </div>



            </div>
      </div>
    </div>
          
      </Layout>)
    }
  }

export default withRouter(InvoiceForm3);
  