import React from "react";
import Header from "./Header";
import Features from "./Features";
import Footer from "./Footer";
import Loader from "./Loader";

const FeaturesPage=()=>{
    return(
        <>
        <Loader/>
        <Header/><br></br><br></br>
        
        <Features/>
        <Footer/>
    </>
    );
}

export default FeaturesPage;