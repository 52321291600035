import React,{useEffect} from "react";
import $ from "jquery";
import "./Header.css";

const Header=()=>{

  useEffect(() => {
    // jQuery code for mobile menu and window resize handling
    var width = $(window).width();
    $(window).resize(function () {
      if (width > 767 && $(window).width() < 767) {
        window.location.reload();
      } else if (width < 767 && $(window).width() > 767) {
        window.location.reload();
      }
    });

    // Menu Dropdown Toggle
    if ($(".navbar-toggler").length) {
      $(".navbar-toggler").on("click", function () {
        $(this).toggleClass("active");
        $(".navbar-collapse").slideToggle(200);
      });
    }

    const dropdownOpener = $(".main-nav ul.nav .has-sub > a");

    // Open/Close Submenus
    if (dropdownOpener.length) {
      dropdownOpener.each(function () {
        var _this = $(this);

        _this.on("tap click", function (e) {
          var thisItemParent = _this.parent("li"),
            thisItemParentSiblingsWithDrop =
              thisItemParent.siblings(".has-sub");

          if (thisItemParent.hasClass("has-sub")) {
            var submenu = thisItemParent.find("> ul.sub-menu");

            if (submenu.is(":visible")) {
              submenu.slideUp(450, "easeInOutQuad");
              thisItemParent.removeClass("is-open-sub");
            } else {
              thisItemParent.addClass("is-open-sub");

              if (thisItemParentSiblingsWithDrop.length === 0) {
                thisItemParent
                  .find(".sub-menu")
                  .slideUp(400, "easeInOutQuad", function () {
                    submenu.slideDown(250, "easeInOutQuad");
                  });
              } else {
                thisItemParent
                  .siblings()
                  .removeClass("is-open-sub")
                  .find(".sub-menu")
                  .slideUp(250, "easeInOutQuad", function () {
                    submenu.slideDown(250, "easeInOutQuad");
                  });
              }
            }
          }

          e.preventDefault();
        });
      });
    }
  }, []);
    return(
        <nav className="navbar navbar-expand-lg  navbar-home navbar-light px-4 px-lg-5 py-3 py-lg-0 position-fixed">
        <a href="" className="navbar-brand p-0">
          <h1 className="m-0 text-black">PROHMS</h1>
          {/* <img src="img/logo.png" alt="Logo"> */}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto py-0">
            <a href="/" className="nav-item nav-link" activeClassName="current">
              Home
            </a>
            <a href="/features" className="nav-item nav-link" activeClassName="current">
              Features
            </a>
            {/* <a href="/whyHMS" className="nav-item nav-link">
              Why HMS
            </a> */}
            {/* <a href="#pricing" className="nav-item nav-link">
              Pricing
            </a>
            <a href="#review" className="nav-item nav-link">
              Review
            </a>
            <a href="#contact" className="nav-item nav-link">
              Contact
            </a> */}
          </div>
          <a
            href="/signin"
            target="_blank"
            className="btn btn-primary-gradient rounded-pill  d-lg-block"
          >
            Sign In
          </a>
        </div>
      </nav>
    );
}

export default Header;