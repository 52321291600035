import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../frontpage/Loader";

const roleMapping = {
  "Super Admin": 1,
  "Admin": 2,
  "Doctor": 3,
  "Accountant": 4,
  "Receptionist": 5,
  "Analyst": 6,
  "Patient": 7,
};

const UpdateUsers = () => {
  const { hospitalId, userId } = useParams();
  console.log("userId:", userId);
  console.log("hospitalId:", hospitalId);
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    hospitalId: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user details based on userId
    const fetchUser = async () => {
      try {
        const response = await fetch(
          `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getUserById/${hospitalId}/${userId}`
        );
        const userData = await response.json();
        setUser(userData);
        setFormData({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          role: userData.role,
          hospitalId: hospitalId,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const roleId = roleMapping[formData.role] || "";
    try {
      const response = await fetch(
        `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updateUser`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            roleId,
            ...formData,
          }),
        }
      );

      if (response.ok) {
        navigate("/list/users");
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  

  return (
    <Layout>
    <Loader/>
      <div>
        <h1>Edit User</h1>
        <form onSubmit={handleSubmit} style={{marginLeft:"40%",marginTop:"100px"}}>
          <div>
            <label>
              First Name:
              <input
              className="form-control"
                type="text"
                name="firstName"
                value={formData.firstName}
                readOnly
              />
            </label>
          </div>
          <div>
            <label>
              Last Name:
              <input
              className="form-control"
                type="text"
                name="lastName"
                value={formData.lastName}
                readOnly
              />
            </label>
          </div>
          <div >
            <label>
              Email:
              <input
               className="form-control"
                type="email"
                name="email"
                value={formData.email}
                readOnly
              />
            </label>
          </div>
          <div className="form-group1">
            <label htmlFor="role">Role:</label>
            <select
              className="form-control"
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              style={{width:"200px"}}
            >
              <option value="">Select Role</option>
              <option value="Super Admin" disabled>Super Admin</option>
              <option value="Patient">Patient</option>
              <option value="Admin">Admin</option>
              <option value="Accountant">Accountant</option>
              <option value="Doctor">Doctor</option>
              <option value="Receptionist">Receptionist</option>
              <option value="Analyst">Analyst</option>
            </select>
          </div>
          <br />
          <button className="btn btn-primary" type="submit">Update</button>
        </form>
      </div>
    </Layout>
  );
}

export default UpdateUsers;
