import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import firebase from 'firebase/compat/app';

async function GenerateInvoice(patientId, invoiceNumber, hospital, invoice) {

  try {
    const canvas = await html2canvas(document.querySelector("#invoiceCapture"));
    const imgData = canvas.toDataURL('image/png', 1.0);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [612, 792]
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Convert the PDF to a Blob
    const blob = pdf.output('blob');
    const fileName = `${patientId}_${invoiceNumber}.pdf`;

    // Upload PDF to Firebase Storage
    const storageRef = firebase.storage().ref();
    const pdfRef = storageRef.child(`invoices/${fileName}`);
    await pdfRef.put(blob);
    
    // Get the public URL of the uploaded PDF
    const downloadURL = await pdfRef.getDownloadURL();

    const urlData = {
      url: downloadURL,
      hospital: hospital,
      invoice: invoice
    };

    const response = await axios.post('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/splitupdate-pdf-url1', urlData);
    console.log("Server response:", response.data);


  } catch (error) {
    if (error.response) {
      console.error('Server response:', error.response.data);
    } else {
      console.error('Error:', error.message);
    }
  }
}

class SplitInvoiceModal3 extends React.Component {
  render() {
    const { showModal, closeModal, info, items, currency, total, subTotal, taxAmount, discountAmount, selectedMedicineNames = [], color1, color2, color3, color4, socialMedia, address={}, hospital, invoice, notes, rows, totalPaid,  selectedCategory} = this.props;

    return (
      <div>
        <Modal show={showModal} onHide={closeModal} size="lg" centered>
          <div id="invoiceCapture">
            <div className="d-flex flex-row justify-content-between align-items-start  w-100 p-4" style={{ backgroundColor: color1 }}>
              <div>
                <h6>Address</h6>
                {address.streetName1 && <div>{address.streetName1},{address.streetName2}</div>}
                {address.area && <div>{address.area}</div>}
                {address.city && <div>{address.city}</div>}
              </div>
              <div className="w-100" style={{ marginLeft: "10px", textAlign: "center" }}>
                <h4 className="fw-bold my-2">{info.billFrom || 'John Uberbacher'}</h4>
                <h6 className="fw-bold text-secondary mb-1">
                  Invoice #: {info.invoiceNumber || ''}
                </h6>
              </div>
              <div className="text-end ms-4">
                <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
                <h5 className="fw-bold text-secondary">{currency} {total}</h5>
              </div>
            </div>
            <div className="p-4">
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">Billed to:</div>
                  <div>{info.billTo || ''}</div>
                  <div>{info.billToAddress || ''}</div>
                  <div>{info.billToEmail || ''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Billed From:</div>
                  <div>{info.billFrom || ''}</div>
                  <div>{info.billFromAddress || ''}</div>
                  <div>{info.billFromEmail || ''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mt-2">Date Of Issue:</div>
                  <div>{info.dateOfIssue || ''}</div>
                </Col>
              </Row>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className='text-center'>Categories</th>
                    <th className='text-center'>Item</th>
                    <th className='text-center'>QTY</th>
                    <th className="text-center">PRICE</th>
                    <th className="text-center">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => (
                    <tr key={i}>
                      <td className="text-center">{info.selectedCategory || item.selectedCategory}</td>
                      <td className="text-center">{selectedMedicineNames[i] || item.name}</td>
                      <td className="text-center" style={{ width: '70px' }}>{item.quantity}</td>
                      <td className="text-center" style={{ width: '100px' }}>{currency} {item.price}</td>
                      <td className="text-center" style={{ width: '100px' }}>{currency} {(item.price * item.quantity).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {subTotal}</td>
                  </tr>
                  {taxAmount!== '0.00' &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>TAX</td>
                      <td className="text-end" style={{ width: '100px' }}>{currency} {taxAmount}</td>
                    </tr>
                  }
                  {discountAmount!== '0.00' &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>DISCOUNT</td>
                      <td className="text-end" style={{ width: '100px' }}>{currency} {discountAmount}</td>
                    </tr>
                  }
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TOTAL</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {total}</td>
                  </tr>
                </tbody>
              </Table>
              <Table>
              <thead>
                <tr>
                  <th style={{textAlign:"center"}}>S.NO</th>
                  <th style={{textAlign:"center"}}>Payment Type</th>
                  <th style={{textAlign:"center"}}>Payment Id</th>
                  <th style={{textAlign:"center"}}>Date</th>
                  <th style={{textAlign:"center"}}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr className="text-end" key={index}>
                    <td style={{textAlign:"center"}}>{index + 1}</td>
                    <td className="fw-bold" style={{ width: '100px', textAlign:"center" }}>
                      {`${row.paymentMode}`}
                    </td>
                    <td>
                      <div style={{ fontWeight: "normal", textAlign: "center" }}>
                        {`${row.paymentId || 'N/A'}`}
                      </div>
                      </td>
                    <td style={{ textAlign: "center" }}>
                      {`${row.date}`}
                    </td>
                    <td className="text-center" style={{ width: '100px' }}>
                      {currency} {(parseFloat(row.amount) || 0).toFixed(2)}
                    </td>
                  </tr>
                ))}
               
              </tbody>
            </Table>
            <Table>
              <tbody>
              <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>TOTAL PAID</td>
                  <td className="text-end" style={{ width: '100px' }}>{currency} {totalPaid.toFixed(2)}</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>AMOUNT DUE</td>
                  <td className="text-end" style={{ width: '100px' }}>{currency}{(parseFloat(total) - parseFloat(totalPaid) || 0).toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
              {info.notes &&
                <div className="bg-light py-3 px-4 rounded">
                  {info.notes}
                </div>}
            </div>
            <div className="mt-4 text-center footerbill" style={{backgroundColor: color2 || "lightgrey"}}>
              {/* <p className="fw-bold">Thank you!</p>
              <p>Contact us: {this.state.billFromEmail || 'email@example.com'}</p>
              <p>{this.state.billFromAddress || '1234 Street, City, Country'}</p> */}
              <div className="social-icons mt-3 d-flex justify-content-center">
                {socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{socialMedia.facebook}</span>
                  </div>
                )}
                {socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{socialMedia.instagram}</span>
                  </div>
                )}
                {socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{socialMedia.linkedin}</span>
                  </div>
                )}
                {socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{socialMedia.youtube}</span>
                  </div>
                )}
              </div>



            </div>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6}>
                {/* <Button variant="primary" className="d-block w-100" onClick={() => GenerateInvoice(info.patientId, info.patientName, total)}>
                  <BiPaperPlane style={{ width: '15px', height: '15px', marginTop: '-3px' }} className="me-2" />Send Invoice
                </Button> */}
              </Col>
              <Col md={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={() => GenerateInvoice(info.patientId, info.patientName, hospital, invoice)}>
                  <BiCloudDownload style={{ width: '16px', height: '16px', marginTop: '-3px' }} className="me-2" />
                  Download Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

export default SplitInvoiceModal3;
