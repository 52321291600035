import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';


const AddClinicExpense = () => {

  const [PatientName, setPatientName] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [Paid, setPaid] = useState('');
  const [Balance, setBalance] = useState('');
//   const [patientId, setPatientId] = useState('');
const [selectedCategory, setSelectedCategory] = useState(''); 
const [clinicExpenseCategories, setClinicExpenseCategories] = useState([]);
  

  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));

          const clinicExpense = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getClinicCategory/${addressData.HospitalId}`
          );
          setClinicExpenseCategories(clinicExpense.data.ClinicExpenseCategory || []);
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const submitHandler = async(e) => {
    e.preventDefault();

    const expenseData = {
      PatientName,
      totalAmount: totalAmount,
      date: formatDate(date),
      description,
      Paid,
      Balance,
      category: selectedCategory,
      HospitalId: formData1.HospitalId
    };

  //   axios.post('http://localhost:3001/add-expense', expenseData)
  //     .then(response => {
  //       alert(response.data.message);
  //       // Clear form fields
  //       setPatientName('');
  //       setTotalAmount('');
  //       setDate(new Date());
  //       setDescription('');
  //       setPaid('');
  //       setBalance('');
  //       setPatientId('');
  //     })
  //     .catch(error => {
  //       console.error('Error adding expense: ', error);
  //     });
  // };
  try {
    const response = await fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/add-clinicexpense', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(expenseData),
  });
  const result = await response.json();
  if (response.ok) {
    setSuccessMessage(result.message);
    setErrorMessage('');
   
  } else {
    throw new Error(result.error);
  }
} catch (error) {
  console.error('Error submitting form: ', error);
  setSuccessMessage('');
  setErrorMessage('Error submitting form.');
}
};


  const addExpenseForm = () => (
    <div className="form-group col-md-12">
  <form onSubmit={submitHandler}>
    {/* First Column */}
    <div className="form-row" style={{paddingLeft:"30%"}}>
      <div className="form-group col-md-3">
        <label className="font-weight-bold" htmlFor="inputAddress2">
          Name
        </label>
        <input
          type="text"
          className="form-control input-shadow input-p"
          id="inputAddress2"
          placeholder="Name"
          value={PatientName}
          onChange={(e) => setPatientName(e.target.value)}
        />
      </div>
      <div className="form-group col-md-3">
        <label className="font-weight-bold" htmlFor="inputAddress">
          Amount
        </label>
        <input
          type="text"
          className="form-control input-shadow input-p"
          placeholder="Amount"
          value={totalAmount}
          onChange={(e) => setTotalAmount(e.target.value)}
        />
      </div>
      
    </div>
    {/* Second Column */}
    <div className="form-row" style={{paddingLeft:"30%"}}>
      <div className="form-group col-md-3" >
        <label className="font-weight-bold" htmlFor="inputAddress">
          Date
        </label>
        <div className="input-container">
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            className="form-control input-shadow1 input-p"
          />
          <div className="icon-container">
            <i className="fa fa-calendar" style={{ color: "blue" }}></i>
          </div>
        </div>
      </div>
      <div className="form-group col-md-3">
        <label className="font-weight-bold" htmlFor="balance">
          Balance
        </label>
        <input
          type="text"
          className="form-control  input-shadow input-p"
          id="balance"
          placeholder="Balance"
          value={Balance}
          onChange={(e) => setBalance(e.target.value)}
        />
      </div>
      
    </div>
    <div className="form-row" style={{paddingLeft:"30%"}}>
      
      <div className="form-group col-md-3" >
        <label className="font-weight-bold" htmlFor="exampleFormControlSelect1">
          Total Paid
        </label>
        <input
          type="text"
          className="form-control  input-shadow input-p"
          placeholder="Amount"
          value={Paid}
          onChange={(e) => setPaid(e.target.value)}
        />
      </div>
      
          <div className="form-group col-md-3">
            <label className="font-weight-bold" htmlFor="categorySelect">Category</label>
            <select
              id="categorySelect"
              className="form-control input-shadow input-p"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {clinicExpenseCategories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>
        
      {/* <div className="form-group col-md-3">
        <label className="font-weight-bold" htmlFor="patientId">
          Patient ID
        </label>
        <input
          type="text"
          className="form-control  input-shadow input-p"
          id="patientId"
          placeholder="Patient ID"
          value={patientId}
          onChange={(e) => setPatientId(e.target.value)}
        />
      </div> */}
      </div>
    
    {/* Description */}
    <div className="form-row justify-content-center" style={{marginRight:"40px"}}>
      <div className="form-group col-md-4 text-center">
        <label className="font-weight-bold" htmlFor="exampleFormControlTextarea1">
          Description
        </label>
        <textarea
          className="form-control input-bg-light-grey input-shadow3 input-p"
          id="exampleFormControlTextarea1"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Write a description"
          rows="3"
        />
      </div>
    </div><br></br>
    {/* Button */}
    <div className="form-row justify-content-center">
      <div className="col-md-2 text-center">
        <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>
          Save
        </button>
      </div>
    </div>
  </form>
  {successMessage && (
      <div className="alert alert-success mt-3" role="alert">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="alert alert-danger mt-3" role="alert">
        {errorMessage}
      </div>
    )}
</div>

  );

  return (
    <Layout title="Category Treatment Form">
      <>
      <h1 className="mt-4 color-bright">Add Clinic Expense Details</h1><br></br>
        {addExpenseForm()}
      </>
    </Layout>
  );
};

export default AddClinicExpense;
