
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { format } from 'date-fns';
import "./AddPatient.css";
import Papa from 'papaparse';
import saveAs from 'file-saver';
import Loader from "../frontpage/Loader";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFVeBVvSqo6y4gV05YZjWORabL_2BfnyE",
  authDomain: "hospital-management-23dbf.firebaseapp.com",
  databaseURL: "https://hospital-management-23dbf-default-rtdb.firebaseio.com",
  projectId: "hospital-management-23dbf",
  storageBucket: "hospital-management-23dbf.appspot.com",
  messagingSenderId: "928001005956",
  appId: "1:928001005956:web:7f48c6d6f49c9aa9d1a5d1",
  measurementId: "G-B0CEBXVS7F"
};

firebase.initializeApp(firebaseConfig);

const AddPatientDetails = ({ users, genders, status, types }) => {
  
    const [Name, setName] = useState('');
    // const [idNumber, setIdNumber] = useState('');
    const [DateOfAdmission, setRegDate] = useState(new Date());
    const [Address, setAddress] = useState('');
    const [PhoneNo, setCell] = useState('');
    const [Dateofbirth, setBirthDate] = useState('');
    const [Residence, setResidence] = useState('');
    const [Email, setEmail] = useState('');
    const [Guardian, setGuardian] = useState('');
    const [Relation, setRelation] = useState('');
    const [Gender, setGender] = useState('');
    const [PatientStatus, setStatusPatient] = useState('');
    const [InpatientOutpatient, setPatientType] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadPhoto, setUploadPhoto] = useState(null);
    const [doctorName,setDoctor] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [photoName, setPhotoName] = useState('Choose file');


    const [ClinicReference, setClinicReference] = useState('');
    const [Age, setAge] = useState('');
    const [Dominance, setDominance] = useState('');
    const [MaritalStatus, setMaritalStatus] = useState('');
    const [ReferenceSource, setReferenceSource] = useState('');
    const [Height, setHeight] = useState('');
    const [Weight, setWeight] = useState('');
    const [BMI, setBMI] = useState('');
    const [FoodHabits, setFoodHabits] = useState('');
    const [Occupation, setOccupation] = useState('');
    const [City, setCity] = useState('');
    const [Pincode, setPincode] = useState('');
    const [ContactPerson, setContactPerson] = useState('');
    const [ContactPersonNumber, setContactPersonNumber] = useState('');
    const [Diabetes, setDiabetes] = useState('');
    const [AnyAllergy, setAnyAllergy] = useState('');
    const [AadharNumber, setAadharNumber] = useState('');
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (uploadType === 'multi' && bulgData.length > 0) {
        // Submit bulk data
        try {
          const latestPatientId = await getLatestPatientId(formData1.HospitalId);
          const updatedBulkData = [];
          
          bulgData.forEach((item, index) => {
            
            updatedBulkData.push({
              ...item,
              patientId: (parseInt(latestPatientId) + index + 1).toString(),
              HospitalId: formData1.HospitalId, 
            });
          });
  
          const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/Patient-bulk-submit/${formData1.HospitalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedBulkData),
          });
          const result = await response.json();
          if (response.ok) {
            setSuccessMessage(result.message);
            setErrorMessage('');
            setBulgData([]);
          } else {
            throw new Error(result.error);
          }
        } catch (error) {
          console.error('Error submitting bulk data: ', error);
          setSuccessMessage('');
          setErrorMessage('Error submitting bulk data.');
        }
      } else {
      const formattedDateOfAdmission = format(DateOfAdmission, 'yyyy-MM-dd');
      const formattedDateOfBirth = format(Dateofbirth, 'dd/MM/yyyy');
  
      const formData = {
        Name,
        // idNumber,
        DateofAdmission: formattedDateOfAdmission,
        Address,
        PhoneNo,
        Dateofbirth: formattedDateOfBirth,
        Residence: Residence || null,
        Email: Email || null,
        Guardian: Guardian || null,
        Relation: Relation || null,
        Gender,
        PatientStatus: PatientStatus || null,
        InpatientOutpatient,
        UploadPhoto: uploadPhoto ? uploadPhoto.name : null,
        doctorName,

        ClinicReference: ClinicReference || null,
        Age,
        Dominance: Dominance || null,
        MaritalStatus: MaritalStatus || null,
        ReferenceSource: ReferenceSource || null,
        Height: Height || null,
        Weight: Weight || null,
        BMI: BMI || null,
        FoodHabits: FoodHabits || null,
        Occupation: Occupation || null,
        City,
        Pincode: Pincode || null,
        ContactPerson: ContactPerson || null,
        ContactPersonNumber: ContactPersonNumber || null,
        Diabetes: Diabetes || null,
        AnyAllergy: AnyAllergy || null,
        AadharNumber: AadharNumber || null,
        HospitalId: formData1.HospitalId
      };
      try {
        if (uploadPhoto) {
          // If a photo is uploaded, upload it to Firebase
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(uploadPhoto.name);
          await fileRef.put(uploadPhoto);
    
          // Get the image URL from Firebase Storage
          const url = await fileRef.getDownloadURL();
          
          // Update form data with the image URL
          formData.UploadPhoto = url;
        }
    
        const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/patient-submit/${formData1.HospitalId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData) 
        });
  
        const result = await response.json();
        if (response.ok) {
          setSuccessMessage(result.message);
          setErrorMessage('');

          setName('');
          setAddress('');
          setGender('');
          setCell('');
          setEmail('');
          setGuardian('');
          setRelation('');
          setAge('');
          setFoodHabits('');
          setUploadPhoto('');
          setPhotoName('');
          setHeight('');
          setWeight('');
          setBMI('');
          setDominance('');
          setOccupation('');
          setCity('');
          setPincode('');
          setAadharNumber('');
          setRegDate('');
          setBirthDate('');
          setPatientType('');
          setDoctor('');
          setStatusPatient('');
          setClinicReference('');
          setReferenceSource('');
          setDiabetes('');
          setAnyAllergy('');
          setContactPerson('');
          setContactPersonNumber('');
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error submitting form: ', error);
        setSuccessMessage('');
        setErrorMessage('Error submitting form.');
      }
    }
    };

    const getLatestPatientId = async (HospitalId) => {
      try {
        const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/Patient-latest-id?HospitalId=${HospitalId}`);
        const result = await response.json();
        if (response.ok) {
          console.log('Latest Patient ID:', result.latestPatientId);
          return result.latestPatientId;
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error fetching latest patient ID: ', error);
        throw error;
      }
    };
  
    useEffect(() => {
      getLatestPatientId();
    }, []);
  
    const uploadFileHandler = (e) => {
      const file = e.target.files[0];
      if (file) {
        setUploadPhoto(file);
        setPhotoName(file.name);
      }
    };
  
    const [occupationOptions, setOccupationOptions] = useState([]);
    const [inpatientOutpatientOptions, setInpatientOutpatientOptions] = useState([]);
    const [patientStatusOptions, setPatientStatusOptions] = useState([]);

    const[formData1, setFormData1]= useState({
      userId:"",
      HospitalName:"",
    })

    const[HospitalName,setHospitalName]=useState("");

    useEffect(()=>{
      const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          const userId = currentUser.uid;
  
          setFormData1((prevData) => ({
            ...prevData,
            userId: userId,
            HospitalName: HospitalName,
          }));
  
          try {
            const response = await axios.get(
              `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
            );
            const addressData = response.data;
            setFormData1((prevData) => ({
              ...prevData,
              HospitalName: addressData.HospitalName || " ",
              HospitalId: addressData.HospitalId || " ",
            }));
            const occupationResponse = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getOccupations/${addressData.HospitalId}`);
            const extraFields = occupationResponse.data;

              setOccupationOptions(extraFields.Occupation || []);
              setInpatientOutpatientOptions(extraFields.InpatientOutpatient || []);
              setPatientStatusOptions(extraFields.PatientStatus || []);
          } catch (error) {
            console.error("Error fetching user data:", error);
           
          } finally {
            
          }
        } else {
         
        }
      });
  
      return () => unsubscribe();
    }, []);


    const [doctorName1, setDoctorName1] = useState('');
    const [doctors, setDoctors] = useState([]);
  
    useEffect(() => {
      // Fetch doctor details from the backend API
      const fetchDoctors = async () => {
        try {
          const {HospitalId}= formData1;
          const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getdoctorsname/${HospitalId}`);
          console.log("response for doctor name:", response.data);
          setDoctors(response.data);
        } catch (error) {
          console.error("Error fetching doctors:", error);
        }
      };
  
      if (formData1.HospitalId) {
        fetchDoctors();
      }
    }, [formData1.HospitalId]);

    const [uploadType, setUploadType] = useState('single');

    const [bulgData, setBulgData] = useState([]);
  
    const handleUploadTypeChange = (e) => {
      setUploadType(e.target.value);
    };

    const parseCSV = (csvData) => {
      // Parse CSV data using Papa Parse
      const parsedData = Papa.parse(csvData, {
        header: true, // Assume the first row contains column headers
        skipEmptyLines: true, // Skip empty lines in the CSV
      });
  
      // Check for parsing errors
      if (parsedData.errors.length > 0) {
        console.error('Error parsing CSV: ', parsedData.errors);
        throw new Error('Error parsing CSV file.');
      }
  
      // Return the array of bulg data objects
      return parsedData.data;
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = event.target.result;
          try {
            const parsedData = parseCSV(csvData); // Call a separate function to parse CSV data
            setBulgData(parsedData);
            setErrorMessage(''); // Clear any previous errors
            setSuccessMessage('Bulg data Inserted.Please click submit');
          } catch (error) {
            setErrorMessage('Error parsing CSV file.');
            console.error('Error parsing CSV: ', error);
          }
        };
        reader.readAsText(file);
      }
    };

    const handleDownloadTemplate = () => {
      // Create a new workbook
      const headers = [
        'Name',
        'PhoneNo',
        'AadharNumber',
        'Address',
        'Age',
        'AnyAllergy',
        'BMI',
        'City',
        'ClinicReference',
        'ContactPerson',
        'ContactPersonNumber',
        'DateofAdmission',
        'Dateofbirth',
        'Diabetes',
        'Dominance',
        'Email',
        'FoodHabits',
        'Gender',
        'Guardian',
        'Height',
        'InpatientOutpatient',
        'MaritalStatus',
        'Occupation',
        'PatientStatus',
        'Pincode',
        'ReferenceSource',
        'Relation',
        'Weight',
        'doctorName'
      ];
    
      // Create a CSV string from the headers
      const csvContent = Papa.unparse([headers]);
    
      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
      // Trigger the download
      saveAs(blob, 'MedicineDetailsTemplate.csv');
    };

    return (
      <Layout>
      <Loader/>
      <h1 className="mt-4 color-bright mb-4">Add Patient Details</h1>
      <div className="form-check form-check-inline  radioadd">
          <input 
            className="form-check-input" 
            type="radio" 
            name="uploadType" 
            id="singleUpload" 
            value="single"
            checked={uploadType === 'single'}
            onChange={handleUploadTypeChange}
          />
          <label className="form-check-label" htmlFor="singleUpload">Single Data Upload</label>
        </div>
        <div className="form-check form-check-inline">
          <input 
            className="form-check-input" 
            type="radio" 
            name="uploadType" 
            id="multiUpload" 
            value="multi"
            checked={uploadType === 'multi'}
            onChange={handleUploadTypeChange}
          />
          <label className="form-check-label" htmlFor="multiUpload">Multi Data Upload</label>
        </div>
      <div className="containerAddPatient">
      <div className="row">
        <form className="form-card" onSubmit={handleSubmit}>
        {uploadType === 'single' && (
        <div className="formtype">
          <div className="card Addpatientcard mb-4">
            <div className="card-header" style={{backgroundColor:"lightblue"}}>
              <h5>Personal Details</h5>
            </div>
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputName">Name *</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required/>
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputAddress">Address *</label>
                  <textarea className="form-control input-bg-light-grey input-shadow3" id="inputAddress" placeholder="Address" rows="3" value={Address} onChange={(e) => setAddress(e.target.value)} required/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputGender">Gender *</label>
                  <select className="form-control styled-select drop-down-patient-form" id="inputGender" value={Gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group col-md-6" >
                  <label className="font-weight-bold" htmlFor="inputCell">Phone No *</label>
                  <input type="number" className="form-control input-shadow input-p" id="inputCell" placeholder="Phone No" value={PhoneNo} onChange={(e) => setCell(e.target.value)} required/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
                  <input type="email" className="form-control input-shadow input-p" id="inputEmail" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputGuardian">Guardian</label>
                  <select className="form-control styled-select drop-down-patient-form" id="inputGuardian" value={Guardian} onChange={(e) => setGuardian(e.target.value)}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputRelation">Relation</label>
                  <select className="form-control styled-select drop-down-patient-form" id="inputRelation" value={Relation} onChange={(e) => setRelation(e.target.value)}>
                    <option value="">Select Relation</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Friend">Friend</option>
                    <option value="Son">Son</option>
                    <option value="Guardian">Guardian</option>
                    <option value="Wife">Wife</option>
                    <option value="Husband">Husband</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputRefrence">Age *</label>
                  <input type="number" className="form-control input-shadow input-p" id="inputAge" placeholder="Age" value={Age} onChange={(e)=> setAge(e.target.value)} required/>
                </div>
              </div>
              <div className="form-row">
              <div className="form-group col-md-6">
                  <label className="font-weight-bold">Food Habits</label>
                  <div className="form-check">
                    <input 
                      className="form-check-input" 
                      type="radio" 
                      id="foodVeg" 
                      name="foodHabits" 
                      value="Veg" 
                      checked={FoodHabits === 'Veg'} 
                      onChange={(e) => setFoodHabits(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="foodVeg">
                      Veg
                    </label>
                  </div>
                  <div className="form-check">
                    <input 
                      className="form-check-input" 
                      type="radio" 
                      id="foodNonVeg" 
                      name="foodHabits" 
                      value="Non-veg" 
                      checked={FoodHabits === 'Non-veg'} 
                      onChange={(e) => setFoodHabits(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="foodNonVeg">
                      Non-veg
                    </label>
                  </div>
                  <div className="form-check">
                    <input 
                      className="form-check-input" 
                      type="radio" 
                      id="foodVegetarian" 
                      name="foodHabits" 
                      value="Vegetarian" 
                      checked={FoodHabits === 'Vegetarian'} 
                      onChange={(e) => setFoodHabits(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="foodVegetarian">
                      Vegetarian
                    </label>
                  </div>
                </div>

                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Marital Status</label>
                    <div className="form-check">
                      <input 
                        type="radio" 
                        id="maritalStatusSingle" 
                        name="maritalStatus" 
                        value="Single" 
                        checked={MaritalStatus === 'Single'} 
                        onChange={(e) => setMaritalStatus(e.target.value)} 
                        className="form-check-input" 
                      />
                      <label className="form-check-label" htmlFor="maritalStatusSingle">Single</label>
                    </div>
                    <div className="form-check">
                      <input 
                        type="radio" 
                        id="maritalStatusMarried" 
                        name="maritalStatus" 
                        value="Married" 
                        checked={MaritalStatus === 'Married'} 
                        onChange={(e) => setMaritalStatus(e.target.value)} 
                        className="form-check-input" 
                      />
                      <label className="form-check-label" htmlFor="maritalStatusMarried">Married</label>
                    </div>
                    <div className="form-check">
                      <input 
                        type="radio" 
                        id="maritalStatusDivorced" 
                        name="maritalStatus" 
                        value="Divorced" 
                        checked={MaritalStatus === 'Divorced'} 
                        onChange={(e) => setMaritalStatus(e.target.value)} 
                        className="form-check-input" 
                      />
                      <label className="form-check-label" htmlFor="maritalStatusDivorced">Divorced</label>
                    </div>
                    <div className="form-check">
                      <input 
                        type="radio" 
                        id="maritalStatusWidowed" 
                        name="maritalStatus" 
                        value="Widowed" 
                        checked={MaritalStatus === 'Widowed'} 
                        onChange={(e) => setMaritalStatus(e.target.value)} 
                        className="form-check-input" 
                      />
                      <label className="form-check-label" htmlFor="maritalStatusWidowed">Widowed</label>
                    </div>
                  </div>
                  <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputHeight">Height</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputHeight" placeholder="Height" value={Height} onChange={(e) => setHeight(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputWeight">Weight (in Kg)</label>
                  <input className="form-control input-shadow input-p" id="inputWeight" placeholder="Weight"  value={Weight} onChange={(e) => setWeight(e.target.value)} />
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputBMI">BMI</label>
                  <input className="form-control input-shadow input-p" id="inputBMI" placeholder="BMI"  value={BMI} onChange={(e) => setBMI(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputDominance">Dominance(R/L)</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputDominace" placeholder="Dominance" value={Dominance} onChange={(e)=> setDominance(e.target.value)}/>
                </div>
              </div>
              <div className="row">
              <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputOccupation">Occupation</label>
                  <select
                    className="form-control styled-select drop-down-patient-form"
                    id="inputOccupation"
                    value={Occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                  >
                    <option value="">Select Occupation</option>
                    {occupationOptions.map((occupation, index) => (
                      <option key={index} value={occupation}>{occupation}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label className="font-weight-bold" htmlFor="inputCity">City *</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputAddress" placeholder="City"  value={City} onChange={(e) => setCity(e.target.value)} required/>
                </div>
                <div className="form-group col-md-4">
                  <label className="font-weight-bold" htmlFor="inputPincode">Pincode</label>
                  <input type="number" className="form-control input-shadow input-p" id="inputPincode" placeholder="Pincode"  value={Pincode} onChange={(e) => setPincode(e.target.value)} />
                </div>
                <div className="form-group col-md-4">
                  <label className="font-weight-bold" htmlFor="inputAadhar">AadharNumber</label>
                  <input type="number" className="form-control input-shadow input-p" id="inputAadhar" placeholder="AadharNumber"  value={AadharNumber} onChange={(e) => setAadharNumber(e.target.value)} />
                </div>
              </div>
                </div>
            </div>
          </div>
          <div className="card mb-4" style={{marginLeft:"20px"}}>
            <div className="card-header" style={{backgroundColor:"lightblue"}}>
              <h5>Official Details</h5>
            </div>
            <div className="card-body">
              <div className="form-row">
              <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputRegDate">Registration Date</label>
                    <div className="input-container" style={{ position: 'relative' }}>
                      <DatePicker
                        selected={DateOfAdmission}
                        onChange={(date) => setRegDate(date)}
                        className="form-control input-shadow1 date-p"
                        id="inputRegDate"
                        placeholderText="Select date"
                        style={{ paddingRight: '30px' }} // Add padding to avoid text overlap with icon
                      />
                      <div className="icon-container" style={{ position: 'absolute', right: '100px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
                        <i className="fa fa-calendar" style={{ color: "blue" }}></i>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputBirthDate">Date of Birth *</label>
                    <div className="input-container" style={{ position: 'relative' }}>
                      <DatePicker
                        selected={Dateofbirth}
                        onChange={(date) => setBirthDate(date)}
                        className="form-control input-shadow1 date-p"
                        id="inputBirthDate"
                        placeholder="select date of birth"
                        style={{ paddingRight: '30px' }} // Add padding to avoid text overlap with icon
                      />
                      <div className="icon-container" style={{ position: 'absolute',right: '100px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
                        <i className="fa fa-calendar" style={{ color: "blue" }}></i>
                      </div>
                    </div>
                  </div>

              </div>
              <div className="form-row">
              <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputPatientType">Inpatient/Outpatient *</label>
                  <select
                    className="form-control styled-select drop-down-patient-form"
                    id="inputPatientType"
                    value={InpatientOutpatient}
                    onChange={(e) => setPatientType(e.target.value)}
                  >
                    <option>Please Select</option>
                    {inpatientOutpatientOptions.map((type, index) => (
                      <option key={index} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputDaughter">Doctor Name *</label>
                  <select
                    className="form-control input-shadow"
                    id="inputDaughter"
                    value={doctorName}
                    onChange={(e) => setDoctor(e.target.value)}
                    required
                  >
                    <option value="">Select Doctor Name</option>
                    {doctors.map((doctor) => (
                      <option key={doctor.doctorId} value={doctor.Name}>
                        {doctor.Name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputDaughter">Hospital Name</label>
                <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={formData1.HospitalName} disabled/>
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputStatusPatient">Patient Status</label>
                    <select
                      className="form-control styled-select drop-down-patient-form"
                      id="inputStatusPatient"
                      value={PatientStatus}
                      onChange={(e) => setStatusPatient(e.target.value)}
                    >
                      <option>Please Select</option>
                      {patientStatusOptions.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>
             
              
              <div className="form-row">
               
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputRefrence">Clinic Reference Number</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputClinicNumber" placeholder="Clinic Number" value={ClinicReference} onChange={(e)=> setClinicReference(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputReferenceSource">Reference Source</label>
                  <select 
                    className="form-control styled-select drop-down-patient-form" 
                    id="inputReferenceSource" 
                    value={ReferenceSource} 
                    onChange={(e) => setReferenceSource(e.target.value)}
                  >
                    <option value="">Select Reference Source</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Patient">Patient</option>
                    <option value="Website">Website</option>
                    <option value="Advertisement">Advertisement</option>
                    <option value="Others">Others</option>
                    <option value="Insurance">Insurance</option>
                  </select>
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputDiabetes">Diabetes</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputDiabetes" placeholder="Diabetes" value={Diabetes} onChange={(e)=> setDiabetes(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputAllergy">Any Allergy</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputAllergy" placeholder="Allergy" value={AnyAllergy} onChange={(e)=> setAnyAllergy(e.target.value)}/>
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputContactPerson">Contact Person</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputContactPerson" placeholder="Person Name" value={ContactPerson} onChange={(e)=> setContactPerson(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="inputContactPersonNumber">Contact Person Number</label>
                  <input type="text" className="form-control input-shadow input-p" id="inputContactPersonNumber" placeholder="Person Number" value={ContactPersonNumber} onChange={(e)=> setContactPersonNumber(e.target.value)}/>
                </div>
                </div>
                <div className="form-group col-md-6">
                  <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                  <div className="custom-file">
                  <input
                      type="file"
                      className="form-control"
                      id="inputPhoto"
                      onChange={uploadFileHandler}
                    />
                    <small>{photoName}</small>
                  </div>
                  {uploading && (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              
            </div>
          </div>
          </div>
        )}
        {uploadType === 'multi' && (
          <div className="row mb-4 multidata">
            <div className="col-md-6" style={{marginLeft:"200px"}}>
              <label htmlFor="csvUpload">Upload CSV File</label>
              <input
                type="file"
                id="csvUpload"
                className="form-control"
                accept=".csv"
                onChange={handleFileChange}
              />
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleDownloadTemplate}
              >
                Download Template
              </button>
            </div>
          </div>
        )}
          <button type="submit" className="btn btn-primary mb-4" style={{ width: "20%", textAlign: "center", marginLeft:"35%" }}>Add Patient</button>
        </form>
        {successMessage && (
          <div className="alert alert-success mt-3" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
      </div>
    </Layout>
    );
  };

export default AddPatientDetails;