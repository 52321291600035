import React,{useState, useEffect} from "react";
import axios from "axios";

const Features=()=>{

    const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.get("https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/homefeaturesection");
        const data = response.data;
        if (data) {
          setFeatures(data.filter(feature => feature !== null)); // Filter out null values
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchFeatures();
  }, []);

    return(
        <div className="container-xxl py-5" id="feature">
        <div className="container4 py-5 px-lg-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium mb-4">App Features</h5>
            <h1 className="mb-5">Awesome Features</h1>
          </div>
         
      <div className="row g-4">
            {features.map((feature, index) => (
              <div
                key={feature.id}
                className={`col-lg-4 col-md-6 wow fadeInUp`}
                data-wow-delay={`${0.1 * (index + 1)}s`}
              > 
                <div className="feature-item bg-light rounded p-4" style={{height:"275px"}}>
                  <div
                    className={`d-flex align-items-center justify-content-center bg-${index % 2 === 0 ? "primary" : "secondary"}-gradient rounded-circle mb-4 featurecircle`}
                    style={{ width: 60, height: 60 }}
                    
                  >
                  <div className="icon d-flex justify-content-center align-items-center" style={{width: 45, height: 45}} dangerouslySetInnerHTML={{ __html: feature.Icon }}/>
                  </div>
                  <h5 className="mb-3 featuretitle">{feature.Title}</h5>
                  <p className="m-0 featuredescription">{feature.Description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default Features;