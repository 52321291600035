import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../frontpage/Loader";

const UpdateHospital = () => {
  const { HospitalId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    HospitalName: "",
    Email: "",
    PhoneNumber: "",
    CEOName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/get-hospital/${HospitalId}`);
        setFormData(response.data);
      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }
    };

    fetchHospitalData();
  }, [HospitalId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/update-hospital/${HospitalId}`, formData);
      setSuccessMessage("Hospital information updated successfully!");
      navigate("/list-Hospital"); // Redirect to hospital list after successful update
    } catch (error) {
      console.error("Error updating hospital data:", error);
    }
  };

  return (
    <Layout title="Update Hospital">
    <Loader/>
      <form onSubmit={handleSubmit}>
        <h1 style={{ color: "#007bff" }} className="mb-4">Update Hospital</h1>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputHospitalName">Hospital Name</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputHospitalName"
            name="HospitalName"
            placeholder="Hospital Name"
            value={formData.HospitalName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
          <input
            type="email"
            className="form-control input-shadow input-p"
            id="inputEmail"
            name="Email"
            placeholder="Email"
            value={formData.Email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputPhoneNumber">Phone Number</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputPhoneNumber"
            name="PhoneNumber"
            placeholder="Phone Number"
            value={formData.PhoneNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="font-weight-bold" htmlFor="inputCEOName">CEO Name</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            id="inputCEOName"
            name="CEOName"
            placeholder="CEO Name"
            value={formData.CEOName}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary buttoncenter">Update</button>
        {successMessage && <p className="mt-3 text-success">{successMessage}</p>}
      </form>
    </Layout>
  );
};

export default UpdateHospital;
