import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Whypage=()=>{
    return(
    <>
    <Header/>
    <Footer/>
    </>
    );
}

export default Whypage;