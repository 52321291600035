import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../core/Layout';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Papa from 'papaparse';
import moment from 'moment';
import Loader from '../frontpage/Loader';

const UpdateMedicine = () => {
  const { medicineId } = useParams();

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const [medicine, setMedicine] = useState({
    MedicineName: '',
    GenericName: '',
    BatchNo: '',
    Uses: '',
    Quantity: '',
    Type: '',
    ManufactureDate:  new Date(),
    ExpiryDate: new Date(),
    Price: '',
    RetailCost: '',
    Sideeffects: '',
    Vendor: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log('medicineId:', medicineId); // Log the medicineId

    const fetchMedicineDetails = async () => {
      if (!medicineId) {
        setErrorMessage('Invalid Medicine ID');
        return;
      }
      try {
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/medicine/${medicineId}`,{
          params: {
            HospitalId: formData1.HospitalId 
          }
        });
        const data = response.data;

        data.ManufactureDate = moment(data.ManufactureDate, ["DD/MM/YYYY", "MM/DD/YYYY"]).toDate();
        data.ExpiryDate = moment(data.ExpiryDate, ["DD/MM/YYYY", "MM/DD/YYYY"]).toDate();

        setMedicine(data);
      } catch (error) {
        console.error('Error fetching medicine details:', error);
      }
    };

    fetchMedicineDetails();
  }, [medicineId,  formData1.HospitalId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setMedicine({
      ...medicine,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
    setMedicine({
      ...medicine,
     [name]: date
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updatemedicine/${medicineId}`, {
        ...medicine,
        HospitalId: formData1.HospitalId,
       ManufactureDate: moment(medicine.ManufactureDate).format('DD-MM-YYYY'),
        ExpiryDate: moment(medicine.ExpiryDate).format('DD-MM-YYYY'),
      });
      setSuccessMessage('Medicine updated successfully');
      setErrorMessage('');
    } catch (error) {
      console.error('Error updating medicine:', error);
      setSuccessMessage('');
      setErrorMessage('Error updating medicine');
    }
  };
  
  const UpdateMedicineForm = () => (
    <div className="containe">
  <form onSubmit={handleSubmit}>
    <div className="row">
      {/* First Section */}
      <div className="col-md-3" style={{marginLeft:"15%"}}>
        <div className="form-group">
          <label htmlFor="inputAddress">Name</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="Name"
            name="MedicineName"
            value={medicine.MedicineName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="inputAddress">Generic Name</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="e.g Paracetamol"
            name="GenericName"
            value={medicine.GenericName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="inputAddress">Batch No</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="batch no"
            name="BatchNo"
            value={medicine.BatchNo}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Second Section */}
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="inputAddress">Quantity</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="quantity"
            name="Quantity"
            value={medicine.Quantity}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Type</label>
          <select
            onChange={handleChange}
            className="form-control styled-select input-p"
            id="exampleFormControlSelect1"
            name="Type"
            value={medicine.Type}
          >
            <option>Select Type</option>
            {["Tablet", "Capsule", "Syrup"].map((c, i) => (
              <option key={i} value={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="inputAddress">Cost</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="e.g ksh 2500"
            name="Price"
            value={medicine.Price}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Third Section */}
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="inputAddress">Manufacture Date</label>
          <div className="input-container">
            <DatePicker
              selected={medicine.ManufactureDate}
              onChange={(date) => handleDateChange('ManufactureDate', date)}
              className="form-control input-shadow1 input-p"
            />
            <div className="icon-container">
              <i className="fa fa-calendar" style={{ color: "blue"}}></i>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputAddress">Expiry Date</label>
          <div className="input-container">
            <DatePicker
              selected={medicine.ExpiryDate}
              onChange={(date) => handleDateChange('ExpiryDate', date)}
              className="form-control input-shadow1 input-p"
            />
            <div className="icon-container">
              <i className="fa fa-calendar" style={{ color: "blue" }}></i>
            </div>
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="inputAddress">Retail Cost</label>
          <input
            type="text"
            className="form-control input-shadow input-p"
            placeholder="e.g ksh 1700"
            name="RetailCost"
            value={medicine.RetailCost}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>

    <div className="row">
      {/* Fourth Section */}
      <div className="col-md-3"  style={{marginLeft:"15%"}}>
        <div className="form-group">
          <label className="text-muted">Vendor</label>
          <select
            onChange={handleChange}
            className="form-control styled-select input-p"
            name="Vendor"
            value={medicine.Vendor}
          >
            <option>Select Vendor</option>
            {[
              { _id: "1", name: "Pharma Inc." },
              { _id: "2", name: "Health Ltd." }
            ].map((c, i) => (
              <option key={i} value={c._id}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Fifth Section */}
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">Uses</label>
          <textarea
            className="form-control input-bg-light-grey input-shadow3 input-p"
            id="exampleFormControlTextarea1"
            name="Uses"
            value={medicine.Uses}
            onChange={handleChange}
            placeholder="write description"
            rows="3"
          />
        </div>
      </div>

      {/* Sixth Section */}
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">Effects</label>
          <textarea
            className="form-control input-bg-light-grey input-shadow3 input-p"
            id="exampleFormControlTextarea1"
            name="Sideeffects"
            value={medicine.Sideeffects}
            onChange={handleChange}
            placeholder="write effects"
            rows="3"
          />
        </div>
      </div>
    </div><br></br>

    <div className="row">
      {/* Seventh Section */}
      <div className="col-md-12 text-center">
        <button type="submit" className="btn btn-primary" style={{ width: "10%" }}>
          Save
        </button>
      </div>
    </div>

    {successMessage && (
      <div className="alert alert-success mt-3" role="alert">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="alert alert-danger mt-3" role="alert">
        {errorMessage}
      </div>
    )}
  </form>
</div>


  );

  return (
    <Layout title="Category Treatment Form">
    <Loader/>
      <>
      <h1 className="mt-4 color-bright">Update Medicine Details</h1><br></br>
        {UpdateMedicineForm()}
      </>
    </Layout>
  );
};

export default UpdateMedicine;