import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Papa from 'papaparse';
import "./AddMedicine.css";
import { FaCalendarAlt } from 'react-icons/fa';
import axios from "axios";
import Loader from "../frontpage/Loader";
import moment from 'moment';

const AddMedicine = () => {
  const [MedicineName, setMedicineName] = useState("");
  const [GenericName, setGenericName] = useState("");
  const [BatchNo, setBatchNo] = useState("");
  const [Uses, setUses] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [Type, setType] = useState("");
  const [ManufactureDate, setManDate] = useState(new Date());
  const [ExpiryDate, setExpDate] = useState(new Date());
  const [Price, setCost] = useState("");
  const [RetailCost, setRetailCost] = useState("");
  const [Sideeffects, setEffects] = useState("");
  const [Vendor, setVendor] = useState("");
  const [bulgData, setBulgData] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadType, setUploadType] = useState('single'); // State to manage upload type

  // Dummy types and vendors for design purposes
  const dummyTypes = ["Tablet", "Capsule", "Syrup"];
  const dummyVendors = [
    { _id: "1", name: "Pharma Inc." },
    { _id: "2", name: "Health Ltd." },
  ];

  const getLatestMedicineId = async (HospitalId) => {
    try {
      const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/Medicine-latest-id?HospitalId=${HospitalId}`);
      const result = await response.json();
      if (response.ok) {
        console.log('Latest Medicine ID:', result.latestMedicineId);
        return result.latestMedicineId;
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error fetching latest medicine ID: ', error);
      throw error;
    }
  };

  const [formData1, setFormData1] = useState({ userId: "", HospitalName: "", HospitalId: "" });
  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        console.log("userId:",userId);

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          console.log("addressId:", addressData.HospitalId);
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  console.log("hospitalId:",formData1.HospitalId);
  console.log("hospitalName:", formData1.HospitalName);

  const submitHandler = async (e) => {
    e.preventDefault();

    const formatDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return `${day}-${month}-${year}`; // 'YYYY-MM-DD' format
    };

    if (uploadType === 'multi' && bulgData.length > 0) {
      // Submit bulk data
      try {
        const latestMedicineId = await getLatestMedicineId(formData1.HospitalId);
        const updatedBulkData = [];
        
        bulgData.forEach((item, index) => {
          
          updatedBulkData.push({
            ...item,
            medicineId: (parseInt(latestMedicineId) + index + 1).toString(),
            HospitalId: formData1.HospitalId, 
          });
        });

        const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/Medicine-bulk-submit/${formData1.HospitalId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedBulkData),
        });
        const result = await response.json();
        if (response.ok) {
          setSuccessMessage(result.message);
          setErrorMessage('');
          setBulgData([]);
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error submitting bulk data: ', error);
        setSuccessMessage('');
        setErrorMessage('Error submitting bulk data.');
      }
    } else {
      // Submit single entry data
      const formData = {
        MedicineName,
        GenericName,
        BatchNo,
        Uses,
        Quantity,
        Type,
        ManufactureDate: formatDate(ManufactureDate),
        ExpiryDate: formatDate(ExpiryDate),
        Price,
        RetailCost,
        Vendor,
        Sideeffects,
        HospitalId: formData1.HospitalId,
      };

      try {
        const response = await fetch(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/Medicine-submit/${formData1.HospitalId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (response.ok) {
          setSuccessMessage(result.message);
          setErrorMessage('');
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error submitting form: ', error);
        setSuccessMessage('');
        setErrorMessage('Error submitting form.');
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;
        try {
          const parsedData = parseCSV(csvData); // Call a separate function to parse CSV data
          setBulgData(parsedData);
          setErrorMessage(''); // Clear any previous errors
          setSuccessMessage('Bulg data uploaded successfully.');
        } catch (error) {
          setErrorMessage('Error parsing CSV file.');
          console.error('Error parsing CSV: ', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csvData) => {
    // Parse CSV data using Papa Parse
    const parsedData = Papa.parse(csvData, {
      header: true, // Assume the first row contains column headers
      skipEmptyLines: true, // Skip empty lines in the CSV
    });

    // Check for parsing errors
    if (parsedData.errors.length > 0) {
      console.error('Error parsing CSV: ', parsedData.errors);
      throw new Error('Error parsing CSV file.');
    }

    // Return the array of bulg data objects
    return parsedData.data;
  };

  const handleDownloadTemplate = () => {
    // Create a new workbook
    const headers = [
      'MedicineName',
      'GenericName',
      'BatchNo',
      'Uses',
      'Quantity',
      'Type',
      'ManufactureDate',
      'ExpiryDate',
      'Price',
      'RetailCost',
      'Sideeffects',
      'Vendor'
    ];
  
    // Create a CSV string from the headers
    const csvContent = Papa.unparse([headers]);
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Trigger the download
    saveAs(blob, 'MedicineDetailsTemplate.csv');
  };

  const handleUploadTypeChange = (e) => {
    setUploadType(e.target.value);
  };

  
  const handleManufactureDateChange = (date) => {
    setManDate(moment(date).format('DD/MM/YYYY'));
  };

  const handleExpiryDateChange = (date) => {
    setExpDate(moment(date).format('DD/MM/YYYY'));
  };

  const AddMedicineForm = () => (
    <div className="container3">
    <div className="text-center">
      <h3 className="mt-4 color-bright">Add Medicine Details</h3><br></br>
      <div className="form-check form-check-inline">
        <input 
          className="form-check-input" 
          type="radio" 
          name="uploadType" 
          id="singleUpload" 
          value="single"
          checked={uploadType === 'single'}
          onChange={handleUploadTypeChange}
        />
        <label className="form-check-label" htmlFor="singleUpload">Single Data Upload</label>
      </div>
      <div className="form-check form-check-inline">
        <input 
          className="form-check-input" 
          type="radio" 
          name="uploadType" 
          id="multiUpload" 
          value="multi"
          checked={uploadType === 'multi'}
          onChange={handleUploadTypeChange}
        />
        <label className="form-check-label" htmlFor="multiUpload">Multi Data Upload</label>
      </div>
    </div>

    <form onSubmit={submitHandler} className="mt-4">
      {uploadType === 'single' && (
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="inputAddress">Name</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Medicine"
                value={MedicineName}
                onChange={(e) => setMedicineName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Generic Name</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Generic Name"
                value={GenericName}
                onChange={(e) => setGenericName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Batch Number</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Batch Number"
                value={BatchNo}
                onChange={(e) => setBatchNo(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Uses</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Uses"
                value={Uses}
                onChange={(e) => setUses(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Quantity</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Quantity"
                value={Quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Type</label>
              <select
                className="form-control input-shadow input-p"
                value={Type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Type</option>
                {dummyTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
<label htmlFor="inputAddress">Manufacture Date</label>
<div className="input-container">
  <DatePicker
    selected={moment(ManufactureDate, 'DD/MM/YYYY').toDate()}
    onChange={handleManufactureDateChange}
     dateFormat="dd/MM/yyyy"
    
    className="form-control input-shadow input-p"
    placeholderText="Select date"
  />
  <FaCalendarAlt className="iconadd" />
</div>
</div>
<div className="form-group">
<label htmlFor="inputAddress">Expiry Date</label>
<div className="input-container">
  <DatePicker
   selected={moment(ExpiryDate, 'DD/MM/YYYY').toDate()}
   onChange={handleExpiryDateChange}
    dateFormat="dd/MM/yyyy"
    
    className="form-control input-shadow input-p"
    placeholderText="Select date"
  />
  <FaCalendarAlt className="iconadd" />
</div>
</div>

            <div className="form-group">
              <label htmlFor="inputAddress">Price</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Price"
                value={Price}
                onChange={(e) => setCost(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Retail Cost</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Retail Cost"
                value={RetailCost}
                onChange={(e) => setRetailCost(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Side effects</label>
              <input
                type="text"
                className="form-control input-shadow input-p"
                placeholder="Side effects"
                value={Sideeffects}
                onChange={(e) => setEffects(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Vendor</label>
              <select
                className="form-control input-shadow input-p"
                value={Vendor}
                onChange={(e) => setVendor(e.target.value)}
              >
                <option value="">Select Vendor</option>
                {dummyVendors.map((vendor) => (
                  <option key={vendor._id} value={vendor.name}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {uploadType === 'multi' && (
        <div className="row mb-4 multidata">
          <div className="col-md-6" style={{marginLeft:"50px"}}>
            <label htmlFor="csvUpload">Upload CSV File</label>
            <input
              type="file"
              id="csvUpload"
              className="form-control"
              accept=".csv"
              onChange={handleFileChange}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleDownloadTemplate}
            >
              Download Template
            </button>
          </div>
        </div>
      )}

      <div className="text-center">
        <button type="submit" className="btn btn-primary">
          Add Medicine
        </button>
      </div>
      {successMessage && (
        <div className="alert alert-success mt-4">{successMessage}</div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-4">{errorMessage}</div>
      )}
    </form>
  </div>
  );

  return (
    <Layout title="Add Medicine" description="Add Medicine Details">
    <Loader/>
      {AddMedicineForm()}
    </Layout>
  );
};

export default AddMedicine;
