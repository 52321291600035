import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom'; 
import 'firebase/compat/auth';
import 'firebase/compat/database'; 
import 'firebase/compat/firestore';
import './signin.css';
import image1 from './img/authimage.png';
import Loader from './frontpage/Loader';

const SignUpForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const submitSignUpForm = () => {
        signUpEmailPassword(email, password);
    }

    const signUpEmailPassword = (email, password) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setMessage(`Signed up as ${user.email}`);

                storeUserData(user.uid, firstName, lastName, email);
                
                
               
            })
            .catch((error) => {
                setMessage(`Error: ${error.message}`);
            });
    }

    // const storeUserData = (userId, firstName, lastName, email) => {
    //     firebase.database().ref('users/' + userId).set({
    //         firstName: firstName,
    //         lastName: lastName,
    //         email: email,
    //         status: 'New'
    //     })
    //     .then(() => {
    //         console.log('User data stored successfully');
    //         window.location.href = '/signin';
    //     })
    //     .catch((error) => {
    //         console.error('Error storing user data:', error);
    //     });
    // }

    const storeUserData = ( userId , firstName, lastName, email) =>{
        fetch('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/addUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, firstName, lastName, email }),
          })
          .then(response => response.text())
          .then(data => {
            console.log(data);
            window.location.href = '/signin';
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }

    return (
        <div className="container1 d-flex">
        <Loader/>
            <div className="form-container1 align-content-center1">
                <form className="bg-light p-5 rounded">
                    <p className="h4 mb-4 text-center">Sign Up</p>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control mb-4"
                            placeholder="First Name"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control mb-4"
                            placeholder="Last Name"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control mb-4"
                            placeholder="Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control mb-4"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-primary" onClick={submitSignUpForm}>
                            Submit
                        </button>
                    </div>
                    <p id="message" className="mt-3 text-center">{message}</p>
                    <p className="mt-3 text-center">
                        Already have an account? <Link to="/signin">Sign in</Link>
                    </p>
                </form>
            </div>
            <div className="image-container1">
                <img src={image1} alt="Image Description" className="img-fluid" />
            </div>
        </div>
    );
}

export default SignUpForm;
