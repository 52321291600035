import React from 'react';

class EditableField extends React.Component {
  handleChange = (e) => {
    const { onItemizedItemEdit } = this.props;
    onItemizedItemEdit(e);
  }

  render() {
    const { cellData } = this.props;
    const { type, name, min, step, value, id, leading, textAlign } = cellData;
    return (
      <div className="editable-field">
      {leading && <span>{leading}</span>}
      <input
        type={type}
        name={name}
        min={min}
        step={step}
        value={value}
        id={id}
        onChange={this.handleChange}
        style={{ textAlign }}
      />
    </div>
    );
  }
}

export default EditableField;
