import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { BiTrash } from "react-icons/bi";
import Select from 'react-select';
import "./Invoice.css";

class InvoiceItem2 extends React.Component {
  handleCheckboxChange = (itemId, updatedSessions) => {
    const updatedItems = this.props.items.map(item => {
      if (item.id === itemId) {
        return { ...item, sessions: updatedSessions };
      }
      return item;
    });
    this.props.onItemizedItemEdit({ target: { id: itemId, name: 'sessions', value: updatedSessions } });
  }
  
  render() {
    const { onItemizedItemEdit, currency, onRowDel, medicineOptions, items, onRowAdd } = this.props;
    
    if (!Array.isArray(items)) {
      console.error('Expected items to be an array, but got:', typeof items, items);
      return null;
    }

    const itemTable = items.map((item, index) => (
      <ItemRow
        index={index}
        onItemizedItemEdit={onItemizedItemEdit}
        item={item}
        onDelEvent={onRowDel}
        key={item.id}
        currency={currency}
        medicineOptions={medicineOptions}
        handleCalculateTotal={this.props.handleCalculateTotal}
        onCheckboxChange={this.handleCheckboxChange}
      />
    ));
    
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th className='text-left select-cell1'>S.No</th>
              <th className='text-center select-cell2'>ITEM</th>
             
              <th className='text-center quantity-cell'>Quantity</th>
              <th className='text-center select-cell3'>Days</th>
              <th className="text-center action-cell">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {itemTable}
          </tbody>
        </Table>
        <Button className="fw-bold" onClick={onRowAdd}>Add Item</Button>
      </div>
    );
  }
}

class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionFrom: '',
      optionPrice: '',
      optionValue: '',
      
    };
  }

  handleDelEvent = () => {
    this.props.onDelEvent(this.props.item);
  }

  handleMedicineSelect = (selectedOption) => {
    const { item, onItemizedItemEdit, handleCalculateTotal } = this.props;
    const { value, label, price } = selectedOption;

    this.setState({
      optionFrom: label,
      optionPrice: price,
      optionValue: value
    });
  
    const event = {
      target: {
        id: item.id,
        name: 'name',
        value: label
      }
    };
    onItemizedItemEdit(event);

    const priceEvent = {
      target: {
        id: item.id,
        name: 'price',
        value: price
      }
    };
    onItemizedItemEdit(priceEvent);

    const medicineIdEvent = {
      target: {
        id: item.id,
        name: 'medicineId',
        value: value
      }
    };
    onItemizedItemEdit(medicineIdEvent);

    handleCalculateTotal();
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { onItemizedItemEdit } = this.props;

    if (name === "days") {
      this.setState({ days: parseInt(value, 10) });
      const event = {
        target: {
          id: this.props.item.id,
          name: 'days',
          value: parseInt(value, 10)
        }
      };
      onItemizedItemEdit(event);
    } else {
      this.setState(prevState => {
        const updatedQuantities = { ...prevState.quantities, [name]: parseInt(value) };
        const itemEvent = {
          target: {
            id: this.props.item.id,
            name: 'quantities',
            value: updatedQuantities
          }
        };
        onItemizedItemEdit(itemEvent);
        return { quantities: updatedQuantities };
      });
    }
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedSessions = { ...this.props.item.sessions, [name]: checked };
    this.props.onCheckboxChange(this.props.item.id, updatedSessions); // Call parent method
  }

  render() {
    const { medicineOptions, item, currency, index} = this.props;

    return (
      <tr className="table-row1">
        <td className=" select-cell2">{index + 1}</td>
        <td className="select-cell1">
          <Select
            options={medicineOptions}
            onChange={this.handleMedicineSelect}
            placeholder="Select a medicine..."
            formatOptionLabel={option => (
              <div>{option.value} - {option.label}</div>
            )}
            value={medicineOptions.find(option => option.value === item.medicineId) || null}
            className="my-2"
          />
        </td>
        <td className="session-quantity-cell text-center">
          <table className="nested-table">
            <thead>
              <tr>
                <th></th>
                {['Mor', 'Noon', 'Eve', 'Nig'].map(time => (
                  <th key={time}>{time}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {['Before', 'After'].map(period => (
                <tr key={period}>
                  <td>{period}</td>
                  {['morning', 'noon', 'evening', 'night'].map(time => (
                    <td key={`${time}${period}`}>
                      <input
                        type="number"
                        className="form-control"
                        name={`${time}${period}`}
                        value={item.quantities[`${time}${period}`] || 0}
                        onChange={this.handleInputChange}
                        min="0"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </td>
        <td className="select-cell3">
          <input type="number" className='form-control' style={{width:"80px"}} name="days" value={item.days || 0}
            onChange={this.handleInputChange} 
            min="0"></input>
        </td>
        <td className="action-cell">
          <BiTrash onClick={this.handleDelEvent} style={{ height: '33px', width: '33px', padding: '7.5px' }} className="text-white mt-1 btn btn-danger" />
        </td>
      </tr>
    );
  }
}

export default InvoiceItem2;
