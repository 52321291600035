import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useParams, useNavigate  } from "react-router-dom";
import { expenses } from "./ListExpenses";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
// import { useHistory } from 'react-router-dom';

const UpdateClinicExpenses = () => {

  const { expenseId } = useParams();
  
  const [expense, setExpense] = useState({
    PatientName: "",
    totalAmount: "",
    description: "",
    date: moment().format('DD/MM/YYYY'),
    Paid: "",
    Balance: "",
    category: ""
  });

  const [clinicExpenseCategories, setClinicExpenseCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));

          const clinicExpenseResponse = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getClinicCategory/${addressData.HospitalId}`
          );
          setClinicExpenseCategories(clinicExpenseResponse.data.ClinicExpenseCategory || []);
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);


  useEffect(() => {
    console.log("Extracted patientId:", expenseId);
    
    const fetchExpense = async () => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/clinicexpense/${expenseId}`,{
          params: {
            HospitalId: formData1.HospitalId 
          }
        });
        const expenseData = response.data;
        let parsedDate;
          if (expenseData.Date) {
            parsedDate = moment(expenseData.Date, "DD/MM/YYYY").toDate();
          } else {
            parsedDate = new Date();  // Fallback to current date if not provided
          }
        setExpense({ ...expenseData, Date: parsedDate, category: expenseData.category || "" });
      } catch (error) {
        console.error('Error fetching expense: ', error);
      }
    };

    fetchExpense();
  },[expenseId,formData1.HospitalId]);

  const handleChange = (e) => {
   
      const { name, value } = e.target;

      setExpense({ ...expense, [name]: value });
    
  };

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setExpense((prevExpense) => ({ ...prevExpense, Date: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedExpense = {
        ...expense,
        HospitalId: formData1.HospitalId,
        date: moment(expense.date, "DD/MM/YYYY").format("DD/MM/YYYY")  // Ensure date is correctly formatted
      };
      const response = await axios.put(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updateClinicexpenses/${expenseId}`, formattedExpense);

      setSuccessMessage('Expenses updated successfully');
      setErrorMessage('');
    } catch (error) {
      console.error('Error updating expense:', error);
      setSuccessMessage('');
      setErrorMessage('Error updating expense');
    }
  };

  const formattedDate = moment(expense.Date, "DD/MM/YYYY").isValid()
  ? moment(expense.Date, "DD/MM/YYYY").toDate()
  : new Date();

  const UpdateExpenseForm = () => (
    <div className="form-group col-md-12">
      <form onSubmit={handleSubmit}>
        <div className="form-row" style={{marginLeft:"20%"}}>
          {/* First Column */}
          <div className="form-group col-md-4">
            {/* <label className="font-weight-bold" htmlFor="patientId">
              Patient ID
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="patientId"
              name="patientId"
              placeholder="Patient ID"
              value={expense.patientId}
              onChange={handleChange}
            /><br></br> */}
  
            <label className="font-weight-bold" htmlFor="inputAddress2">
              Name
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="inputAddress2"
              name="PatientName"
              placeholder="Name"
              value={expense.PatientName}
              onChange={handleChange}
            /><br></br>
  
            <label
              className="font-weight-bold"
              htmlFor="exampleFormControlTextarea1"
            >
               Description
            </label>
            <textarea
              className="form-control input-bg-light-grey input-shadow3 input-p"
              id="description"
              name="description"
              value={expense.description}
              onChange={handleChange}
              placeholder="Write a description"
              rows="3"
            />
            <label className="font-weight-bold" htmlFor="category">Category</label>
            <select
              className="form-control"
              id="category"
              name="category"
              value={expense.category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {clinicExpenseCategories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>

          </div>
  
          {/* Second Column */}
          <div className="form-group col-md-4" style={{marginLeft:"10px"}}>
            <label className="font-weight-bold" htmlFor="inputAddress">
              Date
            </label>
            <div className="input-container">
              <DatePicker
                selected={formattedDate}
                onChange={handleDateChange}
                className="form-control input-shadow1 input-p"
              />
              <div className="icon-container">
                <i className="fa fa-calendar" style={{ color: "blue" }}></i>
              </div>
            </div><br></br>
  
            <label className="font-weight-bold" htmlFor="inputAddress">
              Amount
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Amount"
              name="text"
              value={expense.totalAmount}
              onChange={handleChange}
            /><br></br>
  
            <label
              className="font-weight-bold"
              htmlFor="exampleFormControlSelect1 input-p"
            >
              Total Paid
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="paid"
              name="paid"
              placeholder="Amount"
              value={expense.Paid}
              onChange={handleChange}
            /><br></br>
  
            <label className="font-weight-bold" htmlFor="balance">
              Balance
            </label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              id="balance"
              name="balance"
              placeholder="Balance"
              value={expense.Balance}
              onChange={handleChange}
            />
          </div>
           
          
        </div><br></br>
        <button type="submit" className="btn btn-primary" style={{marginLeft:"42%",width:"150px"}}>
               Save
            </button>
      </form>
      {successMessage && (
        <div className="alert alert-success mt-3" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-3" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );

  return (
    <Layout title="Category Treatment Form">
      <>
      <h1 className="mt-4 color-bright">Update Clinic Expenses</h1><br></br>
        {UpdateExpenseForm()}
      </>
    </Layout>
  );
};


export default UpdateClinicExpenses;
