import React,{useState, useEffect} from 'react';
import { format, parse } from 'date-fns';
import { FaCopy } from 'react-icons/fa';
import "./Modal.css";
import firebase from 'firebase/compat/app';
import axios from "axios";


const AppointmentModal = ({ show, handleClose, appointment }) => {
  const [alertMessage, setAlertMessage] = useState('');

  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const hospitalId=formData1.HospitalId;

  const [appointmentMessageTemplate, setAppointmentMessageTemplate] = useState('');

  useEffect(() => {
    if (hospitalId) {
      fetchAdminExtraFields(hospitalId);
    }
  }, [hospitalId]);

  const fetchAdminExtraFields = async (hospitalId) => {
    try {
      const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getAdminExtraFields/${hospitalId}`);
      const extraFieldsData = response.data;
      setAppointmentMessageTemplate(extraFieldsData.AppointmentMessage || '');
      console.log('Fetched admin extra fields data:', extraFieldsData);
    } catch (error) {
      console.error('Error fetching admin extra fields:', error);
    }
  };

  const generateMessage = (template, appointment) => {
    if (!template) return '';

    return template
      .replace('${name}', appointment.Appointmenttab_name || 'N/A')
      .replace('${date}', formatDate(appointment.Appointmenttab_date) || 'N/A')
      .replace('${time}', appointment.Appointmenttab_time || 'N/A')
      .replace('${doctorname}', appointment.Appointmenttab_doctorname || 'N/A');
  };


  if (!show || !appointment) return null;

  const formatDate = (dateString) => {
    const date = parse(dateString, 'dd/MM/yyyy', new Date());
    if (!isNaN(date)) {
      return format(date, 'dd/MM/yyyy');
    }
    return 'Invalid date';
  };


  const message = generateMessage(appointmentMessageTemplate, appointment);

  const handleCopyDetails = () => {
    navigator.clipboard.writeText(message);
    setAlertMessage('Appointment details copied to clipboard!');
    setTimeout(() => setAlertMessage(''), 3000);
  };

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h4>Appointment Details</h4>
        <p>{message}</p>
       
       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <button onClick={handleClose}>Close</button>
          {alertMessage && (  // Conditional rendering of the alert message
          <div className="alert-message">
            {alertMessage}
          </div>
        )}
          <FaCopy
            style={{ cursor: 'pointer', marginLeft: '10px', color:"#32a852" }}
            onClick={handleCopyDetails}
            title="Copy Details"
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
