import React from 'react';
import './Taginput.css'; // Add the necessary CSS for styling

const TagInput = ({ label, tags, setTags, inputValue, setInputValue }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <div className="tag-input">
      <label className="font-weight-bold">{label}</label>
      <div className="tags-container">
        {tags.map((tag, index) => (
          <div className="tag" key={index}>
            {tag} <span className="tag-delete" onClick={() => handleDelete(index)}>X</span>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={`Add ${label}`}
        />
      </div>
    </div>
  );
};

export default TagInput;
