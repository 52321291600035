import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../frontpage/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyAFVeBVvSqo6y4gV05YZjWORabL_2BfnyE",
  authDomain: "hospital-management-23dbf.firebaseapp.com",
  databaseURL: "https://hospital-management-23dbf-default-rtdb.firebaseio.com",
  projectId: "hospital-management-23dbf",
  storageBucket: "hospital-management-23dbf.appspot.com",
  messagingSenderId: "928001005956",
  appId: "1:928001005956:web:7f48c6d6f49c9aa9d1a5d1",
  measurementId: "G-B0CEBXVS7F"
};

firebase.initializeApp(firebaseConfig);

const UpdateDoctorDetails = () => {
  const { doctorId } = useParams();
  const [doctor, setDoctor] = useState({
    doctorId:"",
    HospitalId:"",
    Name: "",
    RegistrationDate: new Date(),
    Address: "",
    PhoneNo: "",
    speciality: "",
    Residence: "",
    Email: "",
    Fee: "",
    Gender: "",
    UploadPhoto: "",
  });

  const [UploadPhoto, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const [specializationOptions, setSpecializationOptions] = useState([]);

  useEffect(() => {
    console.log("Extracted doctorId:", doctorId);
    if(doctorId){
      const fetchDoctorDetails = async () => {
        try {
          const response = await axios.get(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/update-doctors/${doctorId}`,{
            params: {
              HospitalId: formData1.HospitalId 
            }
          });
          const doctorData = response.data;

          setDoctor({
            Name: doctorData.Name || "",
            RegistrationDate: doctorData.RegistrationDate ? moment(doctorData.RegistrationDate).toDate() : new Date(),
            Address: doctorData.Address || "",
            PhoneNo: doctorData.PhoneNo || "",
            Email: doctorData.Email || "",
            Fee: doctorData.Fee || "",
            Gender: doctorData.Gender || "",
            Residence: doctorData.Residence || "",
            UploadPhoto: doctorData.UploadPhoto || "",
            doctorId: doctorData.doctorId || "",
            HospitalId: doctorData.HospitalId || "",
            speciality: doctorData.speciality || ""
          });
        } catch (error) {
          console.error('Error fetching doctor details:', error);
          
        }
      };
      const fetchSpecialization = async () => {
        try {
          const response = await axios.get(` https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getSpecialization/${formData1.HospitalId}`);
          setSpecializationOptions(response.data.Specialization || []);
        } catch (error) {
          console.error("Error fetching specialization:", error);
        }
      };
      
      if(doctorId && formData1.HospitalId){
      fetchDoctorDetails();
      fetchSpecialization();
      }
    }
  }, [doctorId, formData1.HospitalId]);

  
  const [doctorName1, setDoctorName1] = useState('');
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    // Fetch doctor details from the backend API
    const fetchDoctors = async () => {
      try {
        const response = await axios.get('https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/getdoctorsname');
        console.log("response for doctor name:", response);
        setDoctors(response.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDoctor({
      ...doctor,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setDoctor({
      ...doctor,
      RegistrationDate: date
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let photoURL = doctor.UploadPhoto;
      if (UploadPhoto) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(UploadPhoto.name);
        await fileRef.put(UploadPhoto);
        photoURL = await fileRef.getDownloadURL();
      }

      const formattedDate = moment(doctor.RegistrationDate).format("MM/DD/YYYY");

      const updatedDoctor = {
        ...doctor,
        RegistrationDate: formattedDate,
        UploadPhoto: photoURL,
        HospitalId: formData1.HospitalId
      };

      const response = await axios.put(`https://us-central1-hospital-management-23dbf.cloudfunctions.net/api/updatedoctors/${doctorId}`, updatedDoctor);

      if (response.status === 200) {
        setSuccessMessage('Doctor updated successfully');
        setErrorMessage('');
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error('Error updating doctor details:', error);
      setSuccessMessage('');
      setErrorMessage('Error updating doctor details.');
    }
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    setUploading(file);
  };

  return (
    <Layout>
    <Loader/>
    <h1 className="mt-4 color-bright">Update Doctor Details</h1>
      <br />
      <div className="containerAddPatient">
        <div className="row">
          <form className="form-card" onSubmit={submitHandler}>
          <div className="formtype">
            <div className="card mb-4">
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Personal Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="Name"
                      value={doctor.Name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputPhone">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone no"
                      name="PhoneNo"
                      value={doctor.PhoneNo}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="Email"
                      value={doctor.Email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="genderSelect">Gender</label>
                    <select
                      name="Gender"
                      value={doctor.Gender}
                      onChange={handleChange}
                      className="form-control"
                      id="genderSelect"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputResidence">Residence</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Residence"
                      name="Residence"
                      value={doctor.Residence}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputAddress">Address</label>
                    <textarea
                      className="form-control"
                      placeholder="Write address"
                      rows="3"
                      name="Address"
                      value={doctor.Address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="exampleFormControlFile1">Upload Photo</label>
                    <input
                      type="file"
                      onChange={uploadFileHandler}
                      className="form-control-file"
                      id="exampleFormControlFile1"
                    />
                    {UploadPhoto && (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    
                  </div>
                  {doctor.UploadPhoto && (
                      <div className="form-group col-md-6">
                        <label className="font-weight-bold">Uploaded Photo</label>
                        <img
                          src={doctor.UploadPhoto}
                          alt="Doctor"
                          className="img-thumbnail"
                          style={{ width: "200px", height: "200px" }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="card mb-4" style={{marginLeft:"20px"}}>
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Professional Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputRegDate">Registration Date</label>
                    <DatePicker
                      selected={doctor.RegistrationDate}
                      onChange={handleDateChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputSpeciality">Specialization</label>
                    <select
                      name="speciality"
                      value={doctor.speciality}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option>Select</option>
                      {specializationOptions.map((specialization, index) => (
                      <option key={index} value={specialization}>
                        {specialization}
                      </option>
                    ))}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputFee">Fee</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fee"
                      name="Fee"
                      value={doctor.Fee}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            </div>
            <button type="submit" className="btn btn-primary" style={{ width: "20%", textAlign: "center", marginLeft:"40%" }}>
              Save
            </button>

          </form>
        </div>
        {successMessage && (
          <div className="alert alert-success mt-3" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UpdateDoctorDetails;
