import React,{useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
// Mock logout function
const mockLogout = () => {
  console.log("User logged out");
};

const Menu = () => {
  // Mock user info
  const mockUserInfo = { name: "Admin User" };

  const logoutHandler = () => {
    mockLogout();
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setIsLoggedIn(false);
        // Redirect to home page or do any necessary clean up
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

 
    useEffect(() => {
      // Check if user is already logged in
      const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
      return unsubscribe
    }, []);

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark navbar-color" style={{backgroundColor:"#4576d1"}}>
    
      <a className="navbar-brand" href="">
        Hospital & Pharmacy Management System
      </a>
      {/* <button
        className="btn btn-link btn-sm order-1 order-lg-0"
        id="sidebarToggle"
        href="#"
      >
        <i className="fas fa-bars"></i>
      </button> */}

      <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <div className="input-group">
          <input
            className="invisible"
            type="text"
            placeholder="Search for..."
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button className="invisible" type="button">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      {/* <ul className="navbar-nav ml-auto ml-md-0">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            id="userDropdown"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-user fa-fw"></i>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="userDropdown"
          >
            <a className="dropdown-item" href="#">
              Settings
            </a>
            <a className="dropdown-item" href="#">
              Activity Log
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={logoutHandler}>
              Logout
            </a>
          </div>
        </li>
      </ul> */}
      <div className="dropdown navicontop" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{marginRight:"60px"}}>
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: '#EA8D8D' }}
        />

        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10px\\', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              {/* <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link> */}
              {/* <Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>
              <Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>  */}
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Menu;
