import React from "react";

const Footer=()=>{
    return(
        <>
             {/* Footer Start */}
    <div
      className="container-fluid bg-primary text-light footer wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container4 py-5 px-lg-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 ">
            <h4 className="text-white mb-4 footertitle">Address</h4>
            <p>
              <i className="fa fa-map-marker-alt me-3" />
              Padur,Chennai
            </p>
            <p>
              <i className="fa fa-phone-alt me-3" />
              +91 80561 95554
            </p>
            <p>
              <i className="fa fa-envelope me-3" />
              project@datadna.in
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-instagram" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 footertitle">
            <h4 className="text-white mb-4">Quick Link</h4>
            <a className="btn btn-link " href="/home">
              Home
            </a>
            <a className="btn btn-link" href="/features">
              Features
            </a>
            {/* <a className="btn btn-link" href="/whyHMS">
            Why HMS
            </a> */}
            {/* <a className="btn btn-link" href="">
              Terms &amp; Condition
            </a>
            <a className="btn btn-link" href="">
              Career
            </a> */}
          </div>
          {/* <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4 footertitle">Popular Link</h4>
            <a className="btn btn-link" href="/home">
              Home
            </a>
            <a className="btn btn-link" href="/features">
              Features
            </a> */}
            {/* <a className="btn btn-link" href="/whyHMS">
              Why HMS
            </a> */}
            {/* <a className="btn btn-link" href="">
              Terms &amp; Condition
            </a>
            <a className="btn btn-link" href="">
              Career
            </a> */}
          {/* </div> */}
          <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4 footertitle">Newsletter</h4>
            <p>
            Join our community to receive essential insights and news designed to enhance your healthcare experience.
            </p>
            <div className="position-relative w-100 mt-3">
              <input
                className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                type="text"
                placeholder="Your Email"
                style={{ height: 48 }}
              />
              <button
                type="button"
                className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
              >
                <i className="fa fa-paper-plane text-primary-gradient fs-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container4 px-lg-5">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a className="border-bottom" href="#">
                PROHMS
              </a>
              , All Right Reserved.
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              Designed By{" "}
              <a className="border-bottom" href="https://www.datadna.in/"  target="_blank">
                DATADNA
              </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/home">Home</a>
                <a href="/features">Features</a>
                {/* <a href="/whyHMS">Why HMS</a> */}
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer End */}
        </>
    );
}

export default Footer;